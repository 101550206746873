import axios from "axios";
import faker from "faker";
import { formatInputDate } from "../utils/formatDate";

export async function validateSerialNumber(serialNumber) {
  const response = await axios.get("/api/registration/validate", { params: { serialNumber } });
  return response.data;
}

// useful during development for quickly setting up lots of data
export const generateRandomRegistration = () => ({
  serialNumber: faker.datatype.uuid(),
  homeownerName: faker.name.findName(),
  homeownerPhone: faker.phone.phoneNumber(),
  homeownerEmail: faker.internet.email(),
  streetAddress: faker.address.streetAddress(),
  state: faker.address.state(),
  city: faker.address.city(),
  zipCode: faker.address.zipCode(),
  lotNumber: "",
  closingDate: formatInputDate(faker.date.soon()),
  // completedDate: "",
  HVACInside: faker.datatype.number({ min: 100000000, max: 999999999 }),
  HVACOutside: faker.datatype.number({ min: 100000000, max: 999999999 }),
});
