import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    showing: false,
    type: null,
    details: null,
  },
  reducers: {
    showSuccessNotification: (state, action) => {
      state.showing = true;
      state.type = "success";
      state.details = action.payload;
    },
    showErrorNotification: (state, action) => {
      state.showing = true;
      state.type = "error";
      state.details = action.payload;
    },
    resetNotification: state => {
      state.showing = false;
      state.type = null;
      state.details = null;
    },
  },
});

export const { showSuccessNotification, showErrorNotification, resetNotification } =
  notificationSlice.actions;

export const flashSuccessNotification = payload => dispatch => {
  dispatch(showSuccessNotification(payload));
  setTimeout(() => {
    dispatch(resetNotification());
  }, 5000);
};

export const flashErrorNotification = payload => dispatch => {
  dispatch(showErrorNotification(payload));
  setTimeout(() => {
    dispatch(resetNotification());
  }, 5000);
};

export default notificationSlice.reducer;
