import React from "react";

export function PageWithSidebar({ children }) {
  // top padding with negative top margin compensates for fixed header
  // to allow h-screen to work without a scrollbar
  return <div className="flex h-screen pt-16 -mt-16">{children}</div>;
}

export function Sidebar({ children }) {
  return <div className="flex-col w-1/4 bg-white overflow-scroll">{children}</div>;
}

export function MainSection({ children }) {
  return <main className="flex flex-col w-3/4 p-8 bg-gray-200 overflow-scroll">{children}</main>;
}
