import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  // Link,
  useHistory,
} from "react-router-dom";
import {
  //  EyeIcon,
  // PencilIcon,
  TrashIcon,
  SearchIcon,
} from "@heroicons/react/outline";
import {
  deleteSubmittalAsync,
  useSubmittalHEUGTableItems,
} from "../../state/slices/submittalSlice";
// import { openModal } from "../../state/slices/frontend";
import Table from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";

export default function SubmittalsHEUG() {
  const submittalTableItems = useSubmittalHEUGTableItems();
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageSize, setPageSize] = useState(25);
  const onChangePageSize = e => setPageSize(parseInt(e.target.value));

  const handleDelete = submittal => {
    const confirm = window.confirm("Deleting a submittal is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deleteSubmittalAsync(submittal));
    }
  };

  const submittalColumns = [
    {
      caption: "Submittal",
      dataField: "name",
    },
    {
      caption: "Received",
      dataField: "dateAdded",
      dataType: "date",
      class: "text-left",
    },
    // {
    //   dataField: "completed",
    //   class: "text-center",
    // },
    // {
    //   caption: "Total",
    //   dataField: "total",
    // },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            {/* DISABLED because it's not part of expected use cases. Only search should be used */}
            {/* <TableButton
              testId={`submittal-edit-${value.id}`}
              icon={PencilIcon}
              onClick={() => dispatch(openModal({ modal: "submittal", target: value }))}
              className="text-indigo-600 "
            />
            <Link to={{ pathname: "/submittals/edit/" + value.id }}>
              <TableButton
                testId={`submittal-view-${value.id}`}
                className="text-indigo-600 mx-2"
                icon={EyeIcon}
              />
            </Link> */}

            <TableButton
              testId={`submittal-delete-${value.id}`}
              onClick={() => handleDelete(value)}
              icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className=" bg-gray-200">
      <header className="bg-gray-800 shadow lg:h-64 md:mt-0  mt-10 ">
        <div className="md:max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 md:flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Submittals</h1>
          <div className="flex">
            <Button onClick={() => history.push("/submittals/search")} className="mr-2">
              <SearchIcon className="mr-2 h-4 text-white inline-block" />
              Search
            </Button>
            <Button onClick={() => history.push("/submittals/import")}>Import</Button>
          </div>
        </div>
      </header>
      <main className="lg:-my-48 md:max-w-7xl  mx-auto container py-6">
        <div className="my-1 lg:px-8 sm:px-6">
          <select
            value={pageSize}
            onChange={onChangePageSize}
            id="pageSize"
            name="pageSize"
            className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          >
            <option value={5}>5</option>
            <option value={25}>25</option>
          </select>
        </div>
        <Table columns={submittalColumns} items={submittalTableItems} pageSize={pageSize} />
      </main>
    </div>
  );
}
