import React from "react";

function Tab({ title, selected, onClick }) {
  const selectedStyles = "border-indigo-500 text-indigo-600";
  const nonSelectedStyles =
    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300";
  const appliedClasses = selected ? selectedStyles : nonSelectedStyles;
  return (
    <span
      role="button"
      onClick={onClick}
      className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${appliedClasses}`}
    >
      {title}
    </span>
  );
}

export default function HeadingWithTabsAndActions({
  tabs = [],
  currentTab,
  onChange = () => {},
  renderActions = () => {},
}) {
  return (
    <div className="flex items-center justify-between border-b border-gray-300">
      <nav className="mt-4 flex space-x-8">
        {tabs.map((title, idx) => (
          <Tab
            title={title}
            key={idx}
            selected={title === currentTab}
            onClick={() => onChange(title)}
          />
        ))}
      </nav>
      <div className="my-1">{renderActions()}</div>
    </div>
  );
}
