import React from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormikFieldWithLabel from "../../../common/FormikFieldWithLabel";
import { upsertHomeAsync } from "../../../../state/slices/homeSlice";
import { HomeValidator } from "../../../../utils/validators/HomeValidator";
import { selectParkOptions } from "../../../../state/slices/parkSlice";
import { selectPlantOptions } from "../../../../state/slices/plantSlice";
import { selectCustomerOptions } from "../../../../state/slices/customerSlice";
import FilteredPlantOptions from "../../FilteredPlantOptions";
import { deleteBugCausingFields } from "./util";

// TODO: remove setParentState after refactoring submittal data
// normalization and home selector in sub details page
export default function InspectionPhase({ home, handleDelete, refreshSubmittal, setParentState }) {
  const { submittalId } = useParams();
  const dispatch = useDispatch();
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const parkOptions = useSelector(selectParkOptions);

  const initialValues = {
    // core info
    CustomerId: home.CustomerId || "",
    PlantId: home.PlantId || "",
    ParkId: home.ParkId || "",
    serialNumber: home.serialNumber || "",
    modelNumber: home.modelNumber || "",
    energyStar: home.energyStar || "",
    type: home.type || "",
    floorPlan: home.floorPlan || "",
    certType: home.certType || "",
    retailer: home.retailer || "",
    certDate: home.certDate || "",
    completionDate: home.completionDate || "",
    notes: home.notes || "",
    // location
    streetAddress: home.streetAddress || "",
    city: home.city || "",
    state: home.state || "",
    lotNumber: home.lotNumber || "",
    zipCode: home.zipCode || "",
    county: home.county || "",
  };

  const handleSubmit = async values => {
    deleteBugCausingFields(values);

    const { payload } = await dispatch(
      upsertHomeAsync({
        ...values,
        id: home.id,
        SubmittalId: submittalId,
      })
    );
    refreshSubmittal();
    const [updatedHome, isNew] = payload;
    if (!isNew) {
      updatedHome.CustomerId = home.CustomerId;
      setParentState(updatedHome);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={HomeValidator(initialValues.serialNumber)}
      validateOnMount={true}
      validateOnChange={true}
      // initialTouched={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form className="flex flex-col">
          {/* <FormButtons showDelete={!!home.id} handleDelete={handleDelete} /> */}
          <div className="flex flex-grow justify-between items-center my-4">
            {home.FieldVerification ? (
              <div>
                <Link to={`/submittals/compare/${home.id}`}>
                  <span className="bg-green-700 mr-2 text-white font-bold py-3 px-5 rounded-md">
                    Compare
                  </span>
                </Link>
                <Link
                  to={`/trip/details/${home.FieldVerification.TripId}?id=${home.FieldVerification.id}`}
                  className="h-full"
                >
                  <span className="bg-green-700 mr-2 text-white font-bold py-3 px-5 rounded-md">
                    Edit Field Verification
                  </span>
                </Link>
              </div>
            ) : (
              <p className="p-3 bg-white rounded-lg text-red-600">
                No field verification found with this home's serial number.
              </p>
            )}

            <div>
              <button
                className="bg-red-500 mr-2 text-white font-bold py-3 px-5 rounded-md"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                type="submit"
                className="bg-green-700 mr-2 text-white font-bold py-3 px-5 rounded-md"
              >
                Save
              </button>
            </div>
          </div>
          <div className="lg:flex flex-row">
            {/* Left column */}
            <div className="flex flex-col">
              <div className="p-3 bg-white rounded-lg m-2">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Core Information</h3>
                </div>

                <div className="gap-2 md:grid grid-cols-3">
                  <FormikFieldWithLabel
                    as="select"
                    name="CustomerId"
                    label="Customer"
                    error={touched.CustomerId && errors.CustomerId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    {customerOptions.map(customer => (
                      <option value={customer.value} key={customer.value}>
                        {customer.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    as="select"
                    name="PlantId"
                    label="Plant/HBF"
                    error={touched.PlantId && errors.PlantId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    <FilteredPlantOptions
                      plantOptions={plantOptions}
                      customerId={values.CustomerId}
                    />
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" type="select" label="Park" name="ParkId">
                    <option defaultValue value="">
                      ---
                    </option>
                    {parkOptions.map(park => (
                      <option value={park.value} key={park.value}>
                        {park.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    type="text"
                    name="serialNumber"
                    id="serialNumber"
                    label="Serial Number"
                    error={touched.serialNumber && errors.serialNumber}
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="modelNumber"
                    id="ModelNo"
                    label="Model Number"
                    className="xl:col-span-1"
                    error={touched.modelNumber && errors.modelNumber}
                  />
                  {/* <div className="col-span-3 grid grid-cols-3"> */}
                  <FormikFieldWithLabel
                    as="select"
                    label="Energy Star"
                    type="select"
                    name="energyStar"
                    error={touched.energyStar && errors.energyStar}
                    disabled={true}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="es">ES</option>
                    <option value="nes">NES</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    as="select"
                    name="type"
                    label="Type"
                    error={touched.type && errors.type}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option defaultValue value="hud">
                      HUD
                    </option>

                    <option value="mod">MOD</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" name="floorPlan" label="Floor Plan">
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="base floor">Base Floor</option>
                    <option value="custom">Custom</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel name="retailer" label="Retailer" />
                  <FormikFieldWithLabel
                    rows="3"
                    label="Notes"
                    as="textarea"
                    type="text"
                    name="notes"
                    id="notes"
                    className="col-span-3"
                  />
                </div>
              </div>
            </div>
            {/* Right column */}
            <div className="flex flex-col">
              <div className="m-2 p-5 bg-white rounded-lg ">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
                </div>
                <div className="md:grid gap-4 grid-cols-3">
                  <FormikFieldWithLabel
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    label="Street Address"
                    className="col-span-3"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="state"
                    id="state"
                    label="State"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="lotNumber"
                    id="lotNumber"
                    label="Lot #"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    label="Zip Code"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="county"
                    id="county"
                    label="County"
                    className="col-span-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
