import axios from "axios";
import faker from "faker";

export const getAll = async () => {
  const { data } = await axios.get(`/api/plants`);
  return data;
};

export const getAllOptions = async () => {
  const { data } = await axios.get("/api/plant/options");
  return data;
};

export const getReport = async () => {
  const { data } = await axios.get(`/api/plants/report`);
  return data;
};

// TODO: replace with createQueryString
export const composePlantReportQuery = query => {
  let queryString = "";
  if (query.after || query.before) {
    queryString += "?";
    queryString += query.after ? "after=" + query.after : "";
    queryString += query.after && query.before ? "&" : "";
    queryString += query.before ? "before=" + query.before : "";
  }
  if (query.isReviewed) queryString += queryString ? "&isReviewed=true" : "?isReviewed=true";
  return queryString;
};

export const getReportByPlantId = async (plantId, query) => {
  // TODO: replace with createQueryString
  const queryString = composePlantReportQuery(query);
  const { data } = await axios.get(`/api/plants/report/${plantId}${queryString}`);
  return data;
};

// useful during development for quickly setting up lots of data
export const generateRandomPlant = () => {
  const companyName = faker.company.companyName();
  return {
    name: companyName,
    certificateName: companyName,
    CustomerId: 3, // hard-coded Cavco (yours might be different, check your local database)
    address: faker.address.streetAddress(),
    city: faker.address.city(),
    state: faker.address.state(),
    specialInstructions: "",
    contactName: faker.name.findName(),
    contactPhone: faker.phone.phoneNumber(),
    contactEmail: faker.internet.email(),
  };
};
