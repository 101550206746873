import CancelledPlantInspectionView from "./CancelledPlantInspectionView";
import CompletedPlantInspectionView from "./CompletedPlantInspectionView";

export default function PlantInspectionTab({ pi, refreshTrip }) {
  return (
    <>
      <CompletedPlantInspectionView pi={pi} refreshTrip={refreshTrip} />
      <CancelledPlantInspectionView pi={pi}></CancelledPlantInspectionView>
    </>
  );
}
