import React from "react";
import { ClockIcon, CheckIcon, XIcon } from "@heroicons/react/outline";

const ScheduledStatusIcon = () => (
  <ClockIcon
    className="h-6 p-1 m-2 rounded-full bg-gray-200 text-gray-800"
    data-testid="icon-scheduled"
  />
);
const CompletedAndReviewedStatusIcon = () => (
  <CheckIcon
    className="h-6 p-1 m-2 rounded-full bg-green-200 text-green-900"
    data-testid="icon-completed-and-reviewed"
  />
);
const CompletedStatusIcon = () => (
  <CheckIcon
    className="h-6 p-1 m-2 rounded-full bg-orange-200 text-orange-900"
    data-testid="icon-completed"
  />
);
const CancelledStatusIcon = () => (
  <XIcon
    className="h-6 p-1 m-2 rounded-full bg-red-200 text-red-900"
    data-testid="icon-cancelled"
  />
);

export default function StatusIcon({ status, isReviewed }) {
  if (isReviewed && status === "completed") {
    return <CompletedAndReviewedStatusIcon />;
  }
  switch (status) {
    case "scheduled":
      return <ScheduledStatusIcon />;
    case "completed":
      return <CompletedStatusIcon />;
    case "cancelled":
      return <CancelledStatusIcon />;
    default:
      return;
  }
}
