import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import { closeModal } from "../../state/slices/frontend";
import { upsertSubmittalAsync } from "../../state/slices/submittalSlice";
import FormHeader from "../common/forms/FormHeader";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import { selectPlantOptions } from "../../state/slices/plantSlice";
import { selectCustomerOptions } from "../../state/slices/customerSlice";
import FilteredPlantOptions from "./FilteredPlantOptions";

export default function SubmittalForm() {
  const dispatch = useDispatch();
  const { modals } = useSelector(state => state.frontend);
  const plantOptions = useSelector(selectPlantOptions);
  const customerOptions = useSelector(selectCustomerOptions);
  // Validation rules
  const SubmittalSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    energyStar: Yup.string().required("Required"),
    CustomerId: Yup.string().required("Required"),
    PlantId: Yup.string().required("Required"),
  });

  const handleAddSubmittal = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    await dispatch(upsertSubmittalAsync(payload));
    dispatch(closeModal({ modal: "submittal", target: null }));
  };
  const initialValues = modals.submittal?.target || {
    name: "",
    energyStar: "",
    PlantId: "",
    CustomerId: "",
  };

  return (
    <Formik
      onSubmit={handleAddSubmittal}
      initialValues={initialValues}
      validationSchema={SubmittalSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values }) => (
        <Form>
          <div className={` bg-white space-y-6 `}>
            <fieldset>
              <FormHeader>
                {modals.submittal?.target?.id ? "Edit Submittal" : "New Submittal"}
              </FormHeader>
              <div className="grid grid-cols-2 gap-2">
                <FormikFieldWithLabel
                  name="name"
                  label="Submittal Name"
                  placeholder="What do you want to name this manual submission?"
                  type="text"
                  className="col-span-2"
                  error={touched.name && errors.name}
                />
                <FormikFieldWithLabel
                  as="select"
                  name="energyStar"
                  label="Energy Star"
                  className="col-span-2"
                  error={touched.energyStar && errors.energyStar}
                >
                  <option defaultValue value="">
                    ---
                  </option>
                  <option value="es">ES</option>
                  <option value="nes">NES</option>
                </FormikFieldWithLabel>
                <FormikFieldWithLabel
                  as="select"
                  name="CustomerId"
                  label="Customer"
                  error={touched.CustomerId && errors.CustomerId}
                >
                  <option value="">---</option>
                  {customerOptions.map(customer => (
                    <option value={customer.value} key={customer.value}>
                      {customer.label}
                    </option>
                  ))}
                </FormikFieldWithLabel>
                <FormikFieldWithLabel
                  as="select"
                  name="PlantId"
                  label="Plant/HBF"
                  error={touched.PlantId && errors.PlantId}
                >
                  <option value="">---</option>
                  <FilteredPlantOptions
                    plantOptions={plantOptions}
                    customerId={values.CustomerId}
                  />
                </FormikFieldWithLabel>
              </div>
            </fieldset>
          </div>

          <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
            <button
              type="submit"
              className={`bg-indigo-600 hover:bg-indigo-700 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
