import React from "react";
import {
  CloudUploadIcon,
  ClipboardCheckIcon,
  SearchIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";

export default function HomePhasesNavBar({ setPhase, currentPhase, isNewHome }) {
  const getPhaseIconColor = phase => (currentPhase === phase ? "bg-green-500" : "");
  const getPhaseDisabledColor = () =>
    isNewHome ? "border-gray-400 text-gray-400 " : "border-gray-600 text-gray-600 ";
  const getPhaseDisabledText = () => (isNewHome ? "text-gray-400 " : "text-gray-600 ");

  return (
    <nav aria-label="Progress" className="w-full">
      <ol className="border border-gray-300 overflow-auto rounded-md divide-y divide-gray-300 flex  md:divide-y-0 ">
        <li className="relative md:flex-1 md:flex" onClick={() => setPhase("submittal")}>
          {/* Completed Step */}
          <button className="focus:outline-none pointer-events-auto px-6 py-4 flex items-center text-sm font-medium">
            <span
              className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-600 rounded-full
              ${getPhaseIconColor("submittal")}`}
              aria-current="step"
            >
              <CloudUploadIcon className="h-6 w-6 text-gray-600 " />
            </span>
            <span className="ml-4 text-sm font-medium text-gray-600">Submittal</span>
          </button>
          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>
        <li
          className="relative md:flex-1 md:flex"
          onClick={() => !isNewHome && setPhase("certification")}
        >
          {/* Upcoming Step */}
          <button
            href="#"
            className="focus:outline-none pointer-events-auto px-6 py-4 flex items-center w-full text-sm font-medium"
          >
            <span
              className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full
                  ${getPhaseIconColor("certification")} ${getPhaseDisabledColor()}`}
              aria-current="step"
            >
              <ClipboardCheckIcon className="h-6 w-6" />
            </span>
            <span className={`ml-4 text-sm font-medium ${getPhaseDisabledText()}`}>
              Certification
            </span>
          </button>
          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>

        <li
          className="relative md:flex-1 md:flex"
          onClick={() => !isNewHome && setPhase("inspection")}
        >
          {/* Upcoming Step */}
          <button
            href="#"
            className="focus:outline-none pointer-events-auto px-6 py-4 flex items-center text-sm font-medium"
          >
            <span
              className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full
              ${getPhaseIconColor("inspection")}  ${getPhaseDisabledColor()}`}
              aria-current="step"
            >
              <SearchIcon className="h-6 w-6" />
            </span>
            <span className={`ml-4 text-sm font-medium ${getPhaseDisabledText()}`}>Inspection</span>
          </button>
          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>
        <li className="relative md:flex-1 md:flex" onClick={() => !isNewHome && setPhase("plans")}>
          {/* Current Step */}
          <button
            href="#"
            className="focus:outline-none pointer-events-auto px-6 py-4 flex items-center text-sm font-medium"
          >
            <span
              className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full
              ${getPhaseIconColor("plans")} ${getPhaseDisabledColor()}`}
            >
              <BriefcaseIcon className="h-6 w-6" />
            </span>
            <span className={`ml-4 text-sm font-medium ${getPhaseDisabledText()}`}>Plans</span>
          </button>
          <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
            <svg
              className="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vectorEffect="non-scaling-stroke"
                stroke="currentcolor"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </li>

        <li
          className="relative md:flex-1 md:flex"
          onClick={() => !isNewHome && setPhase("billing")}
        >
          {/* Upcoming Step */}
          <button
            href="#"
            className="focus:outline-none pointer-events-auto px-6 py-4 flex items-center text-sm font-medium"
          >
            <span
              className={`flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 rounded-full
              ${getPhaseIconColor("billing")}  ${getPhaseDisabledColor()}`}
              aria-current="step"
            >
              <CurrencyDollarIcon className="h-6 w-6" />
            </span>
            <span className={`ml-4 text-sm font-medium ${getPhaseDisabledText()}`}>Billing</span>
          </button>
        </li>
      </ol>
    </nav>
  );
}
