import React from "react";
import { Link } from "react-router-dom";
import { isHEUGSite } from "../../utils/isHEUGSite";

export default function Logo({ className }) {
  return (
    <Link to="/">
      {isHEUGSite ? (
        <img
          className={`h-10 w-10 mx-auto p-1 bg-white rounded-md ${className}`}
          src="/assets/HEUG_logo.png"
          alt="Logo for HEUG"
        />
      ) : (
        <img
          className={`h-10 w-10 mx-auto bg-white rounded-full ${className}`}
          src="/assets/energy.png"
          alt="Logo for energycheck"
        />
      )}
    </Link>
  );
}
