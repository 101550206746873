import { unset } from "lodash";

export default function stripUnfilledValues(values) {
  for (const prop in values) {
    if (!values[prop]) {
      unset(values, prop);
    }
  }
}

export function stripUnfilledValues_pureFunction(values) {
  const cloned = { ...values };
  for (const prop in cloned) {
    if (!cloned[prop]) {
      delete cloned[prop];
    }
  }
  return cloned;
}
