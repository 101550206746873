import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { upsertUserAsync, clearErrors } from "../../state/slices/userSlice";
import { loginAsync } from "../../state/slices/authSlice";
import axios from "axios";
import { upsertRetailerAsync } from "../../state/slices/retailerSlice";
import Logo from "../nav/Logo";

export default function RetailerSignUp() {
  const [state, setState] = useState({}); //form
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isCreatingNewRetailer, setIsCreatingNewRetailer] = useState(false);
  const { generalError } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    axios
      .get("/api/retailers")
      .then(({ data }) => setRetailerOptions(data.rows))
      .catch(console.error);
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    if (state.password !== state.confirm) {
      setError("Password and confirm password must match.");
      return;
    }
    delete state.confirm;
    setLoading(true);
    try {
      if (isCreatingNewRetailer) {
        const retailerPostRes = await dispatch(
          upsertRetailerAsync({
            name: state.retailerName,
            address: state.retailerAddress,
            city: state.retailerCity,
            state: state.retailerState,
            zipCode: state.retailerZip,
            contactName: state.name,
            contactPhone: state.phoneNumber,
            contactEmail: state.email,
          })
        );
        state.RetailerId = retailerPostRes.payload.retailer[0].id;
      }
      const userAction = await dispatch(
        upsertUserAsync({
          name: state.name,
          RetailerId: state.RetailerId,
          phoneNumber: state.phoneNumber,
          email: state.email,
          password: state.password,
          role: "retailer",
        })
      );
      if (userAction.type === "user/upsert/fulfilled") {
        const loginAction = await dispatch(loginAsync(state));
        if (loginAction.type === "auth/signin/fulfilled") {
          return history.push("/");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClearErrors = () => {
    dispatch(clearErrors());
    setError("");
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="w-12 h-12" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create your retailer account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Or{" "}
          <Link to="/auth/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
            already have an account?
          </Link>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            {/* TODO: hide retailer select when creating */}
            {!isCreatingNewRetailer && (
              <div>
                <label htmlFor="RetailerId" className="block text-sm font-medium text-gray-700">
                  Retailer
                </label>
                <div className="mt-1">
                  <select
                    onFocus={handleClearErrors}
                    id="RetailerId"
                    value={(state && state.RetailerId) || ""}
                    onChange={handleChange}
                    name="RetailerId"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value=""></option>
                    {retailerOptions.map(r => (
                      <option value={r.id} key={r.id}>
                        {r.name}
                      </option>
                    ))}
                  </select>
                  <button
                    type="button"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    onClick={() => setIsCreatingNewRetailer(true)}
                  >
                    Don't see your retailer?
                  </button>
                </div>
              </div>
            )}
            {isCreatingNewRetailer && (
              <>
                <div>
                  <label htmlFor="retailerName" className="block text-sm font-medium text-gray-700">
                    Retailer name
                  </label>
                  <div className="mt-1">
                    <input
                      onFocus={handleClearErrors}
                      id="retailerName"
                      value={(state && state.retailerName) || ""}
                      onChange={handleChange}
                      name="retailerName"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <button
                    type="button"
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    onClick={() => setIsCreatingNewRetailer(false)}
                  >
                    Choose from existing retailers
                  </button>
                </div>
                <div>
                  <label
                    htmlFor="retailerAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Retailer address
                  </label>
                  <div className="mt-1">
                    <input
                      onFocus={handleClearErrors}
                      id="retailerAddress"
                      value={(state && state.retailerAddress) || ""}
                      onChange={handleChange}
                      name="retailerAddress"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="retailerCity" className="block text-sm font-medium text-gray-700">
                    Retailer city
                  </label>
                  <div className="mt-1">
                    <input
                      onFocus={handleClearErrors}
                      id="retailerCity"
                      value={(state && state.retailerCity) || ""}
                      onChange={handleChange}
                      name="retailerCity"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="retailerState"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Retailer state
                  </label>
                  <div className="mt-1">
                    <input
                      onFocus={handleClearErrors}
                      id="retailerState"
                      value={(state && state.retailerState) || ""}
                      onChange={handleChange}
                      name="retailerState"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="retailerZip" className="block text-sm font-medium text-gray-700">
                    Retailer zip code
                  </label>
                  <div className="mt-1">
                    <input
                      onFocus={handleClearErrors}
                      id="retailerZip"
                      value={(state && state.retailerZip) || ""}
                      onChange={handleChange}
                      name="retailerZip"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                {/* This could come in handy if we want to make default contact for retailer optional */}
                {/* <div>
                  <input id="isDefaultContact" type="checkbox" />
                  <label htmlFor="isDefaultContact">
                    Make me the default contact for this retailer
                  </label>
                </div> */}
              </>
            )}
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Full name
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="name"
                  value={(state && state.name) || ""}
                  onChange={handleChange}
                  autoFocus
                  name="name"
                  type="text"
                  autoComplete="name"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                Phone number
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="phoneNumber"
                  value={(state && state.phoneNumber) || ""}
                  onChange={handleChange}
                  name="phoneNumber"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  onFocus={handleClearErrors}
                  id="email"
                  value={(state && state.email) || ""}
                  onChange={handleChange}
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ul className="text-red-700 my-2">
                <li>{generalError && generalError}</li>
              </ul>
            </div>
            <div>
              <label htmlFor="new-password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="new-password"
                  onChange={e =>
                    handleChange({ target: { name: "password", value: e.target.value } })
                  }
                  name="new-password"
                  type="password"
                  value={(state && state.password) || ""}
                  onFocus={handleClearErrors}
                  autoComplete="off"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700">
                Confirm Password
              </label>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="confirm password"
                  onChange={e =>
                    handleChange({ target: { name: "confirm", value: e.target.value } })
                  }
                  type="password"
                  value={(state && state.confirm) || ""}
                  autoComplete="new-password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <ul className="text-red-700 my-2">
                <li>{error && error}</li>
              </ul>
            </div>

            <div>
              <button
                type="submit"
                style={{
                  opacity: isLoading || error || generalError ? "50%" : "initial",
                }}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create your account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
