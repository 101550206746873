import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../state/slices/frontend";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import { upsertUserAsync } from "../../state/slices/userSlice";
import { Formik, Form } from "formik";
import { SubmitButton } from "../common/Buttons";

export default function UserForm() {
  const { modals } = useSelector(state => state.frontend);

  const dispatch = useDispatch();

  const handleSubmitUser = values => {
    dispatch(upsertUserAsync(values));
    dispatch(closeModal({ modal: "user" }));
  };
  const initialValues = modals.user.target || {
    name: "",
    email: "",
    RTIN: "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmitUser}>
      <Form className="p-4">
        <h3 className="font-bold text-2xl mb-4">Edit User</h3>
        <div className="grid grid-cols-3 gap-x-4">
          <FormikFieldWithLabel className="col-span-1" name="name" label="Name" />
          <FormikFieldWithLabel
            className="col-span-1"
            name="email"
            label="Email Address"
            disabled
          />
          <FormikFieldWithLabel className="col-span-1" name="RTIN" label="RTIN" />
        </div>
        <div className="flex justify-end m-4 mt-6">
          <SubmitButton />
        </div>
      </Form>
    </Formik>
  );
}
