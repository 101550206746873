import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPlantInspectionById } from "../../../../services/plantInspection";
import formatAddressText from "../../../../utils/formatAddress";
import { GreenButton } from "../../../common/Buttons";
import { DataField } from "../../../common/DataField";
import CompletedPlantInspectionView from "./CompletedPlantInspectionView";

export default function PlantInspectionReport({ match, isAuthenticated }) {
  const id = match.params.id;
  const [report, setReport] = useState([]);

  useEffect(() => {
    getPlantInspectionById(id).then(report => {
      setReport(report);
    });
  }, [id]);

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-6 px-8 items-center">
          <h1 className="text-3xl font-bold text-white">Plant Inspection Report</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto p-8">
          {isAuthenticated && (
            <Link to={`/trip/details/${report.TripId}?piId=${report.id}`}>
              <GreenButton className="mb-6">Edit Plant Inspection</GreenButton>
            </Link>
          )}
          <div className="grid grid-cols-2 gap-2 mb-6">
            <DataField label="Plant" data={report.Plant?.name} />
            <DataField label="Address" data={formatAddressText(report)} />
          </div>
          <CompletedPlantInspectionView pi={report} />
        </div>
      </main>
    </div>
  );
}

export function AuthedPlantInspectionReport({ match }) {
  return <PlantInspectionReport match={match} isAuthenticated={true} />;
}
