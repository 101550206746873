import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Transition } from "@tailwindui/react";
import { NavLink, useHistory } from "react-router-dom";
import {
  ClipboardListIcon,
  GlobeIcon,
  DocumentIcon,
  OfficeBuildingIcon,
  ViewListIcon,
  ViewBoardsIcon,
  UsersIcon,
} from "@heroicons/react/solid";
import { MenuIcon } from "@heroicons/react/outline";
import { logoutAsync } from "../../state/slices/authSlice";
import Logo from "./Logo";
import Modal from "../Modal";
import TripForm from "../forms/TripForm";
import ParkForm from "../forms/ParkForm";
import SubmittalForm from "../forms/SubmittalForm";
import PlantForm from "../forms/PlantForm";
import CustomerForm from "../forms/CustomerForm";
import RetailerForm from "../forms/RetailerForm";
import { closeModal } from "../../state/slices/frontend";
import UserForm from "../forms/UserForm";
import { isHEUGSite } from "../../utils/isHEUGSite";

function NavbarLink({ to, icon, title }) {
  const IconComponent = icon;
  return (
    <NavLink
      to={to}
      activeClassName="bg-gray-900 text-white hover:bg-gray-900"
      className="hover:bg-gray-700 text-lg text-white hover:text-white px-3 py-2 rounded-md font-medium"
    >
      <span className="inline-flex justify-around items-center">
        <IconComponent className="w-5 h-5 mx-1" />
        <span className="text-md">{title}</span>
      </span>
    </NavLink>
  );
}

// function RegularUserLinks() {
//   return (
//     <>
//       <NavbarLink to="/45L/trips" icon={GlobeIcon} title="Trips" />
//       <NavbarLink to="/submittals" icon={ClipboardListIcon} title="Submittals" />
//       <NavbarLink to="/customers" icon={DocumentIcon} title="Customers" />
//       <NavbarLink to="/plants" icon={OfficeBuildingIcon} title="Plants" />
//       <NavbarLink to="/parks" icon={ViewListIcon} title="Parks" />
//       <NavbarLink to="/retailers" icon={ViewBoardsIcon} title="Retailers" />
//       <NavbarLink to="/users" icon={UsersIcon} title="Users" />
//     </>
//   );
// }

export function Links45L() {
  return (
    <>
      <NavbarLink to="/trips" icon={GlobeIcon} title="Trips" />
      <NavbarLink to="/submittals" icon={ClipboardListIcon} title="Submittals" />
      <NavbarLink to="/customers" icon={DocumentIcon} title="Customers" />
      <NavbarLink to="/plants" icon={OfficeBuildingIcon} title="Plants" />
      <NavbarLink to="/parks" icon={ViewListIcon} title="Parks" />
      <NavbarLink to="/retailers" icon={ViewBoardsIcon} title="Retailers" />
      <NavbarLink to="/users" icon={UsersIcon} title="Users" />
    </>
  );
}

export function LinksHEUG() {
  return (
    <>
      <NavbarLink to="/registrations/compare" icon={GlobeIcon} title="Registrations" />
      <NavbarLink to="/submittals" icon={ClipboardListIcon} title="Submittals" />
      <NavbarLink to="/customers" icon={DocumentIcon} title="Customers" />
      <NavbarLink to="/plants" icon={OfficeBuildingIcon} title="Plants" />
      <NavbarLink to="/parks" icon={ViewListIcon} title="Parks" />
      <NavbarLink to="/retailers" icon={ViewBoardsIcon} title="Retailers" />
      <NavbarLink to="/users" icon={UsersIcon} title="Users" />
    </>
  );
}

const Navbar = () => {
  const dispatch = useDispatch();
  const profileMenuRef = useRef(null);
  const navMenuRef = useRef(null);
  const history = useHistory();
  const [profileDropdownIsOpen, setProfileDropdownIsOpen] = useState(false);
  const [navMenu, setNavMenu] = useState(false);
  const { currentUser } = useSelector(state => state.auth);
  const { modals } = useSelector(state => state.frontend);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (
      profileMenuRef &&
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target) &&
      "user-menu-img" !== event.target.id
    ) {
      // click outsite profileMenuRef
      setProfileDropdownIsOpen(false);
    }
    if (navMenuRef && navMenuRef.current && !navMenuRef.current.contains(event.target)) {
      // click outsite profileMenuRef
      setNavMenu(false);
    }
  };

  const handleLogout = () => {
    dispatch(logoutAsync()).then(() => {
      history.push("/auth/signin");
    });
  };

  return (
    <nav className="fixed top-0 sm:relative bg-gray-800 w-full h-16">
      <div className="max-w-7xl h-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between md:h-16 h-full">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Logo />
            </div>
            {/* <h1 className="text-white ml-2 text-2xl font-medium">{isHEUGSite ? "HEUG" : "45L"}</h1> */}
            {/* Retailers don't see any links, regular users see links to all modules */}
            {currentUser?.role === "user" ? (
              <div className="hidden xl:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {isHEUGSite ? <LinksHEUG /> : <Links45L />}
                </div>
              </div>
            ) : null}
          </div>
          <div className="hidden xl:block">
            <div className="ml-4 flex items-center md:ml-6">
              {/* <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" />
              </button> */}
              {/* Profile dropdown */}

              {/*
          Dropdown menu, show/hide based on menu state.
kk
          Entering: "transition ease-out duration-100"
            From: "transform opacity-0 scale-95"
            To: "transform opacity-100 scale-100"
          Leaving: "transition ease-in duration-75"
            From: "transform opacity-100 scale-100"
            To: "transform opacity-0 scale-95"
        */}
              <div className="ml-3 relative">
                {/*  Profile dropdown */}
                <div>
                  <button
                    className="flex text-md font-semibold text-xl text-white border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition duration-150 ease-in-out"
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                    onClick={() => setProfileDropdownIsOpen(!profileDropdownIsOpen)}
                  >
                    {currentUser && currentUser.avatar ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={currentUser.avatar}
                        alt="User's avatar"
                      />
                    ) : (
                      <span className="h-8 w-8 rounded-full">
                        {currentUser ? currentUser.name[0] : "U"}
                      </span>
                    )}
                  </button>
                </div>

                <Transition
                  show={profileDropdownIsOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div
                    className="d-none origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg"
                    ref={profileMenuRef}
                  >
                    <div
                      className="py-1 rounded-md bg-white shadow-xs"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <button
                        className="w-full block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        role="menuitem"
                        onClick={handleLogout}
                      >
                        Log out
                      </button>
                    </div>
                  </div>
                </Transition>
              </div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="-mr-2 flex xl:hidden">
            <button
              type="button"
              className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-haspopup="true"
              onClick={() => setNavMenu(!navMenu)}
            >
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="block h-6 w-6" />
            </button>
          </div>
        </div>
        {/* Mobile menu, show/hide based on menu state. */}
        <Transition
          show={navMenu}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            ref={navMenuRef}
            className="xl:hidden bg-gray-600"
            id="mobile-menu"
            onClick={e => setNavMenu(!navMenu)}
          >
            {/* Retailers don't see any links, regular users see links to all modules */}
            {currentUser?.role === "user" ? (
              <div className="px-2 pt-2 pb-3 space-y-1 flex flex-col sm:px-3">
                {isHEUGSite ? <LinksHEUG /> : <Links45L />}
              </div>
            ) : null}
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="flex-shrink-0">
                  {currentUser && currentUser.avatar ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={currentUser.avatar}
                      alt="User's avatar"
                    />
                  ) : (
                    <span className="h-8 w-8 rounded-full text-white">
                      {currentUser ? currentUser.name[0] : "U"}
                    </span>
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium leading-none text-white">
                    {currentUser && currentUser.name}
                  </div>
                  <div className="text-sm font-medium leading-none text-gray-400">
                    {currentUser && currentUser.email}
                  </div>
                </div>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <button
                  onClick={handleLogout}
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </Transition>
      </div>
      <Modal
        modalShowing={modals.park.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "park" }))}
      >
        <ParkForm />
      </Modal>
      <Modal
        modalShowing={modals.trip.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "trip" }))}
      >
        <TripForm />
      </Modal>
      <Modal
        modalShowing={modals.customer.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "customer" }))}
      >
        <CustomerForm />
      </Modal>
      <Modal
        modalShowing={modals.plant.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "plant" }))}
      >
        <PlantForm />
      </Modal>
      <Modal
        modalShowing={modals.retailer.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "retailer" }))}
      >
        <RetailerForm />
      </Modal>
      <Modal
        modalShowing={modals.submittal.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "submittal" }))}
      >
        <SubmittalForm />
      </Modal>
      <Modal
        modalShowing={modals.user.isOpen}
        closeModal={() => dispatch(closeModal({ modal: "user" }))}
      >
        <UserForm />
      </Modal>
    </nav>
  );
};

export default Navbar;
