import React from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import FormikFieldWithLabel from "../../../common/FormikFieldWithLabel";
import { upsertHomeAsync } from "../../../../state/slices/homeSlice";
import { FormButtons } from "./SubmittalPhase";
import { selectParkOptions } from "../../../../state/slices/parkSlice";
import { selectPlantOptions } from "../../../../state/slices/plantSlice";
import { selectCustomerOptions } from "../../../../state/slices/customerSlice";
import FilteredPlantOptions from "../../FilteredPlantOptions";
import { validateSerialNumber } from "../../../../services/home";
import { deleteBugCausingFields } from "./util";

// TODO: remove setParentState after refactoring submittal data
// normalization and home selector in sub details page
export default function CertificationPhase({
  home,
  handleDelete,
  refreshSubmittal,
  setParentState,
}) {
  const { submittalId } = useParams();
  const dispatch = useDispatch();
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const parkOptions = useSelector(selectParkOptions);

  const initialValues = {
    // core info
    CustomerId: home.CustomerId || "",
    PlantId: home.PlantId || "",
    ParkId: home.ParkId || "",
    serialNumber: home.serialNumber || "",
    energyStar: home.energyStar || "",
    type: home.type || "",
    floorPlan: home.floorPlan || "",
    certType: home.certType || "",
    certDate: home.certDate || "",
    notes: home.notes || "",
    // location
    streetAddress: home.streetAddress || "",
    city: home.city || "",
    state: home.state || "",
    lotNumber: home.lotNumber || "",
    zipCode: home.zipCode || "",
    county: home.county || "",
    // unique plan details
    climateCode: home.climateCode || "",
    radiantBarrier: home.radiantBarrier || "",
  };

  const validator = Yup.object().shape({
    CustomerId: Yup.string().required("Required"),
    PlantId: Yup.string().required("Required"),
    serialNumber: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .test("checkDuplicateSerialNumber", "This serial number already exists", async value => {
        // don't perform check if it's own serialNumber, or you'll get a false negative
        if (initialValues.serialNumber === value) return true;
        else {
          return validateSerialNumber(value);
        }
      }),
    energyStar: Yup.string().required("Required").oneOf(["es", "nes"]),
  });

  const handleSubmit = async values => {
    deleteBugCausingFields(values);

    const { payload } = await dispatch(
      upsertHomeAsync({
        ...values,
        id: home.id,
        SubmittalId: submittalId,
      })
    );
    refreshSubmittal();
    const [updatedHome, isNew] = payload;
    if (!isNew) {
      updatedHome.CustomerId = home.CustomerId;
      setParentState(updatedHome);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validator}
      validateOnMount={true}
      validateOnChange={true}
      // initialTouched={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form className="flex flex-col">
          <FormButtons showDelete={home.id} handleDelete={handleDelete} />
          <div className="lg:flex flex-row">
            {/* Left column */}
            <div className="flex flex-col">
              <div className="p-3 bg-white rounded-lg m-2">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Core Information</h3>
                </div>
                <div className="gap-2 md:grid grid-cols-3">
                  <FormikFieldWithLabel
                    as="select"
                    name="CustomerId"
                    label="Customer"
                    error={touched.CustomerId && errors.CustomerId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    {customerOptions.map(customer => (
                      <option value={customer.value} key={customer.value}>
                        {customer.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    as="select"
                    name="PlantId"
                    label="Plant/HBF"
                    error={touched.PlantId && errors.PlantId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    <FilteredPlantOptions
                      plantOptions={plantOptions}
                      customerId={values.CustomerId}
                    />
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" type="select" label="Park" name="ParkId">
                    <option defaultValue value="">
                      ---
                    </option>
                    {parkOptions.map(park => (
                      <option value={park.value} key={park.value}>
                        {park.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    type="text"
                    name="serialNumber"
                    id="serialNumber"
                    label="Serial Number"
                    error={touched.serialNumber && errors.serialNumber}
                  />
                  <FormikFieldWithLabel
                    as="select"
                    label="Energy Star"
                    type="select"
                    name="energyStar"
                    error={touched.energyStar && errors.energyStar}
                    disabled={true}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="es">ES</option>
                    <option value="nes">NES</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    as="select"
                    name="type"
                    label="Type"
                    error={touched.type && errors.type}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="hud">HUD</option>
                    <option value="mod">MOD</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" name="floorPlan" label="Floor Plan">
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="base floor">Base Floor</option>
                    <option value="custom">Custom</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    label="Cert Type"
                    as="select"
                    name="certType"
                    error={touched.certType && errors.certType}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="30">30%</option>
                    <option value="mod-50">Mod50%</option>
                    <option value="no">No</option>
                    <option value="50">50%</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    className="col-span-2"
                    name="certDate"
                    label="Cert Date"
                    type="date"
                  />
                  <FormikFieldWithLabel
                    rows="3"
                    label="Notes"
                    as="textarea"
                    type="text"
                    name="notes"
                    id="notes"
                    className="col-span-3"
                  />
                </div>
              </div>
              <div className="col-span-3 p-5 bg-white rounded-lg mx-2 mt-1">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Unique Plan Details
                  </h3>
                </div>
                <div className="gap-2 grid grid-cols-4 md:grid-cols-6">
                  <FormikFieldWithLabel
                    type="text"
                    name="climateCode"
                    label="Climate Code"
                    className="col-span-2"
                  />

                  <FormikFieldWithLabel
                    as="select"
                    name="radiantBarrier"
                    className="col-span-2"
                    label="Radiant Barrier"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </FormikFieldWithLabel>
                </div>
              </div>
            </div>
            {/* Right column */}
            <div className="flex flex-col">
              <div className="m-2 p-5 bg-white rounded-lg ">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
                </div>
                <div className="md:grid gap-4 grid-cols-3">
                  <FormikFieldWithLabel
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    label="Street Address"
                    className="col-span-3"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="state"
                    id="state"
                    label="State"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="lotNumber"
                    id="lotNumber"
                    label="Lot #"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    label="Zip Code"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="county"
                    id="county"
                    label="County"
                    className="col-span-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
