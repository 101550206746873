import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { closeModal } from "../../state/slices/frontend";
import { createTripAsync, updateTripAsync } from "../../state/slices/tripSlice";
import { useUserOptions } from "../../state/slices/userSlice";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import FormHeader from "../common/forms/FormHeader";
import { formatInputDate } from "../../utils/formatDate";
import { SubmitButton } from "../common/Buttons";

export default function StatefulTripForm() {
  const dispatch = useDispatch();
  const initialValues = useSelector(state => state.frontend.modals.trip.target || {});
  const userOptions = useUserOptions();
  const handleSubmitTrip = async trip => {
    stripUnfilledValues(trip);
    if (trip.id) {
      await dispatch(updateTripAsync(trip));
      dispatch(closeModal({ modal: "trip" }));
    } else {
      await dispatch(createTripAsync(trip));
      dispatch(closeModal({ modal: "trip" }));
    }
    // dispatch(closeModal({ modal: "trip" }));
  };
  return (
    <TripForm trip={initialValues} handleSubmit={handleSubmitTrip} userOptions={userOptions} />
  );
}

export function TripForm({ trip, handleSubmit, userOptions }) {
  const TripSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    UserId: Yup.string().required("Required"),
    startDate: Yup.date().required("Required"),
    endDate: Yup.date().required("Required"),
  });
  const initialValues = {
    name: trip.name || "",
    UserId: trip.UserId || "",
    startDate: formatInputDate(trip.startDate) || "",
    endDate: formatInputDate(trip.endDate) || "",
  };
  // do this in a separate step because new trips should not have an id property
  if (trip.id) initialValues.id = trip.id;
  return (
    <Formik
      className="p-4"
      initialValues={initialValues}
      validationSchema={TripSchema}
      enableReinitialize
      onSubmit={values => handleSubmit(values)}
    >
      {({ touched, errors }) => (
        <Form>
          <FormHeader>{trip.id ? "Edit Trip" : "Add Trip"}</FormHeader>
          <div className="grid grid-cols-2">
            <FormikFieldWithLabel
              name="name"
              className="col-span-2"
              label="Name"
              error={touched.name && errors.name}
            />
            <FormikFieldWithLabel
              name="UserId"
              label="Field Verifier"
              className="col-span-2"
              as="select"
              error={touched.UserId && errors.UserId}
            >
              <option defaultValue value="">
                ---
              </option>
              {userOptions.map(user => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              name="startDate"
              label="Start Date"
              className="col-span-1"
              type="date"
              error={touched.startDate && errors.startDate}
            />
            <FormikFieldWithLabel
              name="endDate"
              label="End Date"
              className="col-span-1"
              type="date"
              error={touched.endDate && errors.endDate}
            />
          </div>
          <div className="flex justify-end m-4 mt-6">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
}
