import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { MagicTable } from "../../common/Table";
import { SearchFieldVerificationsForm } from "../../forms/SearchForms";
import { useSelector } from "react-redux";
import { selectCustomerNamesWithPlantIds } from "../../../state/slices/customerSlice";
import { selectAllPlantNamesAndIds } from "../../../state/slices/plantSlice";
import formatAddressText from "../../../utils/formatAddress";
import { stripUnfilledValues_pureFunction } from "../../../utils/forms/stripUnfilledValues";

const page = 1;

export default function SearchFieldVerifications() {
  const history = useHistory();
  const [state, setState] = useState({
    sortOrder: "desc",
    currentPage: page,
    maxPage: 1,
    filters: {},
    homes: [],
  });

  const customers = useSelector(selectCustomerNamesWithPlantIds);
  const plants = useSelector(selectAllPlantNamesAndIds);

  // Initial Field Verifications load
  useEffect(() => {
    // cancels the request if the component is unmounted before the request completes
    const source = axios.CancelToken.source();
    axios
      .get("/api/search", { params: { page }, cancelToken: source.token })
      .then(value => {
        const { rows, count, pages } = value.data;
        setState(prevState => ({
          ...prevState,
          homes: rows,
          maxPage: pages,
          count,
        }));
      })
      .catch(() => {});
    return source.cancel;
  }, []);

  const homeColumns = useMemo(() => {
    const getCustomerNameByPlantId = id => customers.find(c => c.PlantId === id)?.name;
    const getPlantNameById = id => plants.find(p => p.id === id)?.name;

    return [
      {
        caption: "Edit",
        type: "actions",
        cellDisplay(value) {
          return (
            <div className="flex">
              <Link
                to={{
                  pathname: `/trip/details/${value.TripId}`,
                  // PI don't have type as a value
                  search: value.type ? `?fvId=${value.id}` : `?piId=${value.id}`,
                }}
                onClick={e => e.stopPropagation()}
                className="text-blue-500 hover:text-blue-700 transition-all"
              >
                Edit
              </Link>
            </div>
          );
        },
      },
      {
        caption: "Customer Name",
        dataField: "customerName",
        cellDisplay(value) {
          return (
            <span className="uppercase text-sm">{getCustomerNameByPlantId(value?.PlantId)}</span>
          );
        },
      },
      {
        caption: "Plant",
        class: "truncate w-32",
        cellDisplay(value) {
          return getPlantNameById(value?.PlantId);
        },
      },
      {
        caption: "Serial Number",
        dataField: "serialNumber",
        cellDisplay(value) {
          return <span className="uppercase text-sm">{value.serialNumber || ""}</span>;
        },
      },
      {
        caption: "Type",
        dataField: "type",
        cellDisplay(value) {
          return <span className="uppercase text-sm">{value.type || "Plant"}</span>;
        },
      },
      {
        caption: "EnergyStar",
        cellDisplay(value) {
          return (
            <span className="uppercase text-sm">
              {value.energyStarLabel === "1"
                ? "ES"
                : value.energyStarLabel === "0" && value.type
                ? "NES"
                : ""}
            </span>
          );
        },
      },
      {
        caption: "Address",
        cellDisplay: formatAddressText,
      },
    ];
  }, [customers, plants]);

  const onSearch = async values => {
    const cleanedValues = stripUnfilledValues_pureFunction(values);
    const response = await axios.get("/api/search", { params: { ...cleanedValues } });
    const { rows, count, pages } = response.data;
    setState(prevState => ({
      ...prevState,
      filters: cleanedValues,
      homes: rows,
      maxPage: pages,
      count: count,
    }));
  };

  const changePage = async page => {
    const response = await axios.get("/api/search", {
      params: { ...state.filters, page },
    });
    const { rows, count, pages } = response.data;

    setState(prevState => ({
      ...prevState,
      currentPage: page,
      homes: rows,
      maxPage: pages,
      count,
    }));
  };

  function handleRowClick(value) {
    value.type
      ? history.push(`/trip/details/${value.TripId}?fvId=${value.id}`)
      : history.push(`/trip/details/${value.TripId}?piId=${value.id}`);
  }

  return (
    <div className="flex content-between md:h-5/6 px-2 pt-3">
      <div className="mt-3">
        <SearchFieldVerificationsForm searchHandler={onSearch} />
      </div>
      <div className=" w-3/4 ">
        <MagicTable
          className="max-h-xl"
          columns={homeColumns}
          items={state.homes}
          remote={true}
          setPage={changePage}
          maxPage={state.maxPage}
          currentPage={state.currentPage}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
}
