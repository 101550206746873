import React from "react";
import { DocumentTextIcon } from "@heroicons/react/outline";
import FormHeader from "./forms/FormHeader";
import ImagePreview, { CDN_URL } from "./ImagePreview";

export default function ImageListWithDetails({ images }) {
  const isAnyImagePresent = images.find(i => i.source !== null);
  return (
    <>
      {isAnyImagePresent && <FormHeader>Photos</FormHeader>}
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        <ImagesWithPreview images={images} />
      </ul>
    </>
  );
}

export function ImagesWithPreview({ images }) {
  return images.map(file => {
    const isSourceMissing = !file.source; // no file was uploaded for this field
    const isPdf = /.pdf$/.test(file.source);

    if (isSourceMissing) return null;
    if (isPdf) return <PdfPreview key={file.source} doc={file} />;
    else return <ImagePreview src={file.source} caption={file.title} key={file.source} />;
  });
}

function PdfPreview({ doc }) {
  const src = CDN_URL + doc.source;
  return (
    <div className="my-2 pr-4">
      <a
        href={src}
        target="_blank"
        rel="noreferrer"
        className="h-36 w-36 border border-gray-300 bg-gray-300 text-gray-300 rounded-lg hover:opacity-80 cursor-pointer flex  justify-center items-center"
      >
        <DocumentTextIcon className="h-8 text-gray-800" />
      </a>
      <p className="text-sm text-gray-800 block">{doc.title}</p>
    </div>
  );
}
