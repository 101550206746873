import React, { useState } from "react";
import { CheckCircleIcon, PencilIcon } from "@heroicons/react/outline";
import Modal from "../../../Modal";
import ImageUploadForm from "./ImageUploadForm";
import { CDN_URL } from "../../../common/ImagePreview";
import FormHeader from "../../../common/forms/FormHeader";
import { ImagesWithPreview } from "../../../common/ImageListWithDetails";

function EditableImages({ images, fvId, piId, refreshTrip }) {
  return images.map((file, idx) => (
    <EditableImagePreview
      src={file.source}
      caption={file.title}
      name={file.name}
      key={idx}
      fvId={fvId}
      piId={piId}
      refreshTrip={refreshTrip}
    />
  ));
}

function EditableImagePreview({ src, caption, name, fvId, piId, refreshTrip }) {
  const [isImageOverlayOpen, setIsImageOverlayOpen] = useState(false);
  const openImageUploadForm = () => setIsImageOverlayOpen(true);
  const closeImageUploadForm = () => setIsImageOverlayOpen(false);
  const imageSrc = CDN_URL + src;
  const isPdf = /.pdf$/.test(src);

  return (
    <div className="my-2 pr-4">
      <div
        className="h-36 w-36 border border-gray-300 bg-gray-400 text-gray-300 rounded-lg hover:opacity-80 cursor-pointer"
        onClick={openImageUploadForm}
        data-testid={`img-upload-${name}`}
      >
        {src ? (
          <>
            {isPdf ? (
              <div className="h-36 w-36 border border-gray-300 text-gray-100 rounded-lg hover:opacity-80 cursor-pointer p-2 flex justify-center">
                PDF document
              </div>
            ) : (
              <img
                src={imageSrc}
                className="h-36 w-36 border border-gray-300 text-gray-300 rounded-lg hover:opacity-80 cursor-pointer"
                alt={caption}
              />
            )}
            <PencilIcon className="-mt-20 ml-14 h-7 w-7 text-white cursor-pointer" />
          </>
        ) : (
          <PencilIcon className="mt-14 ml-14 h-7 w-7 text-white cursor-pointer" />
        )}
      </div>
      <span className="text-sm text-gray-800 block">{caption}</span>
      <Modal modalShowing={isImageOverlayOpen} closeModal={closeImageUploadForm} padded={false}>
        <ImageUploadForm
          field={name}
          title={caption}
          fvId={fvId}
          piId={piId}
          value={src}
          closeModal={closeImageUploadForm}
          refreshTrip={refreshTrip}
        />
      </Modal>
    </div>
  );
}

export default function EditableImageList({ images, fvId, piId, refreshTrip }) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <div className="flex items-center">
        <FormHeader>Photos</FormHeader>
        {isEditing ? (
          <CheckCircleIcon
            onClick={() => setIsEditing(e => !e)}
            className="ml-3 mt-1 h-7 w-7 text-blue-500 p-1 cursor-pointer"
            data-testid="done-editing-photos"
          />
        ) : (
          <PencilIcon
            onClick={() => setIsEditing(e => !e)}
            className="ml-3 mt-1 h-7 w-7 text-blue-500 p-1 cursor-pointer"
            data-testid="edit-photos"
          />
        )}
      </div>
      <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {isEditing ? (
          <EditableImages images={images} fvId={fvId} piId={piId} refreshTrip={refreshTrip} />
        ) : (
          <ImagesWithPreview images={images} />
        )}
      </ul>
    </>
  );
}
