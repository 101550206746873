import axios from "axios";
import { ReactSession } from "react-client-session";
ReactSession.setStoreType("cookie");

export const loginUser = async payload => {
  const { data } = await axios.post(`/api/auth/signin`, payload);
  return data;
};

export const loginUserTo45L = async payload => {
  const { data } = await axios.post(`/api/auth/signin-45L`, payload);
  return data;
};

export const checkStatus = async () => {
  const { data } = await axios.get(`/api/auth/status`);
  return data;
};

export const getUserFromSession = () => ReactSession?.get("currentUser");

export const setAuthenticatedUserInSession = user =>
  ReactSession.set("currentUser", { user, isAuthenticated: true });
