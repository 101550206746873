import axios from "axios";
import { createQueryString } from "../utils/searchParams";

async function postFvPhoto(fvId, data) {
  const res = await axios.post(`/api/fieldVerification/upload/${fvId}`, data, {
    "Content-Type": "multipart/form-data",
  });
  const fileName = res.data;
  return fileName;
}

// uploads image to our server, which in turn uploads to S3
// DEPRECATED: this approach placed too much resource strain on our server so we preferred to upload directly to S3 from client
export const uploadSingleImageToBackend = async (photoFile, fieldName, fvId) => {
  try {
    const data = new FormData();
    data.append(fieldName, photoFile);
    return postFvPhoto(fvId, data);
  } catch (error) {
    console.error(error);
  }
};

export const uploadImageToS3 = async (file, fvId, field) => {
  const extension = file.type.split("/")[1];
  // get the url with correct headers to upload to S3; the back-end has the appropriate access keys
  const signedUrlRes = await axios.get(
    "/api/get-signed-url" + createQueryString({ fvId: fvId, field, extension })
  );
  // upload photo to S3
  await axios.put(signedUrlRes.data.signedRequest, file);

  return "/" + signedUrlRes.data.url; // resource location in S3
};
