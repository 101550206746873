import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignInHEUGPage from "./components/auth/SignInHEUG";
import SignIn45LPage from "./components/auth/SignIn45L";
import SignUpPage from "./components/auth/SignUp";
import RetailerSignUpPage from "./components/auth/RetailerSignUp";
import ForgotPasswordPage from "./components/auth/Forgot";
import ResetPasswordPage from "./components/auth/Reset";
import SubmittalDetailsHEUG from "./components/pages/SubmittalDetailsHEUG";
import Navbar from "./components/nav/Navbar";
import SubmittalsHEUG from "./components/pages/SubmittalsHEUG";
import SearchHomesHEUG from "./components/pages/SearchHomesHEUG";
import Users from "./components/pages/Users";
import Plants from "./components/pages/Plants";
import Customers from "./components/pages/Customers";
import Parks from "./components/pages/Parks";
import Retailers from "./components/pages/Retailer";
import Trips from "./components/pages/trip/Trips";
import TripReports from "./components/pages/trip/reports/TripReports";
import TripDetailsPage from "./components/pages/trip/trip-details/TripDetails";
import SubmittalImporterHEUG from "./components/pages/SubmittalImporterHEUG";
import { checkAuthStatusAsync } from "./state/slices/authSlice";
import { readAllSubmittalsAsync } from "./state/slices/submittalSlice";
import { readAllCustomersAsync } from "./state/slices/customerSlice";
import { readAllPlantsAsync } from "./state/slices/plantSlice";
import { readAllParksAsync } from "./state/slices/parkSlice";
import { readAllRetailersAsync } from "./state/slices/retailerSlice";
import { readAllUsersAsync } from "./state/slices/userSlice";
import store from "./state/store/store";
import TripReportByPlant, {
  PublicTripReportByPlant,
} from "./components/pages/trip/reports/TripReportByPlant";
import FieldVerificationReport, {
  AuthedFieldVerificationReport,
} from "./components/pages/trip/reports/FieldVerificationReport";
import PlantInspectionReport, {
  AuthedPlantInspectionReport,
} from "./components/pages/trip/reports/PlantInspectionReport";
import CompareHomeAndFV from "./components/pages/CompareHomeAndFV";
import { readAllTripsAsync } from "./state/slices/tripSlice";
import HomePage from "./components/pages/HomePage";
import Notification from "./components/common/Notification";
import RegistrationsComparePage from "./components/pages/registrations/RegistrationsComparePage";
import RegistrationsPostModellingPage from "./components/pages/registrations/RegistrationsPostModellingPage";
import SearchFieldVerifications from "./components/pages/trip/SearchFieldVerifications";
import RegistrationsByRetailer from "./components/pages/registrations/RegistrationsByRetailer";
import SubmittalImporter45L from "./components/pages/SubmittalImporter-45L";
import Submittals45L from "./components/pages/Submittals-45L";
import SearchHomes45L from "./components/pages/SearchHomes-45L";
import SubmittalDetails45L from "./components/pages/SubmittalDetails-45L";
import { isHEUGSite, getIsOnRetailerSite } from "./utils/isHEUGSite";

// export for test (App.test.js), for dev and prod use the default export WrappedApp
export function App() {
  const dispatch = useDispatch();
  const { isAuthenticated, loading } = useSelector(state => state.auth);
  const currentUser = useSelector(state => state.auth.currentUser);

  const isOnRetailerSite = getIsOnRetailerSite();

  const SignInPage = isHEUGSite ? SignInHEUGPage : SignIn45LPage;
  const SubmittalsPage = isHEUGSite ? SubmittalsHEUG : Submittals45L;
  const SubmittalImporterPage = isHEUGSite ? SubmittalImporterHEUG : SubmittalImporter45L;
  const SubmittalDetailsPage = isHEUGSite ? SubmittalDetailsHEUG : SubmittalDetails45L;
  const SearchHomesPage = isHEUGSite ? SearchHomesHEUG : SearchHomes45L;
  const isRetailerUser = currentUser?.role === "retailer";
  const isRegularUser = currentUser?.role === "user";

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(readAllSubmittalsAsync());
      dispatch(readAllCustomersAsync());
      dispatch(readAllPlantsAsync());
      dispatch(readAllParksAsync());
      dispatch(readAllRetailersAsync());
      dispatch(readAllUsersAsync());
      dispatch(readAllTripsAsync());
    } else {
      dispatch(checkAuthStatusAsync());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <Notification />
      {!isAuthenticated && !loading && (
        <Switch>
          {!isOnRetailerSite && <Route exact path="/auth/signup" component={SignUpPage} />}
          {isOnRetailerSite && (
            <Route exact path="/auth/retailer/signup" component={RetailerSignUpPage} />
          )}
          <Route exact path="/auth/forgot" component={ForgotPasswordPage} />
          <Route exact path="/auth/reset" component={ResetPasswordPage} />
          {/* Public pages */}
          <Route path="/trips/reports/details/pi/:id" component={PlantInspectionReport} />
          <Route path="/trips/reports/details/:fvId" component={FieldVerificationReport} />
          <Route path="/trips/reports/:plantId" component={PublicTripReportByPlant} />
          <Route path="/submittals/compare/:fvId" component={CompareHomeAndFV} />
          {/* the default route when unauthenticated */}
          <Route path="/" component={SignInPage} />
        </Switch>
      )}
      {isAuthenticated && (
        <div className="bg-gray-200 min-h-screen">
          <Navbar />
          {!isOnRetailerSite && isRegularUser && (
            <Switch>
              <Route exact path="/submittals" component={SubmittalsPage} />
              <Route path="/submittals/import" component={SubmittalImporterPage} />
              <Route path="/submittals/edit/:submittalId?" component={SubmittalDetailsPage} />
              <Route path="/submittals/search" component={SearchHomesPage} />
              <Route path="/registrations/compare" component={RegistrationsComparePage} />
              <Route
                path="/registrations/post-modelling"
                component={RegistrationsPostModellingPage}
              />
              <Route path="/submittals/compare/:fvId" component={CompareHomeAndFV} />
              <Route path="/trips/reports/details/pi/:id" component={AuthedPlantInspectionReport} />
              <Route
                path="/trips/reports/details/:fvId"
                component={AuthedFieldVerificationReport}
              />
              <Route path="/trips/reports/:plantId" component={TripReportByPlant} />
              <Route path="/trips/reports" component={TripReports} />
              <Route path="/trip/details/:tripId" component={TripDetailsPage} />
              <Route path="/trips/search" component={SearchFieldVerifications} />
              <Route path="/trips" component={Trips} />
              <Route path="/users" component={Users} />
              <Route path="/customers" component={Customers} />
              <Route path="/plants" component={Plants} />
              <Route path="/parks" component={Parks} />
              <Route path="/retailers" component={Retailers} />
              <Route exact path="/" component={HomePage} />
            </Switch>
          )}
          {isOnRetailerSite && isRetailerUser && (
            <Switch>
              <Route path="/registrations" component={RegistrationsByRetailer} />
              <Route exact path="/" component={HomePage} />
            </Switch>
          )}
        </div>
      )}
    </>
  );
}

export function StatefulWrapperWithRouter({ children }) {
  return (
    <Provider store={store}>
      <Router>{children}</Router>
    </Provider>
  );
}

function WrappedApp() {
  return (
    <StatefulWrapperWithRouter>
      <App />
    </StatefulWrapperWithRouter>
  );
}

export default WrappedApp;
