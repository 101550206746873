import React from "react";
import FormHeader from "../../../common/forms/FormHeader";
import ImageListWithDetails from "../../../common/ImageListWithDetails";
import { formatDisplayDate } from "../../../../utils/formatDate";
import { booleanToYesOrNo } from "../../../../utils/formatBooleanToString";
import { isNullOrUndefined } from "../../../../utils/isNullOrUndefined";
import { DataField } from "../../../common/DataField";
import { capitalize } from "lodash";

export default function CompletedFieldVerificationView({ fv }) {
  // photos in list
  // does not include cancellation photo because it's in a separate section
  const fvPhotoFields = [
    { title: "Address", source: fv.addressPhoto },
    { title: "Red Tag  #1", source: fv.redTagPhoto },
    { title: "Red Tag #2", source: fv.redTagPhoto2 },
    { title: "Home (Side)", source: fv.sideOfHomePhoto },
    { title: "Model Number", source: fv.modelNumberPhoto },
    { title: "Energy Star", source: fv.energyStarPhoto },
    { title: "Condenser Model No.", source: fv.condenserModelNumberPhoto },
    { title: "HVAC Model No.", source: fv.HVACModelNumberPhoto },
    { title: "Misc.", source: fv.miscPhoto },
    { title: "Misc.", source: fv.extraPhoto },
  ];

  return (
    <div>
      <FormHeader className="mb-6">Summary</FormHeader>
      <div className="grid grid-cols-2 gap-2 mb-6">
        {fv.User?.name && (
          <DataField
            label="Verified By"
            data={`${fv.User?.name} (${fv.User?.RTIN || "RTIN missing"})`}
          />
        )}
        {fv.completedDate && (
          <DataField label="Verification Date" data={formatDisplayDate(fv.completedDate)} />
        )}
        <DataField label="Serial Number" data={fv.serialNumber} />
        <DataField label="Model Number" data={fv.modelNumber} />
        <DataField label="Type" data={fv.type} />
        {fv.verificationNotes && (
          <DataField label="Field verifier notes" data={fv.verificationNotes} />
        )}
        {fv.HVACDistribution && (
          <DataField label="HVAC Distribution" data={capitalize(fv.HVACDistribution)} />
        )}
        {!isNullOrUndefined(fv.verificationOfDuctsPlenums) && (
          <DataField
            label="Verification Of Ducts Plenums"
            data={booleanToYesOrNo(fv.verificationOfDuctsPlenums)}
          />
        )}
        {!isNullOrUndefined(fv.refrigerantChargeThermostaticExpansionValve) && (
          <DataField
            label="Refrigerant Charge Thermostatic Expansion Valve"
            data={booleanToYesOrNo(fv.refrigerantChargeThermostaticExpansionValve)}
          />
        )}
        {!isNullOrUndefined(fv.raterVerifiedAdequateAirflow) && (
          <DataField
            label="Rater Verified Adequate Airflow"
            data={booleanToYesOrNo(fv.raterVerifiedAdequateAirflow)}
          />
        )}
        {!isNullOrUndefined(fv.reducedFanPower) && (
          <DataField label="Reduced Fan Power" data={booleanToYesOrNo(fv.reducedFanPower)} />
        )}
        {!isNullOrUndefined(fv.targetDuctLeakage) && (
          <DataField label="Target Duct Leakage" data={booleanToYesOrNo(fv.targetDuctLeakage)} />
        )}
        {!isNullOrUndefined(fv.isOutsideCondenserOnSite) && (
          <DataField
            label="Outside Condenser is On Site"
            data={booleanToYesOrNo(fv.isOutsideCondenserOnSite)}
          />
        )}
        {!isNullOrUndefined(fv.gas) && <DataField label="Gas" data={booleanToYesOrNo(fv.gas)} />}
        {!isNullOrUndefined(fv.tightConstructionInfiltration) && (
          <DataField
            label="Tight Construction Infiltration"
            data={booleanToYesOrNo(fv.tightConstructionInfiltration)}
          />
        )}
        {!isNullOrUndefined(fv.qualityInsulationInstallation) && (
          <DataField
            label="Quality Insulation Installation"
            data={booleanToYesOrNo(fv.qualityInsulationInstallation)}
          />
        )}
        {!isNullOrUndefined(fv.energyStarLabel) && (
          <DataField label="Energy Star Label" data={booleanToYesOrNo(fv.energyStarLabel)} />
        )}
      </div>
      <ImageListWithDetails images={fvPhotoFields} />
    </div>
  );
}
