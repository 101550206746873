import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormHeader from "../../../../common/forms/FormHeader";
import EditableImageList from "../EditableImageList";
import FormikFieldWithLabel from "../../../../common/FormikFieldWithLabel";
import { formatDisplayDate, formatInputDate } from "../../../../../utils/formatDate";
import { useDispatch } from "react-redux";
import { updateFieldVerificationAsync } from "../../../../../state/slices/tripSlice";
import { validateSerialNumber } from "../../../../../services/home";
import { SubmitButton } from "../../../../common/Buttons";
import { DataField } from "../../../../common/DataField";
import { useUserOptions } from "../../../../../state/slices/userSlice";

export default function CompletedFieldVerificationView({ fv, refreshTrip }) {
  const dispatch = useDispatch();
  const userOptions = useUserOptions();
  // photos in list
  // does not include cancellation photo because it's in a separate section
  const fvPhotoFields = [
    { title: "Address", source: fv.addressPhoto, name: "addressPhoto" },
    { title: "Red Tag #1", source: fv.redTagPhoto, name: "redTagPhoto" },
    { title: "Red Tag #2", source: fv.redTagPhoto2, name: "redTagPhoto2" },
    { title: "Home (Side)", source: fv.sideOfHomePhoto, name: "sideOfHomePhoto" },
    { title: "Model Number", source: fv.modelNumberPhoto, name: "modelNumberPhoto" },
    { title: "Energy Star", source: fv.energyStarPhoto, name: "energyStarPhoto" },
    {
      title: "Condenser Model No.",
      source: fv.condenserModelNumberPhoto,
      name: "condenserModelNumberPhoto",
    },
    { title: "HVAC Model No.", source: fv.HVACModelNumberPhoto, name: "HVACModelNumberPhoto" },
    { title: "Misc.", source: fv.miscPhoto, name: "miscPhoto" },
    { title: "Misc.", source: fv.extraPhoto, name: "extraPhoto" },
  ];

  function initializeBooleanValue(val) {
    return val === null ? "" : val;
  }

  const initialValues = {
    serialNumber: fv.serialNumber || "",
    modelNumber: fv.modelNumber || "",
    type: fv.type || "",
    verificationNotes: fv.verificationNotes || "",
    HVACDistribution: initializeBooleanValue(fv.HVACDistribution),
    verificationOfDuctsPlenums: initializeBooleanValue(fv.verificationOfDuctsPlenums),
    refrigerantChargeThermostaticExpansionValve: initializeBooleanValue(
      fv.refrigerantChargeThermostaticExpansionValve
    ),
    raterVerifiedAdequateAirflow: initializeBooleanValue(fv.raterVerifiedAdequateAirflow),
    reducedFanPower: initializeBooleanValue(fv.reducedFanPower),
    targetDuctLeakage: initializeBooleanValue(fv.targetDuctLeakage),
    isOutsideCondenserOnSite: initializeBooleanValue(fv.isOutsideCondenserOnSite),
    gas: initializeBooleanValue(fv.gas),
    tightConstructionInfiltration: initializeBooleanValue(fv.tightConstructionInfiltration),
    qualityInsulationInstallation: initializeBooleanValue(fv.qualityInsulationInstallation),
    energyStarLabel: initializeBooleanValue(fv.energyStarLabel),
    status: fv.status || "",
    UserId: fv.UserId || "",
    completedDate: formatInputDate(fv.completedDate) || "",
    id: fv.id || "",
  };

  const HomeSchema = Yup.object().shape({
    serialNumber: Yup.string()
      .required("Required")
      .test("checkDuplicateSerialNumber", "This serial number already exists", async value => {
        if (initialValues.serialNumber === value) return true;
        else {
          return validateSerialNumber(value);
        }
      }),
    modelNumber: Yup.string(),
    type: Yup.string().required("Required"),
  });

  // allows "unsetting" yes-no fields
  function convertEmptyStringToNull(obj) {
    for (const prop in obj) {
      if (obj[prop] === "") {
        obj[prop] = null;
      }
    }
  }

  const handleSubmit = async fv => {
    convertEmptyStringToNull(fv);
    await dispatch(updateFieldVerificationAsync(fv));
    refreshTrip();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={HomeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values }) => (
        <Form>
          <FormHeader className="mt-4">Summary</FormHeader>
          <div className="grid grid-cols-2 gap-2 mb-6">
            {fv.User?.name && (
              <DataField
                label="Verified By"
                data={`${fv.User?.name} (${fv.User?.RTIN || "RTIN missing"})`}
              />
            )}
            {fv.completedDate && (
              <DataField label="Verification Date" data={formatDisplayDate(fv.completedDate)} />
            )}
            {fv.Reviewer?.name && (
              <DataField
                label="Reviewed by"
                data={`${fv.Reviewer?.name} (${fv.Reviewer?.RTIN || "RTIN missing"})`}
              />
            )}
            {fv.reviewDate && (
              <DataField label="Review Date" data={formatDisplayDate(fv.reviewDate)} />
            )}
            <DataField label="Created date" data={formatDisplayDate(fv.createdAt)} />
          </div>
          <div className="grid grid-cols-3 gap-2">
            <FormikFieldWithLabel
              name="serialNumber"
              label="Serial Number"
              type="text"
              className="col-span-1"
              error={touched.serialNumber && errors.serialNumber}
            />
            <FormikFieldWithLabel
              name="modelNumber"
              label="Model Number"
              type="text"
              className="col-span-1"
              error={touched.modelNumber && errors.modelNumber}
            />
            <FormikFieldWithLabel
              as="select"
              name="type"
              label="Type"
              error={touched.type && errors.type}
            >
              <option value="">---</option>
              <option value="HUD">HUD</option>
              <option value="MOD">MOD</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              name="verificationNotes"
              label="Field verifier notes"
              type="text"
              className="col-span-3"
              error={touched.verificationNotes && errors.verificationNotes}
              as="textarea"
              fieldClass="h-24 resize-y"
            />
            <FormikFieldWithLabel
              as="select"
              name="HVACDistribution"
              label="HVAC Distribution"
              error={touched.HVACDistribution && errors.HVACDistribution}
            >
              <option value="">---</option>
              <option value="floor">Floor (conditioned)</option>
              <option value="attic">Attic</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="verificationOfDuctsPlenums"
              label="Verification of Ducts Plenums"
              error={touched.verificationOfDuctsPlenums && errors.verificationOfDuctsPlenums}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="refrigerantChargeThermostaticExpansionValve"
              label="Refrigerant Charge Thermostatic Expansion Valve"
              error={
                touched.refrigerantChargeThermostaticExpansionValve &&
                errors.refrigerantChargeThermostaticExpansionValve
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="raterVerifiedAdequateAirflow"
              label="Rater Verified Adequate Airflow"
              error={touched.raterVerifiedAdequateAirflow && errors.raterVerifiedAdequateAirflow}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="reducedFanPower"
              label="Reduced Fan Power"
              error={touched.reducedFanPower && errors.reducedFanPower}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="targetDuctLeakage"
              label="Target Duct Leakage"
              error={touched.targetDuctLeakage && errors.targetDuctLeakage}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isOutsideCondenserOnSite"
              label="Outside Condenser is On Site"
              error={touched.isOutsideCondenserOnSite && errors.isOutsideCondenserOnSite}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="gas"
              label="Gas"
              error={touched.gas && errors.gas}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="tightConstructionInfiltration"
              label="Tight Construction Infiltration"
              error={touched.tightConstructionInfiltration && errors.tightConstructionInfiltration}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="qualityInsulationInstallation"
              label="Quality Insulation Installation"
              error={touched.qualityInsulationInstallation && errors.qualityInsulationInstallation}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="energyStarLabel"
              label="Energy Star Label"
              error={touched.energyStarLabel && errors.energyStarLabel}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="status"
              label="Status"
              error={touched.status && errors.status}
            >
              <option value="scheduled">Scheduled</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="UserId"
              label="Verified By"
              error={touched.UserId && errors.UserId}
            >
              <option defaultValue value="">
                ---
              </option>
              {userOptions.map(user => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              name="completedDate"
              label="Verification Date"
              className="col-span-1"
              type="date"
              error={touched.completedDate && errors.completedDate}
            />
          </div>
          <SubmitButton />
          <EditableImageList images={fvPhotoFields} fvId={fv.id} refreshTrip={refreshTrip} />
        </Form>
      )}
    </Formik>
  );
}
