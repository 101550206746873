import { useSelector } from "react-redux";
// import {
//   ClipboardListIcon,
//   UsersIcon,
//   DocumentIcon,
//   OfficeBuildingIcon,
//   ViewListIcon,
//   ViewBoardsIcon,
//   GlobeIcon,
// } from "@heroicons/react/solid";
// import { NavLink } from "react-router-dom";
import { isHEUGSite } from "../../utils/isHEUGSite";
import RegistrationForm from "./RegistrationForm";
import RegistrationsComparePage from "./registrations/RegistrationsComparePage";
import Trips from "./trip/Trips";

export default function HomePage() {
  const currentUser = useSelector(state => state.auth.currentUser);
  const isRetailer = currentUser.role === "retailer";
  const isRegularUser = currentUser.role === "user";

  if (isHEUGSite && isRetailer) {
    return <RegistrationForm />;
  } else if (isHEUGSite && isRegularUser) {
    return <RegistrationsComparePage />;
  } else {
    // on 45L site (only regular users can login)
    return <Trips />;
  }
}

// DEPRECATED
// function MainNav() {
//   return (
//     <div className="flex flex-col mt-20 items-center gap-2">
//       <MainNavLink to="/trips" icon={GlobeIcon} title="45L" />
//       <MainNavLink to="/registrations/compare" icon={ClipboardListIcon} title="HEUG" />
//       <MainNavLink to="/customers" icon={DocumentIcon} title="Customers" />
//       <MainNavLink to="/plants" icon={OfficeBuildingIcon} title="Plants" />
//       <MainNavLink to="/parks" icon={ViewListIcon} title="Parks" />
//       <MainNavLink to="/retailers" icon={ViewBoardsIcon} title="Retailers" />
//       <MainNavLink to="/users" icon={UsersIcon} title="Users" />
//     </div>
//   );
// }

// function MainNavLink({ to, icon, title }) {
//   const IconComponent = icon;
//   return (
//     <NavLink
//       to={to}
//       activeClassName="bg-gray-900 text-white hover:bg-gray-900"
//       className="w-64 bg-gray-300 hover:bg-gray-400 text-lg px-3 py-2 rounded-md font-medium"
//     >
//       <span className="w-full inline-flex justify-center items-center">
//         <IconComponent className="w-5 h-5 mr-2" />
//         <span className="text-md mr-5">{title}</span>
//       </span>
//     </NavLink>
//   );
// }
