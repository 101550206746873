import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../nav/Logo";

export default function Forgot() {
  const [state, setState] = useState({});
  const [status, setStatus] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (state.email) {
        const emailTx = await axios.post("/api/user/forgot", {
          email: state.email,
        });
        if (emailTx) {
          setStatus(true);
        }
      }
    } catch (error) {
      setStatus(false);
      console.log(error);
    }
  };
  const handleChange = e => {
    const { name, value } = e.target;
    console.log(e);
    setState(prevState => ({ ...prevState, [name]: value }));
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="w-12 h-12" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Account Recovery</h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          <Link to="/auth/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
            Sign in to your account
          </Link>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {status ? (
            <div>
              <h4>Email was sent, please check your inbox.</h4>
            </div>
          ) : (
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    autoFocus
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={(state && state.email) || ""}
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
