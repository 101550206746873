import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { BehaviorSubject } from "rxjs";
import { tap, throttleTime } from "rxjs/operators";
import { ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@heroicons/react/solid";

export default function Table({ columns, items, pageSize = 25, className = "", onRowClick }) {
  const maxPage = Math.ceil(items.length / pageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(items || []);

  useEffect(() => {
    // when currentPage changes, change the subset of items we render
    setData(items.slice((currentPage - 1) * pageSize, currentPage * pageSize));

    // if items are changed, make sure we don't stay on a page that doesn't exist
    // e.g. start with 27 items and move to page 2, then re-render with 20 items
    const totalPages = Math.ceil(items.length / pageSize);
    if (currentPage > totalPages) setCurrentPage(1); // jump to first page is good enough for current use cases
  }, [pageSize, items, currentPage]);

  return (
    <div className={className + ``}>
      <div className={`flex flex-col overflow-auto h-full`}>
        {/* <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
        <div className="py-2 w-full align-middle overflow-y-auto sm:px-6 lg:px-8">
          <div className="shadow overflow-y-auto border-b border-gray-200 sm:rounded-lg">
            <table className="overflow-y-auto max-h-full w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th
                        key={(column.dataField ?? column["data-field"] ?? "column") + i}
                        scope="col"
                        className={
                          "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" +
                            column.class ?? ""
                        }
                      >
                        {/action/i.test(column.type)
                          ? ""
                          : /total/i.test(column.type)
                          ? "Total"
                          : column.caption ?? column.dataField ?? column["data-field"]}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Array.from(data).map((value, index) => {
                  return (
                    <tr
                      key={"rowIndex" + index}
                      className={`hover:bg-gray-100 ${onRowClick ? "cursor-pointer" : ""}`}
                      onClick={() => (onRowClick ? onRowClick(value) : null)}
                    >
                      {columns.map((column, i) => (
                        <td
                          key={(column.dataField ?? column["data-field"] ?? "row") + i}
                          className="px-6 py-2 truncate"
                        >
                          {column?.cellDisplay ? (
                            column.cellDisplay(value)
                          ) : (
                            <div className={"text-sm text-gray-900" + column.class ?? ""}>
                              {/date/i.test(column.dataType)
                                ? moment(value[column.dataField]).format("MM/DD/YYYY")
                                : /total/i.test(column.type) && column.calculateValue
                                ? column.calculateValue(value)
                                : value[column.dataField]}
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {/* More items... */}
              </tbody>
            </table>
          </div>
          <nav className="border-t border-gray-200 px-4 flex items-center mt-2 sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                href="#"
                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" />
                Previous
              </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {pagination(currentPage, maxPage).map(page => (
                <button
                  key={page}
                  onClick={() => {
                    if (!isNaN(parseInt(page))) {
                      setCurrentPage(page);
                    }
                  }}
                  className={`order-transparent ${
                    currentPage === page ? "border-t-2" : ""
                  } text-gray-500 hover:text-gray-700 outline-none focus:outline-none hover:border-gray-300  pt-4 px-4 inline-flex items-center text-sm font-medium`}
                >
                  {page}
                </button>
              ))}
              {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                href="#"
                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                Next
                <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" />
              </button>
            </div>
          </nav>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

// TODO: test SearchPage, then refactor this, then remove Magic from its name
// and call it TableThatMakesRequestsOnPageChange or something similar.
// Only SearchPage and Registration uses MagicTable right now
// The regular table won't be sufficient because it requires all the items upfront
// we'll need a table component that triggers fetch of the different pages, this component can do that
export function MagicTable({
  columns,
  items,
  remote,
  setPage,
  maxPage,
  currentPage,
  onRowClick,
  pageSize = 25,
  className = "",
}) {
  const [state, setState] = useState({
    currentPage: 1,
    maxPage: 1,
    pages: [],
  });
  const [data, setData] = useState(items || []);
  const paginator$ = useRef(null);
  const paginate = amount => {
    if (state.currentPage + amount < state.maxPage + 1 && state.currentPage + amount > 0) {
      const data = {
        ...state,
        currentPage: state.currentPage + amount,
      };
      if (!remote) {
        paginator$.current.next(data);
      } else {
        setPage && setPage(data.currentPage);
      }
    }
  };
  const goToPage = page => {
    if (!remote) {
      paginator$.current.next({
        ...state,
        currentPage: page,
      });
    } else {
      setPage && setPage(page);
    }
  };

  useEffect(() => {
    if (!items?.length) {
      setData([]);
      setState(prevState => ({
        ...prevState,
        currentPage,
        maxPage,
      }));
      return;
    }
    if (!remote) {
      const maxPage = Math.ceil(items.length / pageSize);
      if (/[1|5]/i.test(maxPage.length)) {
        return maxPage;
      }
      paginator$.current = new BehaviorSubject({
        currentPage: 1,
        maxPage,
        pages: pagination(1, maxPage),
      });
      const listener = paginator$.current.pipe(
        tap(value => {
          setState({
            ...value,
            pages: pagination(value.currentPage, maxPage),
          });
        }),
        throttleTime(800)
      );
      listener.subscribe(paginator => {
        setData(
          items.slice((paginator.currentPage - 1) * pageSize, paginator.currentPage * pageSize)
        );
      });
      return () => listener.unsubscribe();
    } else {
      setData(items);
      setState(prevState => ({
        ...prevState,
        currentPage,
        maxPage,
      }));
    }
  }, [pageSize, items, remote, maxPage, currentPage]);

  return (
    <div className={className + ``}>
      <div className={`flex flex-col overflow-auto h-full`}>
        {/* <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
        <div className="py-2 w-full align-middle overflow-y-auto sm:px-6 lg:px-8">
          <div className="shadow overflow-y-auto border-b border-gray-200 sm:rounded-lg">
            <table className="overflow-y-auto max-h-full w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((column, i) => {
                    return (
                      <th
                        key={(column.dataField ?? column["data-field"] ?? "column") + i}
                        scope="col"
                        className={
                          "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" +
                            column.class ?? ""
                        }
                      >
                        {/action/i.test(column.type)
                          ? ""
                          : /total/i.test(column.type)
                          ? "Total"
                          : column.caption ?? column.dataField ?? column["data-field"]}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Array.from(data || items).map((value, index) => {
                  return (
                    <tr
                      key={"rowIndex" + index}
                      className={`hover:bg-gray-100 ${onRowClick ? "cursor-pointer" : ""}`}
                      onClick={() => (onRowClick ? onRowClick(value) : null)}
                    >
                      {columns.map((column, i) => (
                        <td
                          key={(column.dataField ?? column["data-field"] ?? "row") + i}
                          className="px-6 py-2 truncate"
                        >
                          {column?.cellDisplay ? (
                            column.cellDisplay(value)
                          ) : (
                            <div className={"text-sm text-gray-900" + column.class ?? ""}>
                              {/date/i.test(column.dataType)
                                ? moment(value[column.dataField]).format("MM/DD/YYYY")
                                : /total/i.test(column.type) && column.calculateValue
                                ? column.calculateValue(value)
                                : value[column.dataField]}
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
                {/* More items... */}
              </tbody>
            </table>
          </div>
          <nav className="border-t border-gray-200 px-4 flex items-center mt-2 sm:px-0">
            <div className="-mt-px w-0 flex-1 flex">
              <button
                onClick={() => paginate(-1)}
                href="#"
                className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                <ArrowNarrowLeftIcon className="mr-3 h-5 w-5 text-gray-400" />
                Previous
              </button>
            </div>
            <div className="hidden md:-mt-px md:flex">
              {pagination(state.currentPage, state.maxPage).map(page => (
                <button
                  key={page}
                  onClick={() => {
                    if (!isNaN(parseInt(page))) {
                      goToPage(page);
                    }
                  }}
                  className={`order-transparent ${
                    state.currentPage === page ? "border-t-2" : ""
                  } text-gray-500 hover:text-gray-700 outline-none focus:outline-none hover:border-gray-300  pt-4 px-4 inline-flex items-center text-sm font-medium`}
                >
                  {page}
                </button>
              ))}
              {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
            </div>
            <div className="-mt-px w-0 flex-1 flex justify-end">
              <button
                onClick={() => paginate(1)}
                href="#"
                className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
              >
                Next
                <ArrowNarrowRightIcon className="ml-3 h-5 w-5 text-gray-400" />
              </button>
            </div>
          </nav>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

function pagination(currentPage, pageCount) {
  let delta = 2,
    left = currentPage - delta,
    right = currentPage + delta + 1,
    result = [];

  result = Array.from({ length: pageCount }, (v, k) => k + 1).filter(
    i => i && i >= left && i < right
  );

  if (result.length > 1) {
    // Add first page and dots

    if (result[result.length - 1] < pageCount) {
      if (result[result.length - 1] !== pageCount - 1) {
        result.push("...");
      }
      result.push(pageCount);
    }
  }

  return result;
}

// see more: https://gridjs.io/docs/config/className
export const gridjsClasses = {
  table:
    "text-left w-full divide-y divide-gray-200 overflow-hidden max-h-full shadow border-b border-gray-200 sm:rounded-lg",
  tbody: "bg-white divide-y divide-gray-200 cursor-pointer",
  thead: "bg-gray-50",
  tr: "hover:bg-gray-100",
  th: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider",
  td: "px-6 py-3 truncate text-sm text-gray-900",
  pagination: "mt-2",
  paginationButton:
    "border-transparent border-t-2 text-gray-500 hover:text-gray-700 outline-none focus:outline-none hover:border-gray-300 py-4 px-4 text-sm font-medium",
  paginationButtonPrev: "mr-auto hover:text-gray-700",
  paginationButtonNext: "ml-auto hover:text-gray-700",
  paginationButtonCurrent: "hover:text-gray-700 outline-none focus:outline-none",
};
