import React, { forwardRef, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Header from "../common/Header";
import Button, { GrayButton, GreenButton } from "../common/Buttons";
import {
  flashErrorNotification,
  flashSuccessNotification,
} from "../../state/slices/notificationSlice";
import { selectRetailerById } from "../../state/slices/retailerSlice";
import { selectUserById } from "../../state/slices/userSlice";
import { formatInputDate } from "../../utils/formatDate";
import { selectPlantOptions } from "../../state/slices/plantSlice";
import { DateFieldWithError, FieldWithError, SelectFieldWithError } from "../common/Field";

export default function RegistrationCompareForm({
  registration,
  refreshRegistrations,
  closeModal,
}) {
  const dispatch = useDispatch();
  const registrationFormRef = createRef();
  const [registrationChangedValues, setRegistrationChangedValues] = useState(null);
  const submittalFormRef = createRef();
  const [submittalChangedValues, setSubmittalChangedValues] = useState(null);
  const areValuesChanged = registrationChangedValues || submittalChangedValues;
  const isReviewDisabled =
    !registration.Submittal || areValuesChanged || registration.noteForReviewer;

  const handleRegistrationChange = () => {
    setRegistrationChangedValues(true);
  };

  const handleSubmittalChange = () => {
    setSubmittalChangedValues(true);
  };

  const handleSubmitRegistration = async () => {
    const payload = registrationFormRef.current.values;
    try {
      await axios.put("/api/registration", { ...payload, id: registration.id });
      dispatch(flashSuccessNotification("Registration updated."));
      refreshRegistrations();
      // closeModal();
    } catch (err) {
      console.error("Failed to update registration", err);
      dispatch(flashErrorNotification("Something went wrong updating registration."));
    }
  };

  const handleSubmitSubmittal = async () => {
    try {
      const payload = submittalFormRef.current.values;
      await axios.post("/api/heug/home", { ...payload, id: registration.Submittal.id });
      dispatch(flashSuccessNotification("Submittal updated."));
      refreshRegistrations();
      // closeModal();
    } catch (err) {
      console.error("Failed to update submittal", err);
      dispatch(flashErrorNotification("Something went wrong updating submittal."));
    }
  };

  const handleResolveReview = async () => {
    try {
      await axios.put("/api/registration", {
        id: registration.id,
        // if there were notes for reviewer and they addressed them, mark as resolved by clearing them
        noteForReviewer: "",
      });
      registration.noteForReviewer = "";
      dispatch(flashSuccessNotification("Registration reviewed."));
      refreshRegistrations();
    } catch (err) {
      console.error("Failed to resolve review", err);
      dispatch(flashErrorNotification("Something went wrong."));
    }
  };

  const handleMarkReviewed = async payload => {
    try {
      if (registration.noteForReviewer) {
        const confirmed = window.confirm(
          `Did you address reviewer notes "${registration.noteForReviewer}"? Once you click OK the comments will be marked resolved and cleared. MAKE SURE YOU SAVE YOUR VALUES BEFORE MARKING REVIEWED.`
        );
        if (!confirmed) return;
      }
      await axios.put("/api/registration", {
        id: registration.id,
        isReviewed: true,
      });
      dispatch(flashSuccessNotification("Registration reviewed."));
      refreshRegistrations();
      closeModal();
    } catch (err) {
      console.error("Failed to mark reviewed", err);
      dispatch(flashErrorNotification("Something went wrong reviewing registration."));
    }
  };

  const handleSave = async () => {
    if (registrationChangedValues) handleSubmitRegistration();
    if (submittalChangedValues) handleSubmitSubmittal();
    // reset change state
    setRegistrationChangedValues(false);
    setSubmittalChangedValues(false);
  };

  const handleCancel = () => {
    // TODO: warn if unsaved values
    closeModal();
  };

  return (
    <>
      <div id="forms-split" className="flex flex-row gap-4">
        <div className="p-4 w-full rounded-md border border-gray-300">
          <RegistrationEditForm
            registration={registration}
            handleSubmitRegistration={handleSubmitRegistration}
            changeCallback={handleRegistrationChange}
            ref={registrationFormRef}
          />
        </div>
        <div className="p-4 w-full rounded-md border border-gray-300">
          {registration.Submittal ? (
            <SubmittalEditForm
              submittal={registration.Submittal}
              handleSubmitSubmittal={handleSubmitSubmittal}
              changeCallback={handleSubmittalChange}
              ref={submittalFormRef}
            />
          ) : (
            <p>No matching submittal</p>
          )}
        </div>
      </div>
      {registration.noteForReviewer && (
        <div className="flex items-center justify-between mt-4 p-4 w-full rounded-md border border-red-300">
          <p className="text-red-700">Note for reviewer: {registration.noteForReviewer}</p>
          <GreenButton onClick={handleResolveReview} disabled={areValuesChanged}>
            Resolve
          </GreenButton>
        </div>
      )}
      <div className="flex gap-2 justify-end pt-4">
        <GrayButton onClick={handleCancel}>Cancel</GrayButton>
        <Button onClick={handleSave} disabled={!areValuesChanged}>
          Save
        </Button>
        <GreenButton onClick={handleMarkReviewed} disabled={isReviewDisabled}>
          Mark Reviewed
        </GreenButton>
      </div>
    </>
  );
}

const RegistrationEditForm = forwardRef(
  ({ registration, handleSubmitRegistration, changeCallback }, ref) => {
    const retailer = useSelector(selectRetailerById(registration.RetailerId));
    // the retailer user who created this registration
    const creator = useSelector(selectUserById(registration.UserId));
    const plantOptions = useSelector(selectPlantOptions);

    const RegistrationSchema = Yup.object().shape({
      PlantId: Yup.string().required("Required"),
      serialNumber: Yup.string().required("Required"),
    });

    const initialValues = {
      PlantId: registration.PlantId || "",
      serialNumber: registration.serialNumber || "",
      homeownerName: registration.homeownerName || "",
      homeownerPhone: registration.homeownerPhone || "",
      homeownerEmail: registration.homeownerEmail || "",
      closingDate: formatInputDate(registration.closingDate) || "",
      // HVACInside: registration.HVACInside || "",
      // HVACOutside: registration.HVACOutside || "",
      streetAddress: registration.streetAddress || "",
      state: registration.state || "",
      city: registration.city || "",
      zipCode: registration.zipCode || "",
      lotNumber: registration.lotNumber || "",
    };

    const formik = useFormik({
      initialValues,
      validationSchema: RegistrationSchema,
      onSubmit: handleSubmitRegistration,
    });
    ref.current = formik;

    const wrapChangeHandler = handleChange => e => {
      // tell parent component that a value was changed
      changeCallback();
      // default form handler
      handleChange(e);
    };

    return (
      <form onSubmit={formik.handleSubmit}>
        <Header>Edit Registration</Header>
        <div>
          <p>{retailer.name}</p>
          <p>
            {creator.name} - {creator.email} - {creator.phoneNumber}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <SelectFieldWithError
            as="select"
            name="PlantId"
            label="Plant"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.PlantId}
            error={formik.errors.PlantId}
            className="col-span-2"
          >
            <option value="">---</option>
            {plantOptions.map(plant => (
              <option key={plant.value} value={plant.value}>
                {plant.label}
              </option>
            ))}
          </SelectFieldWithError>
          <FieldWithError
            name="serialNumber"
            label="Serial Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.serialNumber}
            error={formik.errors.serialNumber}
            className="col-span-2"
          />
          <FieldWithError
            name="homeownerName"
            label="Homeowner Name"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.homeownerName}
            error={formik.errors.homeownerName}
            className="col-span-2"
          />
          <FieldWithError
            name="homeownerPhone"
            label="Homeowner Phone"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.homeownerPhone}
            error={formik.errors.homeownerPhone}
            className="col-span-2"
          />
          <FieldWithError
            name="homeownerEmail"
            label="Homeowner Email"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.homeownerEmail}
            error={formik.errors.homeownerEmail}
            className="col-span-2"
          />
          <FieldWithError
            name="streetAddress"
            label="Homeowner Street Address"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.streetAddress}
            error={formik.errors.streetAddress}
            className="col-span-2"
          />
          <FieldWithError
            name="city"
            label="Homeowner City"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.city}
            error={formik.errors.city}
            className="col-span-1"
          />
          <FieldWithError
            name="state"
            label="Homeowner State"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.state}
            error={formik.errors.state}
            className="col-span-1"
          />
          <FieldWithError
            name="zipCode"
            label="Homeowner Zip Code"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
            className="col-span-1"
          />
          <FieldWithError
            name="lotNumber"
            label="Homeowner Lot Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.lotNumber}
            error={formik.errors.lotNumber}
            className="col-span-1"
          />
          <DateFieldWithError
            name="closingDate"
            label="Closing Date"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.closingDate}
            error={formik.errors.closingDate}
            className="col-span-2"
          />
          {/* DEPRECATED: this is equivalent to createdOn, so we can leave it as the db's timestamp */}
          {/* <FormikFieldWithLabelAndError
            name="completedDate"
            label="Completed Date"
            className="col-span-1"
            type="date"
            error={touched.endDate && errors.endDate}
          /> */}
          {/* Not sure yet if HVAC fields are needed in the compare form */}
          {/* <FieldWithError
            name="HVACInside"
            label="Verified HVAC Inside Unit - Coil Model Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.HVACInside}
            error={formik.errors.HVACInside}
            className="col-span-2"
          />
          <FieldWithError
            name="HVACOutside"
            label="Verified HVAC Outside Unit - Condenser Model Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.HVACOutside}
            error={formik.errors.HVACOutside}
            className="col-span-2"
          /> */}
        </div>
      </form>
    );
  }
);

const SubmittalEditForm = forwardRef(
  ({ submittal, handleSubmitSubmittal, changeCallback }, ref) => {
    // const retailer = useSelector(selectRetailerById(submittal.RetailerId));
    // const creator = useSelector(selectUserById(submittal.UserId));
    const plantOptions = useSelector(selectPlantOptions);

    const SubmittalSchema = Yup.object().shape({
      serialNumber: Yup.string().required("Required"),
    });

    const initialValues = {
      PlantId: submittal.PlantId || "",
      serialNumber: submittal.serialNumber || "",
      homeOwnerName: submittal.homeOwnerName || "",
      homeownerPhone: submittal.homeownerPhone || "",
      homeownerEmail: submittal.homeownerEmail || "",
      soldDate: formatInputDate(submittal.soldDate) || "",
      // hpCoilNumber: submittal.hpCoilNumber || "",
      // hpModelNumber: submittal.hpModelNumber || "",
      streetAddress: submittal.streetAddress || "",
      state: submittal.state || "",
      city: submittal.city || "",
      zipCode: submittal.zipCode || "",
      lotNumber: submittal.lotNumber || "",
    };

    const wrapChangeHandler = handleChange => e => {
      // tell parent component that a value was changed
      changeCallback();
      // default form handler
      handleChange(e);
    };

    const formik = useFormik({
      initialValues,
      validationSchema: SubmittalSchema,
      onSubmit: handleSubmitSubmittal,
    });
    ref.current = formik;

    return (
      <form onSubmit={formik.handleSubmit}>
        <Header>Edit Submittal</Header>
        <div>
          <p>{submittal.retailer}</p>
          <p>
            Retailer contact: {submittal.retailerContactName} - {submittal.retailerEmail} -{" "}
            {submittal.retailerPhone} - {submittal.retailerAddress}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <SelectFieldWithError
            as="select"
            name="PlantId"
            label="Plant"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.PlantId}
            error={formik.errors.PlantId}
            className="col-span-2"
          >
            <option value="">---</option>
            {plantOptions.map(plant => (
              <option key={plant.value} value={plant.value}>
                {plant.label}
              </option>
            ))}
          </SelectFieldWithError>
          <FieldWithError
            name="serialNumber"
            label="Serial Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.serialNumber}
            error={formik.errors.serialNumber}
            className="col-span-2"
          />
          <FieldWithError
            name="homeOwnerName"
            label="Homeowner Name"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.homeOwnerName}
            error={formik.errors.homeOwnerName}
            className="col-span-2"
          />
          <FieldWithError
            name="homeownerPhone"
            className="col-span-2"
            label="Homeowner Phone"
            disabled
            placeholder="Not provided by importer"
          />
          <FieldWithError
            name="homeownerEmail"
            className="col-span-2"
            label="Homeowner Email"
            disabled
            placeholder="Not provided by importer"
          />
          <FieldWithError
            name="streetAddress"
            label="Homeowner Street Address"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.streetAddress}
            error={formik.errors.streetAddress}
            className="col-span-2"
          />
          <FieldWithError
            name="city"
            label="Homeowner City"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.city}
            error={formik.errors.city}
            className="col-span-1"
          />
          <FieldWithError
            name="state"
            label="Homeowner State"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.state}
            error={formik.errors.state}
            className="col-span-1"
          />
          <FieldWithError
            name="zipCode"
            label="Homeowner Zip Code"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.zipCode}
            error={formik.errors.zipCode}
            className="col-span-1"
          />
          <FieldWithError
            name="lotNumber"
            label="Homeowner Lot Number"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.lotNumber}
            error={formik.errors.lotNumber}
            className="col-span-1"
          />
          <DateFieldWithError
            name="soldDate"
            label="Sold Date"
            handleChange={wrapChangeHandler(formik.handleChange)}
            value={formik.values.soldDate}
            error={formik.errors.soldDate}
            className="col-span-2"
          />
          {/* DEPRECATED: this is equivalent to createdOn, so we can leave it as the db's timestamp */}
          {/* <FormikFieldWithLabelAndError
        name="completedDate"
        label="Completed Date"
        className="col-span-1"
        type="date"
        error={touched.endDate && errors.endDate}
      /> */}
          {/* <FormikFieldWithLabelAndError
              name="hpCoilNumber"
              className="col-span-2"
              label="Verified HVAC Inside Unit - Coil Model Number"
              error={touched.hpCoilNumber && errors.hpCoilNumber}
            />
            <FormikFieldWithLabelAndError
              name="hpModelNumber"
              className="col-span-2"
              label="Verified HVAC Outside Unit - Condenser Model Number"
              error={touched.hpModelNumber && errors.hpModelNumber}
            /> */}
        </div>
        {/* <div className="flex justify-end m-4 mt-6">
            <SubmitButton>Edit Submittal</SubmitButton>
          </div> */}
      </form>
    );
  }
);
