import React, { useState } from "react";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAsync } from "../../state/slices/authSlice";
import useQuery from "../../utils/hooks/useQuery";

export default function SignIn() {
  const [state, setState] = useState({}); //form
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (state.password !== state.confirm) {
        setError("Pasword confirmation does not match.");
      }
      const email = query.get("email");
      const token = query.get("token");
      const resetResponse = await axios.post("/api/user/reset", {
        email,
        token,
        ...state,
      });
      if (resetResponse) {
        const loginAction = await dispatch(loginAsync({ email, password: state.password }));
        if (loginAction.type === "auth/signin/fulfilled") {
          return history.push("/");
        }
      }
    } catch (error) {
      if (error?.response?.data) {
        if (error.response.data.name === "JsonWebTokenError") {
          setError("Invalid token, please try with a new link.");
        }
      }
    }
    setIsLoading(false);
  };
  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src="/assets/energy.png" alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset your password
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          <Link to="/auth/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
            &larr; Sign in
          </Link>
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  onFocus={() => setError("")}
                  onChange={handleChange}
                  value={(state && state.password) || ""}
                  autoComplete="new-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Confirm New Password
              </label>
              <div className="mt-1">
                <input
                  id="confirm"
                  autoFocus
                  name="confirm"
                  onFocus={() => setError("")}
                  type="password"
                  onChange={handleChange}
                  value={(state && state.confirm) || ""}
                  autoComplete="new-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            {error && (
              <div>
                <div className="text-red-700">{error}</div>
              </div>
            )}
            <div>
              <button
                type="submit"
                style={{ opacity: isLoading || error ? "50%" : "initial" }}
                disabled={isLoading || error}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
