import React from "react";
import { Field } from "formik";
import Tooltip from "@mui/material/Tooltip";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { ErrorLabel } from "./Field";

const MyTooltip = ({ text }) => (
  // TODO: make text a bit bigger https://mui.com/material-ui/react-tooltip/#customization
  <Tooltip title={text} enterTouchDelay={0}>
    {/* The icon must be wrapped in a button otherwise the tooltip won't show up */}
    <button type="button" className="cursor-default">
      <QuestionMarkCircleIcon className="h-5 w-5" />
    </button>
  </Tooltip>
);

// Source:https://formik.org/docs/api/field

export default function FormikFieldWithLabelAndError({
  className,
  label,
  children,
  error,
  name,
  as,
  type,
  autoFocus,
  disabled,
  placeholder,
  tooltip,
  fieldClass = "",
}) {
  return (
    <div className={`inline-block my-3 pr-4 ${className ?? ""}`}>
      <label htmlFor={name} className="block text-sm text-gray-900 mb-2">
        <span className="flex gap-2">
          {label}
          {tooltip && <MyTooltip text={tooltip} />}
        </span>
      </label>
      <Field
        id={name}
        as={as}
        name={name}
        type={type}
        autoFocus={autoFocus}
        className={`resize-none bg-white block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
          error ? "border-red-500" : "border-gray-300"
        } ${fieldClass}`}
        disabled={disabled}
        data-testid={"input-field-" + name}
        placeholder={placeholder}
      >
        {children}
      </Field>
      {error ? <ErrorLabel error={error} name={name} /> : null}
    </div>
  );
}
