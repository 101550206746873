import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormikFieldWithLabelAndError from "../common/FormikFieldWithLabel";
import Header from "../common/Header";
import { GreenButton, SubmitButton } from "../common/Buttons";
import {
  flashErrorNotification,
  flashSuccessNotification,
} from "../../state/slices/notificationSlice";
import { selectRetailerById } from "../../state/slices/retailerSlice";
import { selectUserById } from "../../state/slices/userSlice";
import { formatInputDate } from "../../utils/formatDate";
import { selectPlantOptions } from "../../state/slices/plantSlice";

export default function RegistrationPostModellingForm({
  registration,
  refreshRegistrations,
  closeModal,
}) {
  const dispatch = useDispatch();

  const handleSubmitRegistration = async payload => {
    try {
      await axios.put("/api/registration", { ...payload, id: registration.id });
      dispatch(flashSuccessNotification("Registration updated."));
      refreshRegistrations();
      // closeModal();
    } catch (err) {
      console.error("Failed to update registration", err);
      dispatch(flashErrorNotification("Something went wrong updating registration."));
    }
  };

  const handleSubmitSubmittal = async payload => {
    try {
      await axios.post("/api/heug/home", { ...payload, id: registration.Submittal.id });
      dispatch(flashSuccessNotification("Submittal updated."));
      refreshRegistrations();
      // closeModal();
    } catch (err) {
      console.error("Failed to update submittal", err);
      dispatch(flashErrorNotification("Something went wrong updating submittal."));
    }
  };

  const handleApprove = async () => {
    try {
      await axios.put("/api/registration", { id: registration.id, isApproved: true });
      dispatch(flashSuccessNotification("Registration approved."));
      refreshRegistrations();
      closeModal();
    } catch (err) {
      console.error("Failed to approve registration", err);
      dispatch(flashErrorNotification("Something went wrong approving registration."));
    }
  };

  const handleRequestReview = async payload => {
    try {
      await axios.put("/api/registration", {
        id: registration.id,
        isReviewed: false,
        noteForReviewer: payload.noteForReviewer,
      });
      dispatch(flashSuccessNotification("Review requested."));
      refreshRegistrations();
      closeModal();
    } catch (err) {
      console.error("Failed to request review", err);
      dispatch(flashErrorNotification("Something went wrong requesting review."));
    }
  };

  return (
    <>
      <div id="forms-split" className="flex flex-row gap-4">
        <div className="p-4 w-full rounded-md border border-gray-300">
          <RegistrationEditForm
            registration={registration}
            handleSubmitRegistration={handleSubmitRegistration}
          />
        </div>
        <div className="p-4 w-full rounded-md border border-gray-300">
          <SubmittalEditForm
            submittal={registration.Submittal}
            handleSubmitSubmittal={handleSubmitSubmittal}
          />
        </div>
      </div>
      <div className="mt-4 p-4 w-full rounded-md border border-gray-300">
        <p>Add post-modelling fields</p>
      </div>
      <div className="mt-4 p-4 w-full rounded-md border border-gray-300">
        <RequestReviewForm registration={registration} handleRequestReview={handleRequestReview} />
      </div>
      <div className="flex justify-end pt-4">
        <GreenButton onClick={handleApprove}>Approve & Send</GreenButton>
      </div>
    </>
  );
}

function RegistrationEditForm({ registration, handleSubmitRegistration }) {
  const retailer = useSelector(selectRetailerById(registration.RetailerId));
  // the retailer user who created this registration
  const creator = useSelector(selectUserById(registration.UserId));
  const plantOptions = useSelector(selectPlantOptions);

  const RegistrationSchema = Yup.object().shape({
    serialNumber: Yup.string().required("Required"),
  });

  const initialValues = {
    PlantId: registration.PlantId || "",
    serialNumber: registration.serialNumber || "",
    homeownerName: registration.homeownerName || "",
    homeownerPhone: registration.homeownerPhone || "",
    homeownerEmail: registration.homeownerEmail || "",
    closingDate: formatInputDate(registration.closingDate) || "",
    HVACInside: registration.HVACInside || "",
    HVACOutside: registration.HVACOutside || "",
    streetAddress: registration.streetAddress || "",
    state: registration.state || "",
    city: registration.city || "",
    zipCode: registration.zipCode || "",
    lotNumber: registration.lotNumber || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RegistrationSchema}
      onSubmit={handleSubmitRegistration}
    >
      {({ errors, touched }) => (
        <Form>
          <Header>Edit Registration</Header>
          <div>
            <p>{retailer.name}</p>
            <p>
              {creator.name} - {creator.email} - {creator.phoneNumber}
            </p>
          </div>
          <div className="grid grid-cols-2">
            <FormikFieldWithLabelAndError
              as="select"
              name="PlantId"
              label="Plant"
              className="col-span-2"
              error={touched.PlantId && errors.PlantId}
            >
              <option value="">---</option>
              {plantOptions.map(plant => (
                <option key={plant.value} value={plant.value}>
                  {plant.label}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
            <FormikFieldWithLabelAndError
              name="serialNumber"
              className="col-span-2"
              label="Serial Number"
              error={touched.serialNumber && errors.serialNumber}
            />
            <FormikFieldWithLabelAndError
              name="homeownerName"
              className="col-span-2"
              label="Homeowner Name"
              error={touched.homeownerName && errors.homeownerName}
            />
            <FormikFieldWithLabelAndError
              name="homeownerPhone"
              className="col-span-2"
              label="Homeowner Phone"
              error={touched.homeownerPhone && errors.homeownerPhone}
            />
            <FormikFieldWithLabelAndError
              name="homeownerEmail"
              className="col-span-2"
              label="Homeowner Email"
              error={touched.homeownerEmail && errors.homeownerEmail}
            />
            <FormikFieldWithLabelAndError
              name="streetAddress"
              className="col-span-2"
              label="Homeowner Street Address"
              error={touched.streetAddress && errors.streetAddress}
            />
            <FormikFieldWithLabelAndError
              name="city"
              className="col-span-1"
              label="Homeowner City"
              error={touched.city && errors.city}
            />
            <FormikFieldWithLabelAndError
              name="state"
              className="col-span-1"
              label="Homeowner State"
              error={touched.state && errors.state}
            />
            <FormikFieldWithLabelAndError
              name="zipCode"
              className="col-span-1"
              label="Homeowner Zip Code"
              error={touched.zipCode && errors.zipCode}
            />
            <FormikFieldWithLabelAndError
              name="lotNumber"
              className="col-span-1"
              label="Homeowner Lot Number"
              error={touched.lotNumber && errors.lotNumber}
            />
            <FormikFieldWithLabelAndError
              name="closingDate"
              label="Closing Date"
              className="col-span-2"
              type="date"
              error={touched.startDate && errors.startDate}
            />
            {/* DEPRECATED: this is equivalent to createdOn, so we can leave it as the db's timestamp */}
            {/* <FormikFieldWithLabelAndError
        name="completedDate"
        label="Completed Date"
        className="col-span-1"
        type="date"
        error={touched.endDate && errors.endDate}
      /> */}
            <FormikFieldWithLabelAndError
              name="HVACInside"
              className="col-span-2"
              label="Verified HVAC Inside Unit - Coil Model Number"
              error={touched.HVACInside && errors.HVACInside}
            />
            <FormikFieldWithLabelAndError
              name="HVACOutside"
              className="col-span-2"
              label="Verified HVAC Outside Unit - Condenser Model Number"
              error={touched.HVACOutside && errors.HVACOutside}
            />
          </div>
          <div className="flex justify-end m-4 mt-6">
            <SubmitButton>Edit Registration</SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function SubmittalEditForm({ submittal, handleSubmitSubmittal }) {
  const retailer = useSelector(selectRetailerById(submittal.RetailerId));
  // const creator = useSelector(selectUserById(submittal.UserId));
  const plantOptions = useSelector(selectPlantOptions);

  const SubmittalSchema = Yup.object().shape({
    serialNumber: Yup.string().required("Required"),
  });

  const initialValues = {
    PlantId: submittal.PlantId || "",
    serialNumber: submittal.serialNumber || "",
    homeOwnerName: submittal.homeOwnerName || "",
    homeownerPhone: submittal.homeownerPhone || "",
    homeownerEmail: submittal.homeownerEmail || "",
    closingDate: formatInputDate(submittal.closingDate) || "",
    hpCoilNumber: submittal.hpCoilNumber || "",
    hpModelNumber: submittal.hpModelNumber || "",
    streetAddress: submittal.streetAddress || "",
    state: submittal.state || "",
    city: submittal.city || "",
    zipCode: submittal.zipCode || "",
    lotNumber: submittal.lotNumber || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SubmittalSchema}
      onSubmit={handleSubmitSubmittal}
    >
      {({ errors, touched }) => (
        <Form>
          <Header>Edit Submittal</Header>
          <div>
            <p>{retailer.name}</p>
            <p>
              Created by someone (?)
              {/* {creator.name} - {creator.email} - {creator.phoneNumber} */}
            </p>
          </div>
          <div className="grid grid-cols-2">
            <FormikFieldWithLabelAndError
              as="select"
              name="PlantId"
              label="Plant"
              className="col-span-2"
              error={touched.PlantId && errors.PlantId}
            >
              <option value="">---</option>
              {plantOptions.map(plant => (
                <option key={plant.value} value={plant.value}>
                  {plant.label}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
            <FormikFieldWithLabelAndError
              name="serialNumber"
              className="col-span-2"
              label="Serial Number"
              error={touched.serialNumber && errors.serialNumber}
            />
            <FormikFieldWithLabelAndError
              name="homeOwnerName"
              className="col-span-2"
              label="Homeowner Name"
              error={touched.homeownerName && errors.homeownerName}
            />
            <FormikFieldWithLabelAndError
              name="homeownerPhone"
              className="col-span-2"
              label="Homeowner Phone"
              error={touched.homeownerPhone && errors.homeownerPhone}
              disabled
              placeholder="Not provided by importer"
            />
            <FormikFieldWithLabelAndError
              name="homeownerEmail"
              className="col-span-2"
              label="Homeowner Email"
              error={touched.homeownerEmail && errors.homeownerEmail}
              disabled
              placeholder="Not provided by importer"
            />
            <FormikFieldWithLabelAndError
              name="streetAddress"
              className="col-span-2"
              label="Homeowner Street Address"
              error={touched.streetAddress && errors.streetAddress}
            />
            <FormikFieldWithLabelAndError
              name="city"
              className="col-span-1"
              label="Homeowner City"
              error={touched.city && errors.city}
            />
            <FormikFieldWithLabelAndError
              name="state"
              className="col-span-1"
              label="Homeowner State"
              error={touched.state && errors.state}
            />
            <FormikFieldWithLabelAndError
              name="zipCode"
              className="col-span-1"
              label="Homeowner Zip Code"
              error={touched.zipCode && errors.zipCode}
            />
            <FormikFieldWithLabelAndError
              name="lotNumber"
              className="col-span-1"
              label="Homeowner Lot Number"
              error={touched.lotNumber && errors.lotNumber}
            />
            <FormikFieldWithLabelAndError
              name="closingDate"
              label="Closing Date"
              className="col-span-2"
              type="date"
              error={touched.startDate && errors.startDate}
            />
            {/* DEPRECATED: this is equivalent to createdOn, so we can leave it as the db's timestamp */}
            {/* <FormikFieldWithLabelAndError
        name="completedDate"
        label="Completed Date"
        className="col-span-1"
        type="date"
        error={touched.endDate && errors.endDate}
      /> */}
            <FormikFieldWithLabelAndError
              name="hpCoilNumber"
              className="col-span-2"
              label="Verified HVAC Inside Unit - Coil Model Number"
              error={touched.hpCoilNumber && errors.hpCoilNumber}
            />
            <FormikFieldWithLabelAndError
              name="hpModelNumber"
              className="col-span-2"
              label="Verified HVAC Outside Unit - Condenser Model Number"
              error={touched.hpModelNumber && errors.hpModelNumber}
            />
          </div>
          <div className="flex justify-end m-4 mt-6">
            <SubmitButton>Edit Submittal</SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function RequestReviewForm({ registration, handleRequestReview }) {
  const ReviewRequestSchema = Yup.object().shape({
    noteForReviewer: Yup.string().required("Required"),
  });

  const initialValues = {
    noteForReviewer: registration.noteForReviewer || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ReviewRequestSchema}
      onSubmit={handleRequestReview}
    >
      {({ errors, touched }) => (
        <Form>
          <Header>Request Review</Header>
          <div className="grid grid-cols-2">
            <FormikFieldWithLabelAndError
              name="noteForReviewer"
              className="col-span-2"
              label="Note for reviewer (please put your name)"
              error={touched.noteForReviewer && errors.noteForReviewer}
            />
          </div>
          <div className="flex justify-end m-4 mt-6">
            <SubmitButton>Request Review</SubmitButton>
          </div>
        </Form>
      )}
    </Formik>
  );
}
