import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import { closeModal } from "../../state/slices/frontend";
import {
  insertPlantAsync,
  readAllPlantsAsync,
  updatePlantAsync,
} from "../../state/slices/plantSlice";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import Legend from "../common/Legend";
import Header from "../common/Header";
import { SubmitButton } from "../common/Buttons";

export default function PlantForm() {
  const { modals } = useSelector(state => state.frontend);
  const customerOptions = useSelector(state =>
    state.customer.customerOptions.map(c => ({ value: c.id, label: c.name }))
  );

  const dispatch = useDispatch();

  const handleSubmitPlant = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    if (payload.id) {
      await dispatch(updatePlantAsync(payload));
    } else {
      await dispatch(insertPlantAsync(payload));
    }
    dispatch(closeModal({ modal: "plant", target: null }));
    dispatch(readAllPlantsAsync());
  };
  const initialValues = {
    name: modals.plant.target?.name || "",
    certificateName: modals.plant.target?.certificateName || "",
    CustomerId: modals.plant.target?.CustomerId || "",
    address: modals.plant.target?.address || "",
    city: modals.plant.target?.city || "",
    county: modals.plant.target?.county || "",
    zipcode: modals.plant.target?.zipcode || "",
    state: modals.plant.target?.state || "",
    specialInstructions: modals.plant.target?.specialInstructions || "",
    contactName: modals.plant.target?.contactName || "",
    contactPhone: modals.plant.target?.contactPhone || "",
    contactEmail: modals.plant.target?.contactEmail || "",
    submittalContactName: modals.plant.target?.submittalContactName || "",
    submittalContactPhone: modals.plant.target?.submittalContactPhone || "",
    submittalContactEmail: modals.plant.target?.submittalContactEmail || "",
    fieldVerifierContactName: modals.plant.target?.fieldVerifierContactName || "",
    fieldVerifierContactPhone: modals.plant.target?.fieldVerifierContactPhone || "",
    fieldVerifierContactEmail: modals.plant.target?.fieldVerifierContactEmail || "",
    accountsReceivableContactName: modals.plant.target?.accountsReceivableContactName || "",
    accountsReceivableContactPhone: modals.plant.target?.accountsReceivableContactPhone || "",
    accountsReceivableContactEmail: modals.plant.target?.accountsReceivableContactEmail || "",
    generalManagerContactName: modals.plant.target?.generalManagerContactName || "",
    generalManagerContactPhone: modals.plant.target?.generalManagerContactPhone || "",
    generalManagerContactEmail: modals.plant.target?.generalManagerContactEmail || "",
    id: modals.plant.target?.id || null,
  };

  return (
    <Formik className="p-4" initialValues={initialValues} onSubmit={handleSubmitPlant}>
      <Form>
        <Header>{modals.plant.target?.id ? "Edit Plant" : "Add Plant"}</Header>
        <div className="grid grid-cols-6 gap-x-4 gap-y-2">
          <FormikFieldWithLabel name="name" className="col-span-3" label="Name" />
          <FormikFieldWithLabel
            autoFocus
            name="certificateName"
            label="Name on Certificates"
            className="col-span-3"
          />

          <FormikFieldWithLabel
            as="select"
            label="Customer"
            name="CustomerId"
            className="col-span-6"
          >
            <option value="">---</option>
            {customerOptions.map(customer => (
              <option value={customer.value} key={customer.value}>
                {customer.label}
              </option>
            ))}
          </FormikFieldWithLabel>
          <hr className="my-4 col-span-6" />
          <FormikFieldWithLabel
            name="address"
            label="Street Address"
            type="text"
            className="col-span-6"
          />
          <FormikFieldWithLabel name="city" label="City" type="text" className="col-span-3" />
          <FormikFieldWithLabel name="state" label="State" type="text" className="col-span-3" />
          <FormikFieldWithLabel
            name="zipcode"
            label="Zip Code"
            type="text"
            className="col-span-3"
          />
          <FormikFieldWithLabel name="county" label="County" type="text" className="col-span-3" />

          <FormikFieldWithLabel
            name="specialInstructions"
            label="Special Instructions for Invoicing"
            type="text"
            className="col-span-6"
          />

          <hr className="mt-4 mb-2 col-span-6" />

          <Legend className="col-span-6">Default Contact</Legend>
          <FormikFieldWithLabel name="contactName" label="Name" className="col-span-2" />
          <FormikFieldWithLabel name="contactEmail" label="Email" className="col-span-2" />
          <FormikFieldWithLabel name="contactPhone" label="Phone" className="col-span-2" />

          <Legend className="col-span-6">Submittal Contact</Legend>
          <FormikFieldWithLabel name="submittalContactName" label="Name" className="col-span-2" />
          <FormikFieldWithLabel name="submittalContactEmail" label="Email" className="col-span-2" />
          <FormikFieldWithLabel name="submittalContactPhone" label="Phone" className="col-span-2" />

          <Legend className="col-span-6">Field Verifier Contact</Legend>
          <FormikFieldWithLabel
            name="fieldVerifierContactName"
            label="Name"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="fieldVerifierContactEmail"
            label="Email"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="fieldVerifierContactPhone"
            label="Phone"
            className="col-span-2"
          />

          <Legend className="col-span-6">Accounts Receivable Contact</Legend>
          <FormikFieldWithLabel
            name="accountsReceivableContactName"
            label="Name"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="accountsReceivableContactEmail"
            label="Email"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="accountsReceivableContactPhone"
            label="Phone"
            className="col-span-2"
          />

          <Legend className="col-span-6">General Manager Contact</Legend>
          <FormikFieldWithLabel
            name="generalManagerContactName"
            label="Name"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="generalManagerContactEmail"
            label="Email"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="generalManagerContactPhone"
            label="Phone"
            className="col-span-2"
          />
        </div>
        <div className="flex justify-end m-4 mt-6">
          <SubmitButton />
        </div>
      </Form>
    </Formik>
  );
}
