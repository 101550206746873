import * as Yup from "yup";
import { validateSerialNumber } from "../../services/home";

export const HomeValidator = serialNumber =>
  Yup.object().shape({
    CustomerId: Yup.string().required("Required"),
    PlantId: Yup.string().required("Required"),
    serialNumber: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .test("checkDuplicateSerialNumber", "This serial number already exists", async value => {
        // don't perform check if it's own serialNumber, or you'll get a false negative
        if (serialNumber === value) return true;
        else {
          return validateSerialNumber(value);
        }
      }),
    modelNumber: Yup.string().required("Required"),
    energyStar: Yup.string().required("Required").oneOf(["es", "nes"]),
  });
