import React from "react";
import FormHeader from "../../../common/forms/FormHeader";
import ImageListWithDetails from "../../../common/ImageListWithDetails";
import { formatDisplayDate } from "../../../../utils/formatDate";
import { booleanToYesOrNA } from "../../../../utils/formatBooleanToString";
import { isNullOrUndefined } from "../../../../utils/isNullOrUndefined";
import { DataField } from "../../../common/DataField";

export default function CompletedPlantInspectionView({ pi }) {
  // photos in list
  // does not include cancellation photo because it's in a separate section
  const piPhotoFields = [
    { title: "Plant", source: pi.plantPhoto, name: "plantPhoto" },
    { title: "Insulation #1", source: pi.insulationPhoto1, name: "insulationPhoto1" },
    { title: "Insulation #2", source: pi.insulationPhoto2, name: "insulationPhoto2" },
    { title: "Insulation #3", source: pi.insulationPhoto3, name: "insulationPhoto3" },
    { title: "Air Sealing #1", source: pi.airSealingPhoto1, name: "airSealingPhoto1" },
    { title: "Air Sealing #2", source: pi.airSealingPhoto2, name: "airSealingPhoto2" },
    { title: "Air Sealing #3", source: pi.airSealingPhoto3, name: "airSealingPhoto3" },
    { title: "Window Label", source: pi.windowLabelPhoto, name: "windowLabelPhoto" },
    { title: "Duct Work", source: pi.ductWorkPhoto, name: "ductWorkPhoto" },
    { title: "Misc.", source: pi.miscPhoto1, name: "miscPhoto1" },
    { title: "Misc.", source: pi.miscPhoto2, name: "miscPhoto2" },
    { title: "Misc.", source: pi.miscPhoto3, name: "miscPhoto3" },
    { title: "Misc.", source: pi.miscPhoto4, name: "miscPhoto4" },
  ];

  return (
    <div>
      <FormHeader className="mb-6">Summary</FormHeader>
      <div className="grid grid-cols-2 gap-2 mb-6">
        {pi.User?.name && (
          <DataField
            label="Verified By"
            data={`${pi.User?.name} (${pi.User?.RTIN || "RTIN missing"})`}
          />
        )}
        {pi.completedDate && (
          <DataField label="Verification Date" data={formatDisplayDate(pi.completedDate)} />
        )}
        {pi.inspectionNotes && (
          <DataField label="Plant inpection notes" data={pi.inspectionNotes} />
        )}
        {!isNullOrUndefined(pi.isAirPermeableInsulation) && (
          <DataField
            label="Air Permeable Insulation"
            data={booleanToYesOrNA(pi.isAirPermeableInsulation)}
          />
        )}
        {!isNullOrUndefined(pi.isInsulationAlignedWithAirBarrier) && (
          <DataField
            label="Insulation Aligned With Air Barrier"
            data={booleanToYesOrNA(pi.isInsulationAlignedWithAirBarrier)}
          />
        )}
        {!isNullOrUndefined(pi.isExteriorThermalEnveloppeInsulationConform) && (
          <DataField
            label="Exterior Thermal Envelope Insulation"
            data={booleanToYesOrNA(pi.isExteriorThermalEnveloppeInsulationConform)}
          />
        )}
        {!isNullOrUndefined(pi.isFloorFramingCavityInsulationConform) && (
          <DataField
            label="Floor Framing Cavity Insulation"
            data={booleanToYesOrNA(pi.isFloorFramingCavityInsulationConform)}
          />
        )}
        {!isNullOrUndefined(pi.isBattsInNarrowCavitiesConform) && (
          <DataField
            label="Batts In Narrow Cavities"
            data={booleanToYesOrNA(pi.isBattsInNarrowCavitiesConform)}
          />
        )}
        {!isNullOrUndefined(pi.isRecessedLightFixturesConform) && (
          <DataField
            label="Recessed Light Fixtures"
            data={booleanToYesOrNA(pi.isRecessedLightFixturesConform)}
          />
        )}
        {!isNullOrUndefined(pi.isBattInsulationConform) && (
          <DataField label="Batt Insulation" data={booleanToYesOrNA(pi.isBattInsulationConform)} />
        )}
        {!isNullOrUndefined(pi.isExteriorWallsInsulated) && (
          <DataField
            label="Exterior Walls Insulated"
            data={booleanToYesOrNA(pi.isExteriorWallsInsulated)}
          />
        )}
        {!isNullOrUndefined(pi.isHVACRegisterBootsSealed) && (
          <DataField
            label="HVAC Register Boots Sealed"
            data={booleanToYesOrNA(pi.isHVACRegisterBootsSealed)}
          />
        )}
        {!isNullOrUndefined(pi.isContinuousAirBarrier) && (
          <DataField
            label="Continuous Air Barrier"
            data={booleanToYesOrNA(pi.isContinuousAirBarrier)}
          />
        )}
        {!isNullOrUndefined(pi.isAirBarrierAligned) && (
          <DataField label="Air Barrier Aligned" data={booleanToYesOrNA(pi.isAirBarrierAligned)} />
        )}
        {!isNullOrUndefined(pi.isSpaceWindowDoorSealed) && (
          <DataField
            label="Space Between Window/Door"
            data={booleanToYesOrNA(pi.isSpaceWindowDoorSealed)}
          />
        )}
        {!isNullOrUndefined(pi.isDuctSealed) && (
          <DataField label="Duct Shafts" data={booleanToYesOrNA(pi.isDuctSealed)} />
        )}
        {!isNullOrUndefined(pi.isRecessedLightFixturesCSealed) && (
          <DataField
            label="Recessed Light Fixtures"
            data={booleanToYesOrNA(pi.isRecessedLightFixturesCSealed)}
          />
        )}
        {!isNullOrUndefined(pi.isAirBarrierConform) && (
          <DataField label="Air Barrier" data={booleanToYesOrNA(pi.isAirBarrierConform)} />
        )}
      </div>
      <ImageListWithDetails images={piPhotoFields} />
    </div>
  );
}
