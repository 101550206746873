import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { MagicTable } from "../../common/Table";
import { formatDisplayDate } from "../../../utils/formatDate";
import { useDispatch, useSelector } from "react-redux";
import { readAllRetailersAsync, selectRetailerById } from "../../../state/slices/retailerSlice";
import formatAddressText from "../../../utils/formatAddress";
import { Link } from "react-router-dom";
import { GreenButton } from "../../common/Buttons";
import { readAllPlantsAsync, selectPlantOptions } from "../../../state/slices/plantSlice";
import { createQueryString } from "../../../utils/searchParams";
import { stripUnfilledValues_pureFunction } from "../../../utils/forms/stripUnfilledValues";
import { selectMyRetailerId } from "../../../state/slices/authSlice";

// page for retailer account users to see the registrations their retailer submitted
export default function RegistrationsByRetailer() {
  const [page, setPage] = useState();
  const dispatch = useDispatch();
  const [registrations, setRegistations] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const plantOptions = useSelector(selectPlantOptions);
  const RetailerId = useSelector(selectMyRetailerId);
  const retailerName = useSelector(selectRetailerById(RetailerId)).name;

  const refreshRegistrations = useCallback(() => {
    const filtersAndPage = stripUnfilledValues_pureFunction({ page, RetailerId });
    const queryString = createQueryString(filtersAndPage);
    axios
      .get("/api/registrations/list-by-retailer" + queryString)
      .then(({ data }) => {
        setRegistations(data.rows);
        setTotalPages(data.pages);
      })
      .catch(err => console.error("Failed to fetch registrations", err));
  }, [page, RetailerId]);

  useEffect(() => {
    refreshRegistrations();
    dispatch(readAllRetailersAsync());
    dispatch(readAllPlantsAsync());
  }, [refreshRegistrations, dispatch]);

  const userColumns = [
    {
      caption: "Plant",
      cellDisplay(value) {
        return plantOptions.find(p => p.value === value.PlantId)?.label || "";
      },
    },
    {
      caption: "Serial Number",
      dataField: "serialNumber",
    },
    {
      caption: "Homeowner Address",
      cellDisplay(value) {
        return formatAddressText(value);
      },
    },
    {
      caption: "Registration Created",
      cellDisplay(value) {
        return formatDisplayDate(value.createdAt);
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">Registrations for {retailerName}</h1>
          <Link to="/">
            <GreenButton>Add New</GreenButton>
          </Link>
        </div>
      </div>
      <main>
        <div className="max-w-7xl mx-auto py-6">
          <MagicTable
            columns={userColumns}
            className=""
            items={registrations}
            pageSize={25}
            maxPage={totalPages}
            // not sure what remote does, need to test and refactor
            remote={true}
            currentPage={page || 1}
            setPage={setPage}
          />
        </div>
      </main>
    </div>
  );
}
