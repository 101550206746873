import React from "react";

export default function Input({ className, label, children, ...props }) {
  return (
    <div className={className + ` inline-block`}>
      <label htmlFor={props.name} className="text-gray-900">
        {label}
      </label>
      <input
        {...props}
        id={props.name}
        className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        {children}
      </input>
    </div>
  );
}
