import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";
import { useCustomerIdFromPlantId } from "../../../../../state/slices/customerSlice";
import { selectPlantById } from "../../../../../state/slices/plantSlice";
import {
  deleteFieldVerificationAsync,
  reviewFieldVerificationAsync,
} from "../../../../../state/slices/tripSlice";
import { SmallGreenButton, SmallRedButton } from "../../../../common/Buttons";
import { selectUsers } from "../../../../../state/slices/userSlice";
import { DataField } from "../../../../common/DataField";
import formatAddressText from "../../../../../utils/formatAddress";
import HeadingWithTabsAndActions from "../../../../common/HeadingWithTabsAndActions";
import GeneralTab from "./GeneralTab";
import FieldVerificationTab from "./FieldVerificationTab";
import AuditLogTab from "./AuditLogTab";

export default function FieldVerificationMainSection({ fvId, trip, refreshTrip }) {
  const tabs = ["General", "Field Verification", "Audit Log"];
  const [tab, setTab] = useState(tabs[0]);
  const dispatch = useDispatch();
  // rename originalFV?
  const originalFV = trip?.items.find(fv => fv.id === fvId && fv.isFieldVerification);
  const fv = { ...originalFV };
  const users = useSelector(selectUsers);
  const getUserName = userId => users.find(u => u.id === userId)?.name;
  const plant = useSelector(selectPlantById(fv?.PlantId));
  const CustomerId = useCustomerIdFromPlantId(fv?.PlantId);
  if (fv) fv.CustomerId = CustomerId;
  const isFvCompleted = fv?.status === "completed";
  const shouldShowReviewButton = isFvCompleted && !fv?.isReviewed;

  function deleteFv() {
    const confirm = window.confirm("Deleting a Field Verification is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deleteFieldVerificationAsync(fv)).then(refreshTrip);
    }
  }

  function reviewFv() {
    dispatch(reviewFieldVerificationAsync(fv.id)).then(refreshTrip);
  }

  function renderActions() {
    return (
      <div className="space-x-2">
        {shouldShowReviewButton && (
          <SmallGreenButton onClick={reviewFv}>Mark Reviewed</SmallGreenButton>
        )}
        <SmallRedButton onClick={deleteFv}>Delete</SmallRedButton>
      </div>
    );
  }

  return (
    <>
      <DataField label="Plant" data={plant?.name} />
      <DataField label="Address" data={formatAddressText(fv)} />
      <DataField label="Status" data={capitalize(fv.status)} />
      {fv.status === "cancelled" && (
        <DataField label="Cancellation Notes" data={fv.cancellationNotes} />
      )}
      <DataField label="Scheduler" data={getUserName(fv.SchedulerId)} />
      <HeadingWithTabsAndActions
        tabs={tabs}
        currentTab={tab}
        onChange={setTab}
        renderActions={renderActions}
      />
      {tab === "General" && <GeneralTab fv={fv} refreshTrip={refreshTrip} />}
      {tab === "Field Verification" && <FieldVerificationTab fv={fv} refreshTrip={refreshTrip} />}
      {tab === "Audit Log" && <AuditLogTab fv={fv} />}
    </>
  );
}
