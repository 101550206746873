import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import FormHeader from "../common/forms/FormHeader";
import { useFieldVerifierOptions } from "../../state/slices/userSlice";
import Select from "../common/Select";
import { selectCustomerOptions } from "../../state/slices/customerSlice";
import { selectPlantOptions } from "../../state/slices/plantSlice";
import FilteredPlantOptions from "./FilteredPlantOptions";
import { SmallGreenButton, SmallRedButton } from "../common/Buttons";

export function SearchHomes45LForm({ searchHandler }) {
  const formRef = useRef();
  const userOptions = useFieldVerifierOptions();
  // used for filtering plants by chosen customer
  const [chosenCustomerId, setChosenCustomer] = useState(null);
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    searchHandler(data);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    searchHandler();
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={onSubmitHandler}
        className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg"
      >
        <FormHeader className="col-span-2">Search Homes</FormHeader>
        <div className="col-span-2">
          <label htmlFor="serialNumber" className="text-gray-900">
            Serial Number
          </label>
          <input
            autoFocus
            type="text"
            id="serialNumber"
            name="serialNumber"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="CustomerId" className="text-gray-900">
            Customer
          </label>
          <select
            name="CustomerId"
            id="CustomerId"
            onChange={e => setChosenCustomer(e.target.value)}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            {customerOptions.map(customer => (
              <option value={customer.value} key={customer.value}>
                {customer.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="PlantId" className="text-gray-900">
            Plant/HBF
          </label>
          <select
            name="PlantId"
            id="PlantId"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <FilteredPlantOptions plantOptions={plantOptions} customerId={chosenCustomerId} />
          </select>
        </div>
        <div>
          <label htmlFor="energyStar" className="text-gray-900">
            Energy Star
          </label>
          <select
            name="energyStar"
            id="energyStar"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option defaultValue value="">
              ---
            </option>
            <option value="es">ES</option>
            <option value="nes">NES</option>
          </select>
        </div>
        <div className="col-span-1">
          <label htmlFor="type" className="text-gray-900">
            Type
          </label>
          <select
            name="type"
            id="type"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <option defaultValue value="hud">
              HUD
            </option>
            <option value="mod">MOD</option>
          </select>
        </div>
        <div className="col-span-1">
          <label htmlFor="dateAdded" className="text-gray-900">
            Date Added
          </label>
          <input
            type="date"
            id="dateAdded"
            name="dateAdded"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-1">
          <label htmlFor="certType" className="text-gray-900">
            Cert Type
          </label>
          <select
            name="certType"
            // value={inputValObj.certType}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          >
            <option value="">---</option>
            <option defaultValue value="no">
              No
            </option>
            <option value="30">30%</option>
            <option value="50">50%</option>
          </select>
        </div>
        <div className="col-span-1">
          <label htmlFor="certDate" className="text-gray-900">
            Cert Date
          </label>
          <input
            type="date"
            name="certDate"
            // value={inputValObj.certDate}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-1">
          <label htmlFor="climateCode" className="text-gray-900">
            Climate Code
          </label>
          <input
            type="text"
            name="climateCode"
            // value={inputValObj.climateCode}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-2">
          <label htmlFor="furnace" className="text-gray-900">
            Furnace
          </label>
          <select
            name="furnace"
            // value={inputValObj.furnace}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          >
            <option defaultValue value="">
              ---
            </option>
            <option value="electric">Electric</option>
            <option value="gas">Gas</option>
            <option value="heatPump">Heat Pump</option>
            <option value="package">Package</option>
          </select>
        </div>
        <div className="col-span-2">
          <Select name="fieldVerifier" label="Field Verifier" options={userOptions} />
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}

export function SearchHomesHEUGForm({ searchHandler }) {
  const formRef = useRef();
  // used for filtering plants by chosen customer
  const [chosenCustomerId, setChosenCustomer] = useState(null);
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    searchHandler(data);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    searchHandler();
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={onSubmitHandler}
        className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg"
      >
        <FormHeader className="col-span-2">Search Homes</FormHeader>
        <div className="col-span-2">
          <label htmlFor="serialNumber" className="text-gray-900">
            Serial Number
          </label>
          <input
            autoFocus
            type="text"
            id="serialNumber"
            name="serialNumber"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="CustomerId" className="text-gray-900">
            Customer
          </label>
          <select
            name="CustomerId"
            id="CustomerId"
            onChange={e => setChosenCustomer(e.target.value)}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            {customerOptions.map(customer => (
              <option value={customer.value} key={customer.value}>
                {customer.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="PlantId" className="text-gray-900">
            Plant/HBF
          </label>
          <select
            name="PlantId"
            id="PlantId"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <FilteredPlantOptions plantOptions={plantOptions} customerId={chosenCustomerId} />
          </select>
        </div>
        <p className="col-span-2 mt-4">Date Added</p>
        <div>
          <label htmlFor="before" className="text-gray-900">
            Before
          </label>
          <input
            type="date"
            id="before"
            name="before"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div>
          <label htmlFor="after" className="text-gray-900">
            After
          </label>
          <input
            type="date"
            id="after"
            name="after"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}

export function SearchFieldVerificationsForm({ searchHandler }) {
  const formRef = useRef();

  // used for filtering plants by chosen customer
  const [chosenCustomerId, setChosenCustomer] = useState(null);
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const userOptions = useFieldVerifierOptions();

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    searchHandler(data);
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    searchHandler();
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={onSubmitHandler}
        className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg"
      >
        <FormHeader className="col-span-2">Search Field Verifications</FormHeader>
        <div className="col-span-2">
          <label htmlFor="serialNumber" className="text-gray-900">
            Serial Number
          </label>
          <input
            autoFocus
            type="text"
            id="serialNumber"
            name="serialNumber"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label htmlFor="CustomerId" className="text-gray-900">
            Customer
          </label>
          <select
            name="CustomerId"
            id="CustomerId"
            onChange={e => setChosenCustomer(e.target.value)}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            {customerOptions.map(customer => (
              <option value={customer.value} key={customer.value}>
                {customer.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="PlantId" className="text-gray-900">
            Plant/HBF
          </label>
          <select
            name="PlantId"
            id="PlantId"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <FilteredPlantOptions plantOptions={plantOptions} customerId={chosenCustomerId} />
          </select>
        </div>
        <div className="col-span-2">
          <label htmlFor="status" className="text-gray-900">
            Status
          </label>
          <select
            name="status"
            id="status"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <option value="scheduled">scheduled</option>
            <option value="cancelled">cancelled</option>
            <option value="completed">completed</option>
          </select>
        </div>
        <div className="col-span-2">
          <label htmlFor="UserId" className="text-gray-900">
            Field Verifier
          </label>
          <select
            name="UserId"
            id="UserId"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            {userOptions.map(user => (
              <option value={user.value} key={user.value}>
                {user.label}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}

export function SubmittalSearch({ searchHandler, modalToggleHandler }) {
  const formRef = useRef();
  const userOptions = useFieldVerifierOptions();

  // Cleanup search data and call parent search handler
  const onSubmitHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    searchHandler(data);
    modalToggleHandler();
  };

  const handleReset = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    searchHandler({});
    modalToggleHandler();
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={onSubmitHandler}
        className="grid grid-cols-2 gap-3 p-3 bg-white rounded-lg"
      >
        <FormHeader>Search Homes</FormHeader>
        <div className="col-span-2">
          <label htmlFor="serialNumber" className="text-gray-900">
            Serial Number
          </label>
          <input
            autoFocus
            type="text"
            name="serialNumber"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="col-span-2">
          <label htmlFor="type" className="text-gray-900">
            Type
          </label>
          <select
            name="type"
            id="type"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">---</option>
            <option defaultValue value="hud">
              HUD
            </option>
            <option value="mod">MOD</option>
          </select>
        </div>
        <div className="col-span-1">
          <label htmlFor="dateAdded" className="text-gray-900">
            Date Added
          </label>
          <input
            type="date"
            name="dateAdded"
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-1">
          <label htmlFor="certType" className="text-gray-900">
            Cert Type
          </label>
          <select
            name="certType"
            // value={inputValObj.certType}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          >
            <option value="">---</option>
            <option defaultValue value="no">
              No
            </option>
            <option value="30">30%</option>
            <option value="50">50%</option>
          </select>
        </div>
        <div className="col-span-1">
          <label htmlFor="certDate" className="text-gray-900">
            Cert Date
          </label>
          <input
            type="date"
            name="certDate"
            // value={inputValObj.certDate}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-1">
          <label htmlFor="climateCode" className="text-gray-900">
            Climate Code
          </label>
          <input
            type="text"
            name="climateCode"
            // value={inputValObj.climateCode}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          />
        </div>
        <div className="col-span-2">
          <label htmlFor="furnace" className="text-gray-900">
            Furnace
          </label>
          <select
            name="furnace"
            // value={inputValObj.furnace}
            className="resize-none bg-white block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            // onChange={inputHandler}
          >
            <option defaultValue value="">
              ---
            </option>
            <option value="electric">Electric</option>
            <option value="gas">Gas</option>
            <option value="heatPump">Heat Pump</option>
            <option value="package">Package</option>
          </select>
        </div>
        <div className="col-span-2">
          {/* This select currently has no effect */}
          <Select name="fieldVerifier" label="Field Verifier" options={userOptions} />
        </div>
        <div className="flex justify-end col-span-2">
          <SmallRedButton onClick={handleReset} className="mt-2 mr-2">
            Clear
          </SmallRedButton>

          <SmallGreenButton type="submit" className="mt-2">
            Search
          </SmallGreenButton>
        </div>
      </form>
    </>
  );
}
