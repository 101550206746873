import React from "react";
import CancelledFieldVerificationView, {
  ReAssignToTripForm,
} from "./CancelledFieldVerificationView";
import CompletedFieldVerificationView from "./CompletedFieldVerificationView";
// TODO: delete this component and its file if you get no feedback to bring it back */}
// It contains only the scheduler notes in a form, that's been moved to the home tab
// import ScheduledFieldVerificationView from "./ScheduledFieldVerificationForm";

export default function FieldVerificationView({ fv, refreshTrip }) {
  const isCompleted = fv.status === "completed";

  return (
    <>
      {/* <ScheduledFieldVerificationView fv={fv} /> */}
      <CompletedFieldVerificationView fv={fv} refreshTrip={refreshTrip} />
      <CancelledFieldVerificationView fv={fv} />
      {!isCompleted && (
        <ReAssignToTripForm fvId={fv.id} defaultTripId={fv.TripId} refreshTrip={refreshTrip} />
      )}
    </>
  );
}
