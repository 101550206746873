import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../slices/authSlice";
import UserSlice from "../slices/userSlice";
import notificationSlice from "../slices/notificationSlice";
import submittalSlice from "../slices/submittalSlice";
import CustomerSlice from "../slices/customerSlice";
import FrontEndSlice from "../slices/frontend";
import PlantSlice from "../slices/plantSlice";
import ParkSlice from "../slices/parkSlice";
import retailerSlice from "../slices/retailerSlice";
import homeSlice from "../slices/homeSlice";
import tripSlice from "../slices/tripSlice";

const reducer = {
  frontend: FrontEndSlice,
  auth: AuthSlice,
  user: UserSlice,
  submittal: submittalSlice,
  homes: homeSlice,
  notification: notificationSlice,
  customer: CustomerSlice,
  plant: PlantSlice,
  park: ParkSlice,
  retailer: retailerSlice,
  trip: tripSlice,
};

export default configureStore({
  reducer,
});

// This function is mostly intended for use in tests
export const createStoreWithPreloadedState = preloadedState =>
  configureStore({
    reducer,
    preloadedState,
  });
