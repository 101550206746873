import React, { useState } from "react";
import { ImportStepForm, SaveStepForm, ValidateStepForm } from "../forms/ImportFormHEUG";

export default function SubmittalImporterHEUG() {
  const [currentStep, setCurrentStep] = useState(0);
  const [submittalData, setSubmittalData] = useState({
    name: "",
    Homes: [],
    importData: [],
    importHeaders: {},
    validation: {},
  });
  const steps = ["import", "map", "validate"];
  const handleNextStep = () => setCurrentStep(s => s + 1);
  const handlePreviousStep = () => setCurrentStep(s => s - 1);

  const handleChange = e => {
    const { name, value } = e.target;
    setSubmittalData(s => ({
      ...s,
      [name]: value,
    }));
  };
  const handleChangeHeader = event => {
    const { name, value } = event.target;
    setSubmittalData(s => ({
      ...s,
      importHeaders: {
        ...s.importHeaders,
        [name]: value,
      },
    }));
  };

  const setHeaders = data => {
    setSubmittalData(s => ({ ...s, importHeaders: data }));
  };
  const setImportData = data => {
    setSubmittalData(s => ({ ...s, importData: data }));
  };
  const setValidation = data => {
    setSubmittalData(s => ({ ...s, validation: data }));
  };
  const setHomes = data => {
    setSubmittalData(s => ({ ...s, Homes: data }));
  };

  return (
    <div className="p-4 mx-auto rounded-md flex flex-col items-center">
      <h1 className="text-2xl font-bold my-4">Submittal Importer</h1>
      <nav aria-label="Progress" className="border-b-2 flex justify-evenly mb-4">
        <ol className="space-y-8 md:flex md:space-y-0 md:space-x-32 w">
          {steps.map((step, i) => (
            <li key={step + i} className="md:flex-1">
              {/* Completed Step */}
              <button
                // onClick={() => setImportForm(prevState => ({ ...prevState, step: i }))}
                type="button"
                className={`${
                  i === currentStep ? "md:border-t-4 " : ""
                } focus:outline-none appearance-none group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 `}
              >
                <span
                  className={`text-xs ${
                    i === currentStep ? "text-indigo-600" : ""
                  } font-semibold tracking-wide uppercase group-hover:text-indigo-800`}
                >
                  Step {i + 1}
                </span>
                <span className="text-sm font-medium capitalize">{step}</span>
              </button>
            </li>
          ))}
        </ol>
      </nav>
      <div className="sm:rounded-md transition-height flex flex-col max-w-full duration-500">
        {currentStep === 0 && (
          <ImportStepForm
            state={submittalData}
            handleNextStep={handleNextStep}
            setHeaders={setHeaders}
            setImportData={setImportData}
            handleChange={handleChange}
          />
        )}
        {currentStep === 1 && (
          <ValidateStepForm
            state={submittalData}
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            setHomes={setHomes}
            handleChangeHeader={handleChangeHeader}
            setValidation={setValidation}
          />
        )}
        {currentStep === 2 && (
          <SaveStepForm state={submittalData} handlePreviousStep={handlePreviousStep} />
        )}
      </div>
    </div>
  );
}
