// codebase is deployed to 3 domains:
// https://dashboard.energycheck.us/ (45L site)
// https://www.myenergyguarantee.us/ (HEUG site for EnergyCheck personnel)
// https://www.myenergyregistration.com/ (HEUG retailer site)

// some pages and components are conditionally rendered, depending on which site this is

// to switch between HEUG and 45L mode
// you can add or remove REACT_APP_IS_HEUG in client/.env and restart the front-end server
// or hard-code a boolean here to avoid having to refresh (make sure not to accidentally commit!)
export const isHEUGSite = !!process.env.REACT_APP_IS_HEUG;

// HEUG site is deployed to 2 domains
// this function detects if the user is on the retailer site (www.myenergyregistration.com)
// or on the regular site (www.myenergyguarantee.us)
// for local dev testing, you can switch from retailer to regular site by hard-coding a boolean return value
export const getIsOnRetailerSite = () => window.location.host.includes("myenergyregistration");
