import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { PhotographIcon } from "@heroicons/react/outline";
import {
  flashErrorNotification,
  flashSuccessNotification,
} from "../../../../state/slices/notificationSlice";
import {
  deleteFieldVerificationPhotoAsync,
  updateFieldVerificationAsync,
  deletePlantInspectionPhotoAsync,
  updatePlantInspectionAsync,
} from "../../../../state/slices/tripSlice";
import { SmallRedButton } from "../../../common/Buttons";
import { uploadImageToS3 } from "../../../../services/image-upload";

const ImageUploadForm = ({ title, field, value, fvId, piId, closeModal, refreshTrip }) => {
  const file = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteHandler = async () => {
    const confirmed = window.confirm("Deleting a photo is irreversible. Are you sure?");
    if (confirmed) {
      setLoading(true);
      if (fvId) {
        await dispatch(deleteFieldVerificationPhotoAsync({ id: fvId, fieldName: field }));
        dispatch(flashSuccessNotification("Field verification photo deleted."));
      }
      if (piId) {
        await dispatch(deletePlantInspectionPhotoAsync({ id: piId, fieldName: field }));
        dispatch(flashSuccessNotification("Plant inspection photo deleted."));
      }
      refreshTrip();
      closeModal();
    }
  };

  // upload file listener
  useEffect(() => {
    document.getElementById(field).onclick = () => {
      document.getElementById(field).value = null;
    };

    document.getElementById(field).onchange = async event => {
      if (event.target.files && event.target.files.length > 0) {
        let confirmed = true;
        if (value) confirmed = window.confirm("Replacing a photo is irreversible. Are you sure?");
        if (!confirmed) return;
        if (value && confirmed) {
          // replace; delete before uploading
          try {
            if (fvId)
              await dispatch(deleteFieldVerificationPhotoAsync({ id: fvId, fieldName: field }));
            if (piId)
              await dispatch(deletePlantInspectionPhotoAsync({ id: piId, fieldName: field }));
          } catch (err) {
            console.error("Failed to delete photo", err);
          }
        }

        setLoading(true);
        const file = event.target.files[0];
        try {
          const fileKey = await uploadImageToS3(file, fvId, piId, field);
          if (fvId) await dispatch(updateFieldVerificationAsync({ id: fvId, [field]: fileKey }));
          if (piId) await dispatch(updatePlantInspectionAsync({ id: piId, [field]: fileKey }));

          closeModal();
          refreshTrip();
        } catch (err) {
          console.error("Failed to upload photo", err);
          dispatch(flashErrorNotification("Failed to upload your photo."));
        }
      }
    };

    // TODO: return cleanup function that unbinds the event listeners
  }, [field, fvId, piId, dispatch, closeModal, value, refreshTrip]);

  return (
    <div className="p-4">
      <label htmlFor={field} className="block text-base font-bold leading-5 text-cool-gray-700">
        {"Image Upload: " + title}
      </label>
      <input
        id={field}
        type="file"
        name={field}
        ref={file}
        className="hidden"
        accept="image/*,application/pdf"
      />
      <div className="mt-2 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        {loading ? (
          <p>Saving...</p>
        ) : (
          <div className="text-center">
            <p className="mt-1 text-sm text-gray-600">
              <button
                type="button"
                className="font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
                onClick={() => {
                  if (file && file.current) {
                    file.current.click();
                  }
                }}
              >
                <PhotographIcon className="mx-auto h-10 w-10 text-gray-400" />
                {value ? "Replace photo" : "Upload photo"}
              </button>
              {value && (
                <SmallRedButton onClick={deleteHandler} className="block mt-4" type="button">
                  Delete photo
                </SmallRedButton>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageUploadForm;
