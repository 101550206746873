import { Formik, Form } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { GreenButton, SubmitButton } from "../common/Buttons";
import FormikFieldWithLabelAndError from "../common/FormikFieldWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { flashSuccessNotification } from "../../state/slices/notificationSlice";
import { validateSerialNumber } from "../../services/registration";
import { selectPlantOptions } from "../../state/slices/plantSlice";
import { Link } from "react-router-dom";

export default function RegistrationForm() {
  const currentUser = useSelector(state => state.auth.currentUser);
  const plantOptions = useSelector(selectPlantOptions);
  const dispatch = useDispatch();

  const initialValues = {
    PlantId: "",
    serialNumber: "",
    homeownerName: "",
    homeownerPhone: "",
    homeownerEmail: "",
    streetAddress: "",
    state: "",
    city: "",
    zipCode: "",
    lotNumber: "",
    closingDate: "",
    // completedDate: "",
    HVACInside: "",
    HVACOutside: "",
  };
  const RegistrationSchema = Yup.object().shape({
    PlantId: Yup.string().required("Required"),
    serialNumber: Yup.string()
      .required("Required")
      .test(
        "checkDuplicateSerialNumber",
        "This serial number already exists on another registration",
        async value => validateSerialNumber(value)
      ),
    homeownerName: Yup.string().required("Required"),
    homeownerPhone: Yup.string(),
    homeownerEmail: Yup.string().required("Required"),
    streetAddress: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    zipCode: Yup.string().required("Required"),
    lotNumber: Yup.string(),
    closingDate: Yup.string().required("Required"),
    HVACInside: Yup.string().required("Required"),
    HVACOutside: Yup.string().required("Required"),
  });
  const handleSubmit = async (values, { resetForm }) => {
    await axios.post("/api/registration", {
      ...values,
      UserId: currentUser.id,
      RetailerId: currentUser.RetailerId,
    });
    dispatch(flashSuccessNotification("Registration submitted successfully!"));
    resetForm();
  };

  return (
    <div className="p-8 max-w-2xl mx-auto overflow-scroll">
      <Formik
        initialValues={initialValues}
        validationSchema={RegistrationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="flex justify-between items-center">
              <h1 className="text-3xl font-bold">New Registration</h1>
              <Link to="/registrations">
                <GreenButton>View All</GreenButton>
              </Link>
            </div>
            <div className="grid grid-cols-2">
              <FormikFieldWithLabelAndError
                as="select"
                name="PlantId"
                label="Plant"
                className="col-span-2"
                error={touched.PlantId && errors.PlantId}
              >
                <option value="">---</option>
                {plantOptions.map(plant => (
                  <option key={plant.value} value={plant.value}>
                    {plant.label}
                  </option>
                ))}
              </FormikFieldWithLabelAndError>
              <FormikFieldWithLabelAndError
                name="serialNumber"
                className="col-span-2"
                label="Complete Serial Number"
                error={touched.serialNumber && errors.serialNumber}
              />
              <FormikFieldWithLabelAndError
                name="homeownerName"
                className="col-span-2"
                label="Homeowner Name"
                error={touched.homeownerName && errors.homeownerName}
              />
              <FormikFieldWithLabelAndError
                name="homeownerPhone"
                className="col-span-2"
                label="Homeowner Phone"
                error={touched.homeownerPhone && errors.homeownerPhone}
              />
              <FormikFieldWithLabelAndError
                name="homeownerEmail"
                className="col-span-2"
                label="Homeowner Email"
                error={touched.homeownerEmail && errors.homeownerEmail}
              />
              <FormikFieldWithLabelAndError
                name="streetAddress"
                className="col-span-2"
                label="Homeowner Street Address"
                error={touched.streetAddress && errors.streetAddress}
              />
              <FormikFieldWithLabelAndError
                name="lotNumber"
                className="col-span-1"
                label="Homeowner Lot Number"
                error={touched.lotNumber && errors.lotNumber}
              />
              <FormikFieldWithLabelAndError
                name="city"
                className="col-span-1"
                label="Homeowner City"
                error={touched.city && errors.city}
              />
              <FormikFieldWithLabelAndError
                name="state"
                className="col-span-1"
                label="Homeowner State"
                error={touched.state && errors.state}
              />
              <FormikFieldWithLabelAndError
                name="zipCode"
                className="col-span-1"
                label="Homeowner Zip Code"
                error={touched.zipCode && errors.zipCode}
              />
              <FormikFieldWithLabelAndError
                name="closingDate"
                label="Closing Date"
                className="col-span-2"
                type="date"
                error={touched.closingDate && errors.closingDate}
              />
              {/* DEPRECATED: this is equivalent to createdOn, so we can leave it as the db's timestamp */}
              {/* <FormikFieldWithLabelAndError
                name="completedDate"
                label="Completed Date"
                className="col-span-1"
                type="date"
                error={touched.endDate && errors.endDate}
              /> */}
              <FormikFieldWithLabelAndError
                name="HVACInside"
                className="col-span-2"
                label="Enter condenser model number for HVAC Inside Unit"
                tooltip="The number on the HVAC unit"
                error={touched.HVACInside && errors.HVACInside}
              />
              <FormikFieldWithLabelAndError
                name="HVACOutside"
                className="col-span-2"
                label="Enter condenser model number for HVAC Outside Unit"
                tooltip="The number on the HVAC unit"
                error={touched.HVACOutside && errors.HVACOutside}
              />
            </div>
            <div className="flex justify-end m-4 mt-6">
              <SubmitButton />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
