import React, { useState } from "react";
import Modal from "../Modal";
export const CDN_URL =
  process.env.REACT_APP_CDN_URL || "https://ik.imagekit.io/zonijteiooy/energycheck";

export default function ImagePreview({ caption, src }) {
  // src is like /photos/18/redTagPhoto2_67PACOW-Cyp.jpeg
  const imageUrl = CDN_URL + src;
  const [isImageOverlayOpen, setIsImageOverlayOpen] = useState(false);
  const openImageOverlay = () => setIsImageOverlayOpen(true);
  const closeImageOverlay = () => setIsImageOverlayOpen(false);

  return (
    <div className="my-2 pr-4">
      <img
        src={imageUrl}
        className="h-36 w-36 border border-gray-300 bg-white text-gray-300 rounded-lg hover:opacity-80 cursor-pointer"
        alt={caption}
        onClick={openImageOverlay}
      />
      <span className="text-sm text-gray-800 block">{caption}</span>
      <Modal modalShowing={isImageOverlayOpen} closeModal={closeImageOverlay} padded={false}>
        <img src={imageUrl} alt={caption} />
      </Modal>
    </div>
  );
}
