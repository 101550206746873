import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteCustomerAsync, readAllCustomersAsync } from "../../state/slices/customerSlice";
import { openModal } from "../../state/slices/frontend";
import Table from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";

export default function Customers() {
  const { customers } = useSelector(state => state.customer);
  const dispatch = useDispatch();
  const [state, setState] = useState({ pageSize: 25 });

  // initial load
  useEffect(() => {
    dispatch(readAllCustomersAsync());
  }, [dispatch]);

  const onChange = e => {
    const { value, name } = e.target;
    return setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditCustomer = customer => {
    dispatch(openModal({ modal: "customer", target: customer }));
  };
  const handleAddCustomer = () => {
    dispatch(openModal({ modal: "customer" }));
  };

  const handleDelete = customer => {
    const confirm = window.confirm("Deleting a customer is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deleteCustomerAsync(customer));
    }
  };

  const userColumns = [
    {
      dataField: "name",
      class: "text-left",
    },
    {
      dataField: "emailAddress",
      caption: "Address",
      class: "text-left",
    },
    {
      caption: "Certificate Title",
      dataField: "title",
      class: "text-left",
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`customer-edit-${value.id}`}
              onClick={() => handleEditCustomer(value)}
              icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />

            <TableButton
              testId={`customer-delete-${value.id}`}
              onClick={() => handleDelete(value)}
              icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Customers</h1>
          <div className="flex">
            <Button onClick={handleAddCustomer}>Add</Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6">
            <select
              value={state.pageSize}
              onChange={onChange}
              id="pageSize"
              name="pageSize"
              className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={5}>5</option>
              <option value={25}>25</option>
            </select>
          </div>
          <Table
            columns={userColumns}
            className=""
            items={customers || []}
            pageSize={state.pageSize}
          />
        </div>
      </main>
    </div>
  );
}
