import { capitalize } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { selectUsers } from "../../../../../state/slices/userSlice";
import { formatDateToString } from "../../../../../utils/formatDate";
import FormHeader from "../../../../common/forms/FormHeader";

export default function AuditLogTab({ fv }) {
  const users = useSelector(selectUsers);
  const getUserName = userId => users.find(u => u.id === userId).name;
  return (
    <>
      <FormHeader>Audit Logs</FormHeader>
      {fv.AuditLogs?.map(log => (
        <p className="text-md mt-3" key={log.id}>
          <b>{capitalize(log.action)}</b> on {formatDateToString(log.createdAt)} by{" "}
          {getUserName(log.UserId)}
        </p>
      ))}
    </>
  );
}
