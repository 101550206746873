import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PencilIcon, TrashIcon, CheckIcon } from "@heroicons/react/outline";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { MagicTable } from "../../common/Table";
import TableButton from "../../common/TableButton";
import Modal from "../../Modal";
import RegistrationPostModellingForm from "../../forms/RegistrationPostModellingForm";
import { readAllRetailersAsync, selectRetailerOptions } from "../../../state/slices/retailerSlice";
import formatAddressText from "../../../utils/formatAddress";
import { GreenButton, SubmitButton } from "../../common/Buttons";
import FormHeader from "../../common/forms/FormHeader";
import FormikFieldWithLabelAndError from "../../common/FormikFieldWithLabel";
import { readAllPlantsAsync, selectPlantOptions } from "../../../state/slices/plantSlice";
import { createQueryString } from "../../../utils/searchParams";
import { stripUnfilledValues_pureFunction } from "../../../utils/forms/stripUnfilledValues";
import { toDaysAgo } from "../../../utils/formatDate";

export default function RegistrationsPostModellingPage(props) {
  const search = props.location?.search;
  const searchParams = new URLSearchParams(search);
  const page = Number(searchParams.get("page"));
  const dispatch = useDispatch();
  const history = useHistory();
  const [registrations, setRegistations] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isRegistrationFormOpen, setResigtrationFormOpen] = useState(false);
  const [registrationFormData, setRegistrationFormData] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);
  const retailerOptions = useSelector(selectRetailerOptions);
  const plantOptions = useSelector(selectPlantOptions);

  const filters = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return {
      // submittalBefore: searchParams.get("submittalBefore") || "",
      // submittalAfter: searchParams.get("submittalAfter") || "",
      registrationBefore: searchParams.get("registrationBefore") || "",
      registrationAfter: searchParams.get("registrationAfter") || "",
      PlantId: searchParams.get("PlantId") || "",
      RetailerId: searchParams.get("RetailerId") || "",
    };
  }, [search]);

  const refreshRegistrations = useCallback(() => {
    const filtersAndPage = stripUnfilledValues_pureFunction({ page, ...filters });
    const queryString = createQueryString(filtersAndPage);
    axios
      .get("/api/registrations/list-postmodelling" + queryString)
      .then(({ data }) => {
        setRegistations(data.rows);
        setTotalPages(data.pages);
      })
      .catch(err => console.error("Failed to fetch registrations", err));
  }, [page, filters]);

  useEffect(() => {
    refreshRegistrations();
    dispatch(readAllRetailersAsync());
    dispatch(readAllPlantsAsync());
  }, [refreshRegistrations, dispatch]);

  const handleEditRegistration = registration => {
    setRegistrationFormData(registration);
    setResigtrationFormOpen(true);
  };
  // const handleAddRegistration = () => {
  //   dispatch(openModal({ modal: "registration" }));
  // };

  // const handleDelete = registration => {
  //   const confirm = window.confirm("Deleting a registration is irreversible.\nAre you sure?");
  //   if (confirm) {
  //     dispatch(deleteRegistrationAsync(registration));
  //   }
  // };
  const handleChangePage = newPage => {
    const cleaned = stripUnfilledValues_pureFunction(filters);
    cleaned.page = newPage;
    const queryString = createQueryString(cleaned);
    history.push("/registrations/post-modelling" + queryString);
  };

  const handleFilterChange = filters => {
    // set query string to allow user to share a filtered view
    // no need to trigger a fetch here, the useEffect above will pick up the query change and fetch again
    const cleaned = stripUnfilledValues_pureFunction(filters);
    // when filters are changed, page is reset. If you want to keep the same page on filter change, uncomment line below
    // if (page) cleaned.page = page;
    const queryString = createQueryString(cleaned);
    history.push("/registrations/post-modelling" + queryString);
    // close filter modal
    setShowFilterModal(false);
  };

  const userColumns = [
    {
      caption: "Retailer",
      cellDisplay(value) {
        return retailerOptions.find(r => r.value === value.RetailerId)?.label || "";
      },
    },
    {
      caption: "Plant",
      cellDisplay(value) {
        return plantOptions.find(p => p.value === value.PlantId)?.label || "";
      },
    },
    {
      dataField: "serialNumber",
      caption: "Serial Number",
    },
    {
      caption: "Address from registration",
      cellDisplay(value) {
        return formatAddressText(value);
      },
    },
    {
      caption: "Registration Created",
      cellDisplay(value) {
        return toDaysAgo(value.createdAt);
      },
    },
    {
      caption: "Matching Submittal",
      cellDisplay: value => {
        return value.Submittal ? (
          <CheckIcon
            className="h-6 p-1 m-2 rounded-full text-green-900"
            data-testid="icon-checkmark"
          />
        ) : (
          ""
        );
      },
    },
    {
      caption: "Submittal Created",
      cellDisplay(value) {
        return toDaysAgo(value.Submittal?.createdAt);
      },
    },
    {
      caption: "Approved & Sent",
      cellDisplay: value => {
        return value.isApproved ? (
          <CheckIcon
            className="h-6 p-1 m-2 rounded-full text-green-900"
            data-testid="icon-checkmark"
          />
        ) : (
          ""
        );
      },
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`registration-edit-${value.id}`}
              onClick={() => handleEditRegistration(value)}
              icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />
            <TableButton
              testId={`registration-delete-${value.id}`}
              // onClick={() => handleDelete(value)}
              icon={TrashIcon}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <Modal
        modalShowing={isRegistrationFormOpen}
        closeModal={() => setResigtrationFormOpen(false)}
        large
      >
        <RegistrationPostModellingForm
          registration={registrationFormData}
          refreshRegistrations={refreshRegistrations}
          closeModal={() => setResigtrationFormOpen(false)}
        />
      </Modal>
      <Modal modalShowing={showFilterModal} closeModal={() => setShowFilterModal(false)}>
        <FilterRegistrationsForm onSubmit={handleFilterChange} initialValues={filters} />
      </Modal>
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className=" flex justify-between items-center">
            <h1 className="text-3xl font-bold text-white">Registrations Post-Modelling</h1>
            <GreenButton className="ml-auto" onClick={() => setShowFilterModal(true)}>
              Filter
            </GreenButton>
          </div>
          <div className="mt-2">
            <Link className="mr-2" to="/registrations/compare">
              <GreenButton>Compare</GreenButton>
            </Link>
            {/* On current page this button doesn't need to do anything */}
            {/* <Link className="mr-2" to="/registrations/post-modelling"> */}
            <GreenButton>Post-Modelling</GreenButton>
            {/* </Link> */}
          </div>
        </div>
      </header>
      <main className="md:-my-36">
        <div className="max-w-7xl mx-auto py-6">
          <MagicTable
            columns={userColumns}
            className=""
            items={registrations}
            pageSize={25}
            maxPage={totalPages}
            // not sure what remote does, need to test and refactor
            remote={true}
            currentPage={page || 1}
            setPage={handleChangePage}
          />
        </div>
      </main>
    </div>
  );
}

function FilterRegistrationsForm({ onSubmit, initialValues }) {
  const retailerOptions = useSelector(selectRetailerOptions);
  const plantOptions = useSelector(selectPlantOptions);

  return (
    <div>
      <Formik className="p-4" initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <FormHeader>Filter Registrations</FormHeader>
          <div className="grid grid-cols-2">
            {/* <p className="col-span-2 mt-2">Submittal received date</p>
            <FormikFieldWithLabelAndError type="date" label="Before" name="submittalBefore" />
            <FormikFieldWithLabelAndError type="date" label="After" name="submittalAfter" /> */}
            <p className="col-span-2 mt-4">Registration created date</p>
            <FormikFieldWithLabelAndError type="date" label="Before" name="registrationBefore" />
            <FormikFieldWithLabelAndError type="date" label="After" name="registrationAfter" />
            <FormikFieldWithLabelAndError
              as="select"
              name="PlantId"
              label="Plant"
              className="col-span-2"
            >
              <option value="">---</option>
              {plantOptions.map(plant => (
                <option value={plant.value} key={plant.value}>
                  {plant.label}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
            <FormikFieldWithLabelAndError
              as="select"
              name="RetailerId"
              label="Retailer"
              className="col-span-2"
            >
              <option value="">---</option>
              {retailerOptions.map(retailer => (
                <option value={retailer.value} key={retailer.value}>
                  {retailer.label}
                </option>
              ))}
            </FormikFieldWithLabelAndError>
          </div>
          <div className="mt-4">
            <SubmitButton />
          </div>
        </Form>
      </Formik>
    </div>
  );
}
