import axios from "axios";

export async function validateSerialNumber(serialNumber) {
  const response = await axios.get("/api/fieldVerification/validate", { params: { serialNumber } });
  return response.data;
}

export async function getFieldVerificationById(id) {
  const response = await axios.get(`/api/fieldVerification/${id}`);
  return response.data;
}
