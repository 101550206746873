import React, { useState, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "gridjs-react";
import { gridjsClasses } from "../../../common/Table";

export default function TripReports(props) {
  const history = useHistory();
  const search = props.location?.search;
  const searchParams = new URLSearchParams(search);
  const page = Number(searchParams.get("page")) || 1;
  const gridRef = useRef();

  const [state, setState] = useState({ pageSize: 25 });

  const onChange = e => {
    const { value, name } = e.target;
    return setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const tripColumnsForGrid = useMemo(
    () => [
      { id: "name", name: "Plant name" },
      { id: "total", name: "total" },
      { id: "completed", name: "completed" },
      { id: "scheduled", name: "scheduled" },
      { id: "cancelled", name: "cancelled" },
      // invisible last col with ID to allow passing it to row click handler
      { id: "id", name: "", formatter: () => null },
    ],
    []
  );

  // add eventlistener onRowClick after component is first time rendered
  useEffect(() => {
    function handleRowClick(_e, { _cells }) {
      const plantId = _cells[5].data;
      history.push(`/trips/reports/${plantId}`);
    }
    const gridjsInstance = gridRef.current.getInstance();
    gridjsInstance.on("rowClick", handleRowClick);
  }, [history]);

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Trip Reports</h1>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6">
            <select
              value={state.pageSize}
              onChange={onChange}
              id="pageSize"
              name="pageSize"
              className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={5}>5</option>
              <option value={25}>25</option>
            </select>
          </div>
          <div className="flex flex-col overflow-auto h-full">
            <div className="py-2 w-full align-middle overflow-y-auto sm:px-6 lg:px-8 shadow">
              <Grid
                ref={gridRef}
                columns={tripColumnsForGrid}
                pagination={{
                  enabled: true,
                  summary: false,
                  limit: 25,
                  page: page - 1,
                  server: {
                    url: (prev, nextPage, limit) => {
                      if (page - 1 !== nextPage)
                        history.push("/trips/reports?page=" + (nextPage + 1));
                      return `${prev}?page=${nextPage}`;
                    },
                  },
                }}
                server={{
                  url: "/api/plants/report",
                  then: data => data.rows,
                  total: data => data.count,
                }}
                className={gridjsClasses}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
