import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectTripOptions,
  reScheduleFieldVerificationAsync,
} from "../../../../../state/slices/tripSlice";
import FormHeader from "../../../../common/forms/FormHeader";
import ImagePreview from "../../../../common/ImagePreview";
import Select from "../../../../common/Select";
import { SubmitButton } from "../../../../common/Buttons";
import { DataField } from "../../../../common/DataField";

export function ReAssignToTripForm({ fvId, defaultTripId, refreshTrip }) {
  const dispatch = useDispatch();
  const [tripId, setTripId] = useState(defaultTripId);
  const tripOptions = useSelector(selectTripOptions());
  const onSubmit = () =>
    dispatch(
      reScheduleFieldVerificationAsync({ TripId: tripId, status: "scheduled", id: fvId })
    ).then(refreshTrip);

  return (
    // the div prevents the submit button from taking the full width
    <div>
      <FormHeader className="mt-6">Re-assign to trip</FormHeader>
      <Select
        label="Trip"
        name="TripId"
        options={tripOptions}
        value={tripId}
        onChange={setTripId}
        className="mb-4 max-w-lg"
      />
      <SubmitButton onClick={onSubmit} className={"max-w-24"}>
        Re-schedule
      </SubmitButton>
    </div>
  );
}

export default function CancelledFieldVerificationView({ fv }) {
  const hasCancellationData = fv.cancellationNotes || fv.cancellationPhoto;

  return (
    <>
      {hasCancellationData && (
        <>
          <FormHeader className="mt-6">Cancellation Details</FormHeader>
          <DataField label="Cancellation notes" data={fv.cancellationNotes || "None"} />
          {fv.cancellationPhoto && (
            <ImagePreview src={fv.cancellationPhoto} caption="Cancellation Photo" />
          )}{" "}
        </>
      )}
    </>
  );
}
