import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { MagicTable } from "../common/Table";
import { SearchHomesHEUGForm } from "../forms/SearchForms";
import { selectAllPlantNamesAndIds } from "../../state/slices/plantSlice";
import { stripUnfilledValues_pureFunction } from "../../utils/forms/stripUnfilledValues";
import formatAddressText from "../../utils/formatAddress";
import { toDaysAgo } from "../../utils/formatDate";

const page = 1;

export default function SearchHomesHEUG() {
  const [state, setState] = useState({
    sortOrder: "desc",
    currentPage: page,
    maxPage: 1,
    filters: {},
  });
  const plants = useSelector(selectAllPlantNamesAndIds);

  // Initial load
  useEffect(() => {
    // cancels the request if the component is unmounted before the request completes
    const source = axios.CancelToken.source();
    axios
      .get("/api/heug/homes", { params: { page }, cancelToken: source.token })
      .then(value => {
        const { rows, count, pages } = value.data;
        setState(prevState => ({
          ...prevState,
          homes: rows,
          maxPage: pages,
          count,
        }));
      })
      .catch(() => {});
    return source.cancel;
  }, []);

  const homeColumns = useMemo(() => {
    const getPlantNameById = id => plants.find(p => p.id === id)?.name;

    return [
      {
        caption: "Edit",
        type: "actions",
        cellDisplay(value) {
          return (
            <div className="flex">
              {value.matchingRegistration ? (
                <Link
                  to={{
                    pathname: value.isRegistrationReviewed
                      ? `/registrations/post-modelling/`
                      : `/registrations/compare/`,
                    search: `?serialNumber=${value.serialNumber}`,
                  }}
                  className="text-blue-500 hover:text-blue-700 transition-all"
                >
                  Compare
                </Link>
              ) : (
                <span className="text-gray-400 transition-all">Compare</span>
              )}
            </div>
          );
        },
      },
      {
        caption: "Serial Number",
        dataField: "serialNumber",
      },
      {
        caption: "Date Added",
        cellDisplay: value => toDaysAgo(value.createdAt),
      },
      {
        caption: "Matching Registration",
        cellDisplay: value =>
          value.matchingRegistration ? (
            <CheckIcon
              className="h-6 p-1 m-2 rounded-full text-green-900"
              data-testid="icon-checkmark"
            />
          ) : (
            ""
          ),
      },
      {
        caption: "Address",
        cellDisplay: value => formatAddressText(value),
      },
      {
        caption: "Retailer",
        dataField: "retailer",
        class: "truncate w-32",
      },
      {
        caption: "Plant",
        class: "truncate w-32",
        cellDisplay(value) {
          return getPlantNameById(value?.PlantId);
        },
      },
    ];
  }, [plants]);

  const onSearch = async values => {
    const cleanedValues = stripUnfilledValues_pureFunction(values);
    const response = await axios.get("/api/heug/homes", { params: { ...cleanedValues } });
    const { rows, count, pages } = response.data;
    setState(prevState => ({
      ...prevState,
      filters: values,
      homes: rows,
      maxPage: pages,
      count,
    }));
  };

  const changePage = async page => {
    const response = await axios.get("/api/heug/homes", {
      params: { ...state.filters, page },
    });
    const { rows, count, pages } = response.data;

    setState(prevState => ({
      ...prevState,
      currentPage: page,
      homes: rows,
      maxPage: pages,
      count,
    }));
  };

  return (
    <div className="flex content-between md:h-5/6 px-2 pt-3">
      <div className="mt-3">
        <SearchHomesHEUGForm searchHandler={onSearch} />
      </div>
      <div className=" w-3/4 ">
        <MagicTable
          className="max-h-xl"
          columns={homeColumns}
          items={state.homes}
          remote={true}
          setPage={changePage}
          maxPage={state.maxPage}
          currentPage={state.currentPage}
        />
      </div>
    </div>
  );
}
