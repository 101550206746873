import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import OfficeBuildingIcon from "@heroicons/react/outline/OfficeBuildingIcon";
import { reorderFieldVerificationsAsync } from "../../../../state/slices/tripSlice";
import Button, { buttonVariants, SmallGreenButton } from "../../../common/Buttons";
import StatusIcon from "../../../common/StatusIcon";
import Header from "../../../common/Header";
import { MainSection, PageWithSidebar, Sidebar } from "../../PageWithSidebar";
import FieldVerificationFormForScheduler from "./field-verification/FieldVerificationFormForScheduler";
import PlantInspectionFormForScheduler from "./plant-inspection/PlantInspectionFormForScheduler";
import formatAddressText from "../../../../utils/formatAddress";
import FieldVerificationMainSection from "./field-verification/FieldVerificationMainSection";
import PlantInspectionMainSection from "./plant-inspection/PlantInspectionMainSection";
import { formatDisplayDate } from "../../../../utils/formatDate";

function TripsSidebar({
  items,
  tripId,
  selectedId,
  setSelectedId,
  setIsFvSelected,
  isFvSelected,
  refreshTrip,
  openNewHomeForm,
}) {
  const [isReordering, setIsReordering] = useState(false);

  return isReordering ? (
    <SidebarInDragMode
      items={items}
      tripId={tripId}
      refreshTrip={refreshTrip}
      openNewHomeForm={openNewHomeForm}
      setIsReordering={setIsReordering}
    />
  ) : (
    <SidebarInSelectMode
      items={items}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      setIsFvSelected={setIsFvSelected}
      isFvSelected={isFvSelected}
      openNewHomeForm={openNewHomeForm}
      setIsReordering={setIsReordering}
    />
  );
}

function SidebarHeader({ title, handleReorder, isReordering, openNewHomeForm }) {
  return (
    <div className="flex items-center justify-between p-3">
      <h1>{title}</h1>
      <SmallGreenButton onClick={handleReorder}>
        {isReordering ? "Save" : "Reorder"}
      </SmallGreenButton>
      <SmallGreenButton onClick={openNewHomeForm}>Add</SmallGreenButton>
    </div>
  );
}

function SidebarItem({ item, selected, onClick = () => {} }) {
  const isPi = item.isPlantInspection;
  return (
    <div
      onClick={onClick}
      className={`flex items-center cursor-pointer ${
        selected ? "bg-blue-100" : "hover:bg-gray-100"
      }`}
      data-testid={`fv-item-${item.id}`}
    >
      <StatusIcon status={item.status} isReviewed={item.isReviewed} />
      {isPi && <OfficeBuildingIcon className="w-5 mr-2" />}
      <span className="py-2 flex-1">
        {formatAddressText(item)}
        {isPi && <PIAppointmentDateAndTime pi={item} />}
      </span>
    </div>
  );
}

function PIAppointmentDateAndTime({ pi }) {
  return (
    <>
      {pi.scheduledDate && (
        <span className="text-sm italic ml-3 mr-2">{formatDisplayDate(pi.scheduledDate)}</span>
      )}
      {pi.appointmentTime && <span className="text-sm italic">{pi.appointmentTime}</span>}
    </>
  );
}

function SidebarInDragMode({ items, setIsReordering, refreshTrip, openNewHomeForm, tripId }) {
  const dispatch = useDispatch();
  const [orderedItems, setOrderedItems] = useState(items);

  const title = `Field Verifications (${items.length})`;

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  const handleDragEnd = val => {
    if (!val.destination) return;
    const itemsCloned = Array.from(orderedItems);
    const [reorderedFv] = itemsCloned.splice(val.source.index, 1);
    itemsCloned.splice(val.destination.index, 0, reorderedFv);
    setOrderedItems(itemsCloned);
  };

  const handleReorder = () => {
    dispatch(reorderFieldVerificationsAsync({ tripId, items: orderedItems })).then(() => {
      setIsReordering(false);
      refreshTrip();
    });
  };

  return (
    <>
      <SidebarHeader
        isReordering={true}
        title={title}
        openNewHomeForm={openNewHomeForm}
        handleReorder={handleReorder}
      />
      {/* DraggableItemList */}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="field-verifications">
          {provided => (
            <ul {...provided.droppableProps} ref={provided.innerRef}>
              {orderedItems.map((item, idx) => (
                <Draggable
                  key={item.id}
                  draggableId={`draggable-${item.id}-${item.isPlantInspection ? "pi" : "fv"}`}
                  index={idx}
                >
                  {provided => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <SidebarItem item={item} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

function SidebarInSelectMode({
  items,
  selectedId,
  setSelectedId,
  setIsFvSelected,
  isFvSelected,
  openNewHomeForm,
  setIsReordering,
}) {
  const title = `Field Verifications (${items.length})`;

  return (
    <>
      <SidebarHeader
        isReordering={false}
        title={title}
        openNewHomeForm={openNewHomeForm}
        handleReorder={() => setIsReordering(true)}
      />
      {items.map((item, idx) => (
        <SidebarItem
          key={idx}
          item={item}
          onClick={() => {
            const isFv = item.isFieldVerification;
            setIsFvSelected(isFv);
            setSelectedId(item.id);
          }}
          selected={item.id === selectedId && !!isFvSelected === !!item.isFieldVerification}
        />
      ))}
    </>
  );
}

function NewFormsMainSection({ tripId, refreshTrip }) {
  const [newFieldForm, setNewFieldForm] = useState(true);
  const [newPlantForm, setNewPlantForm] = useState(false);
  const newFVBtnVariant = newFieldForm ? buttonVariants.green : buttonVariants.gray;
  const newPIBtnVariant = newPlantForm ? buttonVariants.green : buttonVariants.gray;

  function showNewFieldVerificationForm() {
    setNewFieldForm(true);
    setNewPlantForm(false);
  }

  function showNewPlantInspectionForm() {
    setNewPlantForm(true);
    setNewFieldForm(false);
  }
  return (
    <div>
      <div className="flex gap-4">
        <Button onClick={showNewFieldVerificationForm} small variant={newFVBtnVariant}>
          New Field Verification
        </Button>
        <Button onClick={showNewPlantInspectionForm} small variant={newPIBtnVariant}>
          New Plant Inspection
        </Button>
      </div>
      {newFieldForm && (
        <FieldVerificationFormForScheduler tripId={tripId} refreshTrip={refreshTrip} />
      )}
      {newPlantForm && (
        <PlantInspectionFormForScheduler tripId={tripId} refreshTrip={refreshTrip} />
      )}
    </div>
  );
}

export default function TripDetailsPage(props) {
  const search = props.location?.search;
  const fvIdFromQuery = Number(new URLSearchParams(search).get("fvId"));
  const piIdFromQuery = Number(new URLSearchParams(search).get("piId"));

  const { tripId } = props.match.params; // get id from route

  const [trip, setTrip] = useState(null);
  const [selectedId, setSelectedId] = useState(fvIdFromQuery || piIdFromQuery);
  const [isFvSelected, setIsFvSelected] = useState(!!fvIdFromQuery);
  const shouldShowFvMainSection = !!(selectedId && isFvSelected);
  const shouldShowPiMainSection = !!(selectedId && !isFvSelected);

  const refreshTrip = useCallback(() => {
    axios
      .get("/api/trip/" + tripId)
      .then(({ data }) => setTrip(data))
      .catch(console.error);
  }, [tripId]);

  // initial load
  useEffect(() => {
    refreshTrip();
    // tripId is in the deps in case user stays on same page but changes trip
  }, [tripId, refreshTrip]);

  const openNewHomeForm = () => setSelectedId(null); // de-selects sidebar item

  return (
    <PageWithSidebar>
      <Sidebar>
        <TripsSidebar
          items={trip?.items || []}
          tripId={tripId}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setIsFvSelected={setIsFvSelected}
          isFvSelected={isFvSelected}
          refreshTrip={refreshTrip}
          openNewHomeForm={openNewHomeForm}
        />
      </Sidebar>
      <MainSection>
        <Header>{trip?.name}</Header>
        {!selectedId && <NewFormsMainSection tripId={tripId} refreshTrip={refreshTrip} />}
        {shouldShowFvMainSection && (
          <FieldVerificationMainSection fvId={selectedId} trip={trip} refreshTrip={refreshTrip} />
        )}
        {shouldShowPiMainSection && (
          <PlantInspectionMainSection piId={selectedId} trip={trip} refreshTrip={refreshTrip} />
        )}
      </MainSection>
    </PageWithSidebar>
  );
}
