import React from "react";
import { Form, Formik } from "formik";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import FormikFieldWithLabel from "../../../common/FormikFieldWithLabel";
import { HomeValidator } from "../../../../utils/validators/HomeValidator";
import { upsertHomeAsync } from "../../../../state/slices/homeSlice";
import { selectParkOptions } from "../../../../state/slices/parkSlice";
import { selectPlantOptions } from "../../../../state/slices/plantSlice";
import { selectCustomerOptions } from "../../../../state/slices/customerSlice";
import FilteredPlantOptions from "../../FilteredPlantOptions";
import { deleteBugCausingFields } from "./util";

export function FormButtons({ showDelete, handleDelete = () => {} }) {
  return (
    <div className="flex flex-grow justify-end items-start my-4">
      {showDelete && (
        <button
          className="bg-red-500 mr-2 text-white font-bold py-3 px-5 rounded-md"
          type="button"
          onClick={handleDelete}
        >
          Delete
        </button>
      )}
      <button type="submit" className="bg-green-700 mr-2 text-white font-bold py-3 px-5 rounded-md">
        Save
      </button>
    </div>
  );
}

// TODO: remove setParentState after refactoring submittal data
// normalization and home selector in sub details page
export default function SubmittalPhase({ home, handleDelete, refreshSubmittal, setParentState }) {
  const { submittalId } = useParams();
  const dispatch = useDispatch();
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const parkOptions = useSelector(selectParkOptions);

  const initialValues = {
    // core info
    CustomerId: home.CustomerId || "",
    PlantId: home.PlantId || "",
    ParkId: home.ParkId || "",
    serialNumber: home.serialNumber || "",
    modelNumber: home.modelNumber || "",
    energyStar: home.energyStar || "",
    type: home.type || "",
    floorPlan: home.floorPlan || "",
    certType: home.certType || "",
    retailer: home.retailer || "",
    certDate: home.certDate || "",
    completionDate: home.completionDate || "",
    notes: home.notes || "",
    // plans
    projectName: home.projectName || "",
    windowSpecs: home.windowSpecs || "",
    windowSpecsSHGC: home.windowSpecsSHGC || "",
    frontDoorSpecs: home.frontDoorSpecs || "",
    rearDoorSpecs: home.rearDoorSpecs || "",
    slidingGlassDoor: home.slidingGlassDoor || "",
    slidingGlassDoorSHGC: home.slidingGlassDoorSHGC || "",
    wallHeightFeet: home.wallHeightFeet || "",
    wallHeightInches: home.wallHeightInches || "",
    frontWallLength: home.frontWallLength || "",
    wallsFront: home.wallsFront || "",
    wallsSide: home.wallsSide || "",
    conditionedSqFt: home.conditionedSqFt || "",
    ductsInConditionedSpace: home.ductsInConditionedSpace || "",
    // location
    streetAddress: home.streetAddress || "",
    city: home.city || "",
    state: home.state || "",
    lotNumber: home.lotNumber || "",
    zipCode: home.zipCode || "",
    county: home.county || "",
    // unique plan details
    climateCode: home.climateCode || "",
    drawingNumber: home.drawingNumber || "",
    radiantBarrier: home.radiantBarrier || "",
    furnace: home.furnace || "",
    hpModelNumber: home.hpModelNumber || "",
    hpCoilNumber: home.hpCoilNumber || "",
    ductLocation: home.ductLocation || "",
    interiorPitch: home.interiorPitch || "",
    // duplicates from plans section
    // wallHeightFeet: home.wallHeightFeet || "",
    // wallHeightInches: home.wallHeightInches || "",
    insulationSpecFloor: home.insulationSpecFloor || "",
    insulationSpecWall: home.insulationSpecWall || "",
    insulationSpecRoof: home.insulationSpecRoof || "",
  };

  const handleSubmit = async values => {
    try {
      deleteBugCausingFields(values);

      const { payload } = await dispatch(
        upsertHomeAsync({
          ...values,
          id: home.id,
          SubmittalId: submittalId,
        })
      );
      refreshSubmittal();
      const [updatedHome] = payload;
      updatedHome.CustomerId = home.CustomerId;
      setParentState(updatedHome);
    } catch (error) {
      console.error("Failed to submit home form", error);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={HomeValidator(initialValues.serialNumber)}
      validateOnMount={true}
      validateOnChange={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form className="flex flex-col">
          <FormButtons showDelete={!!home.id} handleDelete={handleDelete} />
          <div className="lg:flex flex-row">
            {/* Left column */}
            <div className="flex flex-col">
              <div className="p-3 bg-white rounded-lg m-2">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Core Information</h3>
                </div>
                <div className="gap-2 md:grid grid-cols-3">
                  <FormikFieldWithLabel
                    as="select"
                    name="CustomerId"
                    label="Customer"
                    error={touched.CustomerId && errors.CustomerId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    {customerOptions.map(customer => (
                      <option value={customer.value} key={customer.value}>
                        {customer.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    as="select"
                    name="PlantId"
                    label="Plant/HBF"
                    error={touched.PlantId && errors.PlantId}
                    disabled={true}
                  >
                    <option value="">---</option>
                    <FilteredPlantOptions
                      plantOptions={plantOptions}
                      customerId={values.CustomerId}
                    />
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" type="select" label="Park" name="ParkId">
                    <option defaultValue value="">
                      ---
                    </option>
                    {parkOptions.map(park => (
                      <option value={park.value} key={park.value}>
                        {park.label}
                      </option>
                    ))}
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    type="text"
                    name="serialNumber"
                    id="serialNumber"
                    label="Serial Number"
                    error={touched.serialNumber && errors.serialNumber}
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="modelNumber"
                    label="Model Number"
                    className="xl:col-span-1"
                    error={touched.modelNumber && errors.modelNumber}
                  />
                  <FormikFieldWithLabel
                    as="select"
                    label="Energy Star"
                    type="select"
                    name="energyStar"
                    error={touched.energyStar && errors.energyStar}
                    disabled={true}
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="es">ES</option>
                    <option value="nes">NES</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    error={touched.type && errors.type}
                    as="select"
                    name="type"
                    label="Type"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="hud">HUD</option>
                    <option value="mod">MOD</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel as="select" name="floorPlan" label="Floor Plan">
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="base floor">Base Floor</option>
                    <option value="custom">Custom</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    error={touched.certType && errors.certType}
                    label="Cert Type"
                    as="select"
                    name="certType"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="no">No</option>
                    <option value="30">30%</option>
                    <option value="50">50%</option>
                    <option value="mod-50">Mod50%</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel name="retailer" label="Retailer" />
                  <FormikFieldWithLabel
                    className="col-span-2"
                    name="certDate"
                    label="Cert Date"
                    type="date"
                  />
                  <FormikFieldWithLabel
                    className="col-span-2"
                    type="date"
                    label="Completion Date"
                    name="completionDate"
                  />
                  <FormikFieldWithLabel
                    rows="3"
                    label="Notes"
                    as="textarea"
                    type="text"
                    name="notes"
                    id="notes"
                    className="col-span-3"
                  />
                </div>
              </div>
              <div className="md:grid bg-white rounded-md md:grid-cols-3 p-5 md:gap-6 m-2">
                <div className="flex col-span-3 justify-between">
                  <h3 className="text-lg leading-10 font-medium text-gray-900">Plans</h3>
                </div>
                <div className="col-span-3 rounded-md gap-2 md:grid  grid-cols-8">
                  <FormikFieldWithLabel
                    label="Project Name"
                    type="text"
                    name="projectName"
                    className="col-span-8"
                    placeholder=""
                  />
                  <FormikFieldWithLabel
                    label="Window Specs (U-Value)"
                    type="text"
                    name="windowSpecs"
                    className="col-span-2"
                  />
                  <FormikFieldWithLabel
                    label="Window Specs (SHGC)"
                    type="text"
                    name="windowSpecsSHGC"
                    className="col-span-2"
                  />

                  <FormikFieldWithLabel
                    label="Front Door Specs (U-Value)"
                    type="text"
                    name="frontDoorSpecs"
                    className="col-span-2"
                  />
                  <FormikFieldWithLabel
                    label="Rear Door Specs (U-Value)"
                    type="text"
                    name="rearDoorSpecs"
                    className="col-span-2"
                  />
                  <FormikFieldWithLabel
                    name="slidingGlassDoor"
                    label="SGD (U-Value)"
                    className="col-span-2"
                    placeholder="Sliding glass door"
                  />
                  <FormikFieldWithLabel
                    name="slidingGlassDoorSHGC"
                    label="SGD (SHGC)"
                    className="col-span-2"
                    placeholder="Sliding glass door"
                  />
                  <FormikFieldWithLabel
                    label="Wall Height (Feet)"
                    type="text"
                    name="wallHeightFeet"
                    id="wallHeightFeet"
                    className="col-span-2"
                    placeholder="Feet"
                  />
                  <FormikFieldWithLabel
                    label="Wall Height (Inches)"
                    type="text"
                    name="wallHeightInches"
                    id="wallHeightInches"
                    className="col-span-2"
                    placeholder="Inches"
                  />
                  <FormikFieldWithLabel
                    label="Front Walls Length"
                    type="text"
                    name="frontWallLength"
                    className="col-span-2"
                    placeholder=""
                  />
                  <FormikFieldWithLabel
                    label="Walls(Front)"
                    className="col-span-2"
                    type="text"
                    name="wallsFront"
                  />
                  <FormikFieldWithLabel
                    label="Walls(Side)"
                    className="col-span-2"
                    type="text"
                    name="wallsSide"
                  />
                  <FormikFieldWithLabel
                    label="Conditioned Sq Ft"
                    type="text"
                    name="conditionedSqFt"
                    className="col-span-3"
                  />
                  <FormikFieldWithLabel
                    label="Ducts in Conditioned Space"
                    as="select"
                    name="ductsInConditionedSpace"
                    className="col-span-3"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </FormikFieldWithLabel>
                </div>
              </div>
            </div>
            {/* Right column */}
            <div className="flex flex-col">
              <div className="m-2 p-3 bg-white rounded-lg ">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Location</h3>
                </div>
                <div className="md:grid gap-4 grid-cols-3">
                  <FormikFieldWithLabel
                    type="text"
                    name="streetAddress"
                    id="streetAddress"
                    label="Street Address"
                    className="col-span-3"
                  />

                  <FormikFieldWithLabel
                    type="text"
                    name="city"
                    id="city"
                    label="City"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="state"
                    id="state"
                    label="State"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="lotNumber"
                    id="lotNumber"
                    label="Lot #"
                    className="col-span-1"
                  />

                  <FormikFieldWithLabel
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    label="Zip Code"
                    className="col-span-1"
                  />
                  <FormikFieldWithLabel
                    type="text"
                    name="county"
                    id="county"
                    label="County"
                    className="col-span-2"
                  />
                </div>
              </div>

              <div className="col-span-3 p-5 bg-white rounded-lg m-2">
                <div className="flex justify-between mb-4">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Unique Plan Details
                  </h3>
                </div>
                <div className="gap-2 grid grid-cols-4 md:grid-cols-6">
                  <FormikFieldWithLabel
                    type="text"
                    name="climateCode"
                    label="Climate Code"
                    className="col-span-2"
                  ></FormikFieldWithLabel>

                  <FormikFieldWithLabel
                    type="text"
                    label="Drawing Number"
                    name="drawingNumber"
                    className="col-span-2"
                  />
                  <FormikFieldWithLabel
                    as="select"
                    name="radiantBarrier"
                    className="col-span-2"
                    label="Radiant Barrier"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </FormikFieldWithLabel>

                  <FormikFieldWithLabel
                    as="select"
                    name="furnace"
                    label="Furnace"
                    className="col-span-2"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="electric">Electric</option>
                    <option value="gas">Gas</option>
                    <option value="heat pump">Heat Pump</option>
                    <option value="package">Package</option>
                    <option value="gas 80 EF">Gas 80% EF</option>
                    <option value="gas 95 ef">Gas 95% EF</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="hpModelNumber"
                    className="col-span-2"
                    label="HP Model Number"
                    placeholder="No/Outside Brand"
                  ></FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="hpCoilNumber"
                    label="HP Coil Number"
                    placeholder="No/Outside Brand"
                    className="col-span-2"
                  ></FormikFieldWithLabel>

                  <FormikFieldWithLabel
                    as="select"
                    label="Duct Location"
                    name="ductLocation"
                    className="col-span-2"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="attic">Attic</option>
                    <option value="floor">Floor</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="interiorPitch"
                    as="select"
                    label="Interior Pitch"
                    className="col-span-2"
                  >
                    <option defaultValue value="">
                      ---
                    </option>
                    <option value="flat">Flat</option>
                    <option value="vault">Vault</option>
                    <option value="flat/vault">Flat/Vault</option>
                  </FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    label="Wall Height (Feet)"
                    type="text"
                    name="wallHeightFeet"
                    id="wallHeightfeet"
                    className=" col-span-2"
                    placeholder=""
                  />
                  <FormikFieldWithLabel
                    label="Wall Height (Inches)"
                    type="text"
                    name="wallHeightInches"
                    id="wallHeightinches"
                    className=" col-span-2"
                    placeholder=""
                  />
                  <FormikFieldWithLabel
                    name="insulationSpecFloor"
                    label="Ins. Spec Floor"
                    placeholder=""
                    className="col-span-2"
                  ></FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="insulationSpecWall"
                    label="Ins. Spec Walls"
                    placeholder=""
                    className="col-span-2"
                  ></FormikFieldWithLabel>
                  <FormikFieldWithLabel
                    name="insulationSpecRoof"
                    label="Ins. Spec Ceiling"
                    placeholder=""
                    className="col-span-2"
                  ></FormikFieldWithLabel>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
