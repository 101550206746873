import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { capitalize, startCase } from "lodash";
import InputField from "../common/Input";
import {
  checkUnmappedColumns,
  cleanHomeData,
  homeKeys,
  mapPlantNamesToIds,
  // mapRetailerNamesToIds,
  parseHomeData,
  readHomesFile,
  stripInvisibleRow,
  validateHomes,
} from "../../utils/csvReader";
import { importSubmittalAsync } from "../../state/slices/submittalSlice";
import Legend from "../common/Legend";

// step 1
export function ImportStepForm(props) {
  const fileRef = useRef();

  const importFormSubmit = async event => {
    event.preventDefault();
    const parseFile = readHomesFile(event.target.elements.file.files[0]);
    try {
      const payload = await parseFile.toPromise();
      let [headers, rows] = payload;
      rows = stripInvisibleRow(rows);
      props.handleNextStep();
      props.setHeaders(Object.fromEntries(headers));
      props.setImportData(rows);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={importFormSubmit}>
      <div className="bg-white space-y-6 sm:p-6 max-w-3xl mx-auto p-2 rounded-md">
        <fieldset>
          <Legend>New Submittal</Legend>
          <div className="w-full mt-2">
            <InputField
              required={true}
              name="name"
              label="Submittal Name"
              onChange={props.handleChange}
              value={props.state && props.state.name}
              type="text"
              className="w-full"
            />
          </div>
        </fieldset>
        <fieldset>
          <div>
            <legend className="text-base font-medium text-gray-900">File Upload</legend>
            <p className="text-sm text-gray-500">This must be a .csv file.</p>
          </div>
          <div className="mt-4 space-y-4">
            <input
              ref={fileRef}
              name="file"
              accept=".csv"
              type="file"
              role="button"
              aria-label="File Upload"
              required
            />
          </div>
        </fieldset>
      </div>
      <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-between">
        <button
          type="submit"
          className="ml-auto inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700"
        >
          Next &rarr;
        </button>
      </div>
    </form>
  );
}

// step 2
export function ValidateStepForm(props) {
  const { plants } = useSelector(state => state.plant);
  // const retailers = useSelector(state => state.retailer.retailers);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const { importData, importHeaders } = props.state;
      // warn user if some csv columns are not mapped to home db columns
      const unmappedColumns = checkUnmappedColumns(importHeaders);
      const unmappedColumnsString = unmappedColumns.join(", ");
      let confirmed = true;
      if (unmappedColumns.length) {
        confirmed = window.confirm(
          `Are you sure you want to continue? Some imported CSV columns are not mapped. Data for the following won't be saved: ${unmappedColumnsString}.`
        );
      }

      if (confirmed) {
        let homes = parseHomeData(importData, importHeaders);
        // homes = mapRetailerNamesToIds(homes, retailers);
        homes = mapPlantNamesToIds(homes, plants);
        // TODO: deprecate the comparison of submittal plant vs home plant
        // we want to allow setting any PlantId to any home independent of the submittal plant
        //
        // allow validation of plant name on homes vs submittal
        // props.state.plantName = displayPlantName(props.state.PlantId);
        const validations = await validateHomes(homes, props.state);
        props.handleNextStep();
        props.setValidation(validations);
        props.setHomes(homes);
      }
    } catch (error) {
      console.error("Error validating homes.", error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <div
        className={` max-h-screen max-w-6xl overflow-y-auto bg-white space-y-6 p-1 border-2 border-gray-600 rounded-sm`}
      >
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr className="border-b-2">
              {(Object.keys(props.state.importHeaders) || []).map((header, key) => (
                <th
                  key={key}
                  scope="col"
                  style={{
                    minWidth: "200px",
                  }}
                  className="px-6 py-3 text-left text-xs whitespace-nowrap font-medium text-gray-500 uppercase tracking-wider"
                >
                  <label
                    htmlFor={header} // dangerouslySetInnerHTML={{ __html: header }}
                    className="font-semibold"
                  >
                    {header}
                  </label>
                  <br></br>
                  <select
                    className="border-2 border-black text-gray-900 w-full inline-block text-lg"
                    name={header}
                    id={header}
                    onChange={props.handleChangeHeader}
                    value={props.state.importHeaders[header]}
                  >
                    <option className="capitalize" value="">
                      ----
                    </option>
                    {homeKeys.map((key, i) => (
                      <option className="capitalize" key={i} value={key}>
                        {capitalize(startCase(key))}
                      </option>
                    ))}
                  </select>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(props.state.importData || []).map((home, i) => (
              <tr key={i} className={i % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                {home.map((field, j) => (
                  <td
                    className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    key={j} // example: lotNumber-cell-1
                    data-testid={`${Object.keys(props.state.importHeaders || [])[j]}-cell-${i}`}
                  >
                    {field}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-between">
        <button
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={props.handlePreviousStep}
          type="button"
        >
          &larr; Back
        </button>

        <button
          type="submit"
          className={` inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700`}
        >
          Next &rarr;
        </button>
      </div>
    </form>
  );
}

// step 3
export function SaveStepForm(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const numberOfHomes = props.state.Homes.length;
  const isLoading = useSelector(state => state.submittal.loading);
  const isSaveDisabled = props.state.validation?.invalid?.length || isLoading;

  const saveFormSubmit = async event => {
    event.preventDefault();
    try {
      const { CustomerId, PlantId, energyStar, name, Homes } = props.state;
      const submittal = { CustomerId, PlantId, energyStar, name };
      const homes = Homes.map(cleanHomeData);
      const newSubmittal = await dispatch(importSubmittalAsync({ submittal, homes }));

      const newId = newSubmittal.payload?.id;
      if (newId) {
        history.push(`/submittals`);
      }
    } catch (err) {
      console.error("Failed to save homes in submittal importer", err);
    }
  };
  return (
    <form onSubmit={saveFormSubmit}>
      <div className="max-h-screen overflow-y-auto bg-white space-y-6 sm:p-6 rounded-sm border-2 border-black">
        <h6 className="font-semibold mt-4">{numberOfHomes} Homes scanned</h6>
        <ul className="divide-y divide-gray-200">
          <li className="px-6 cursor-pointer hover:bg-gray-50 py-4 overflow-y-auto validator">
            <button type="button" className="focus:outline-none w-full text-left">
              <span className="title ">Valid: {props.state.validation.valid?.length}</span>
            </button>

            <ul
              style={{
                maxHeight: "12rem",
              }}
              className="divide-y overflow-y-scroll  divide-gray-200 validator-list"
            >
              {(props.state.validation.valid || []).map((home, i) => (
                <li
                  key={home.serialNumber + i}
                  className="px-6 py-4 border flex justify-between gap-4"
                >
                  <span>SN: {home.serialNumber}</span>
                  <span>Row: {home.row} </span>
                </li>
              ))}
            </ul>
          </li>
          <li className="px-6 cursor-pointer hover:bg-gray-50 py-4 overflow-y-auto validator">
            <button type="button" className="w-full focus:outline-none text-left">
              <span className="title">Invalid: {props.state.validation.invalid?.length}</span>
            </button>
            <ul
              style={{
                maxHeight: "12rem",
              }}
              className="divide-y overflow-y-scroll  divide-gray-200 validator-list"
            >
              {(props.state.validation.invalid || []).map((invalidObj, index) => (
                <li
                  key={invalidObj.serialNumber + index}
                  className="hover:underline px-6 py-4 border flex flex-col justify-between"
                >
                  <div className="flex justify-between capitalize">
                    <span>SN: {invalidObj.serialNumber}</span>
                    <span>Row: {invalidObj.row} </span>
                  </div>
                  <ul>
                    {invalidObj.errors.map((error, i) => (
                      <li key={i} className="text-red-600 text-sm">
                        {error}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
      <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-between">
        <button
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={props.handlePreviousStep}
          type="button"
        >
          &larr; Back
        </button>

        <button
          disabled={isSaveDisabled}
          type="submit"
          className={` inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700`}
        >
          {isLoading ? "Saving" : "Save"}
        </button>
      </div>
    </form>
  );
}
