import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import HomeService from "../../services/home";
import { readAllSubmittalsAsync } from "./submittalSlice";
import { flashSuccessNotification } from "./notificationSlice";
import { readAllTripsAsync } from "./tripSlice";

// Selectors

export const selectHomeById = (submittalId, homeId) => state => {
  const submittal = state.submittal.submittals.find(
    submittal => submittal.id === Number(submittalId)
  );
  const home = submittal?.Homes?.find(home => home.id === Number(homeId));
  return home || null;
};

// Actions

export const upsertHomeAsync = createAsyncThunk("api/homes", async (payload, { dispatch }) => {
  const response = await HomeService.upsert(payload);
  dispatch(flashSuccessNotification("Successfully saved home."));
  // a home can be updated from 2 places, submittal or trip
  dispatch(readAllSubmittalsAsync());
  dispatch(readAllTripsAsync());
  return response;
});

// This slice is not currently used but the thunk upsertHomeAsync is.

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    loading: false,
    homes: [],
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setHomes: (state, action) => {
      if (action.payload) {
        state.homes = action.payload;
      }
    },
  },
  extraReducers: {
    [readAllSubmittalsAsync.fulfilled]: (state, action) => {
      // normalize and cleanup homes from submittal request
      const allHomes = action.payload.submittals.reduce((acc, cur) => {
        const homes = cur.Homes || cur.HEUGHomes;
        return [...homes, ...acc];
      }, []);
      // reset homes, then add all normalized homes
      state.homes = allHomes.map(h => {
        // extract nested CustomerId
        // assign to new object as workaround for "TypeError: Cannot add property CustomerId, object is not extensible"
        const home = { ...h };
        home.CustomerId = home.Plant?.CustomerId || null;
        delete home.Plant;
        return home;
      });
    },
    [upsertHomeAsync.pending]: state => {
      state.loading = true;
    },
    [upsertHomeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [upsertHomeAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clearErrors, setHomes } = homeSlice.actions;
export default homeSlice.reducer;
