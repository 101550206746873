import axios from "axios";
import { isHEUGSite } from "../utils/isHEUGSite";

class SubmittalService {
  async getAll() {
    const route = isHEUGSite ? "/api/heug/submittals" : "/api/45L/submittals";
    const { data } = await axios.get(route);
    return data;
  }
  async get(id) {
    const route = isHEUGSite ? `/api/heug/submittal/${id}` : `/api/45L/submittal/${id}`;
    const { data } = await axios.get(route);
    return data;
  }
  async update(submittal) {
    const route = isHEUGSite ? "/api/heug/submittal" : "/api/45L/submittal";
    const { data } = await axios.post(route, submittal);
    return data;
  }
  // DEPRECATED
  // async upload(submittalFile) {
  //   const { data } = await axios.post("/api/submittal", submittalFile);
  //   return data;
  // }
  async delete(id) {
    const route = isHEUGSite ? `/api/heug/submittal/${id}` : `/api/45L/submittal/${id}`;
    const { data } = await axios.delete(route);
    return data;
  }
}

export default new SubmittalService();
