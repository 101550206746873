import moment from "moment";

export function formatDateToString(date, format = moment.HTML5_FMT.DATE) {
  if (!date) return;
  const momentDate = moment(date).utc();
  if (!momentDate.isValid()) return;
  return momentDate.format(format).trim();
}

export function formatInputDate(date) {
  return formatDateToString(date);
}

export function formatDisplayDate(date) {
  return formatDateToString(date, "MM/DD/yyyy");
}

export function toDaysAgo(value) {
  if (value) {
    const numDays = moment(value).diff(new Date(), "days") * -1;
    return `${numDays} day${numDays === 1 ? "" : "s"} ago`;
  } else {
    return "";
  }
}
