import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";
import { Formik, Form } from "formik";
import Table from "../../../common/Table";
import { composePlantReportQuery, getReportByPlantId } from "../../../../services/plant";
import formatAddressText from "../../../../utils/formatAddress";
import { formatDisplayDate } from "../../../../utils/formatDate";
import { GreenButton, SubmitButton } from "../../../common/Buttons";
import Modal from "../../../Modal";
import FormikFieldWithLabelAndError from "../../../common/FormikFieldWithLabel";
import FormHeader from "../../../common/forms/FormHeader";

export default function TripReportByPlant({ match, location }) {
  const plantId = match.params.plantId;
  const [plantName, setPlantName] = useState("");
  const [report, setReport] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const query = new URLSearchParams(location?.search);
  const history = useHistory();
  const after = query.get("after") || "";
  const before = query.get("before") || "";

  const hudCount = report.filter(fv => fv.type === "HUD").length;
  const modCount = report.filter(fv => fv.type === "MOD").length;
  const plantCount = report.filter(fv => !fv.type).length;

  const totalCount = report.length;

  useEffect(() => {
    getReportByPlantId(plantId, { before, after }).then(report => {
      setPlantName(report.name);
      const reportPlant = [...report.fieldVerifications, ...report.plantInspections];
      setReport(reportPlant);
    });
  }, [plantId, after, before]);

  const onFilterChange = filters => {
    // set query string to allow user to share a filtered view
    // no need to trigger a fetch here, the useEffect above will pick up the query change and fetch again
    const query = composePlantReportQuery(filters);
    history.push("/trips/reports/" + plantId + query);
    // close filter modal
    setShowFilterModal(false);
  };

  const tripColumns = [
    {
      caption: "Address",
      cellDisplay: value => formatAddressText(value),
    },
    {
      caption: "type",
      cellDisplay: value => value.type || "Plant",
    },
    {
      caption: "Completed Date",
      cellDisplay: value => formatDisplayDate(value.completedDate),
    },
    {
      caption: "Reviewed",
      cellDisplay: value => {
        return value.isReviewed ? (
          <CheckIcon
            className="h-6 p-1 m-2 rounded-full text-green-900"
            data-testid="icon-completed"
          />
        ) : (
          ""
        );
      },
    },
  ];

  function handleRowClick(value) {
    value.type
      ? history.push(`/trips/reports/details/${value.id}`)
      : history.push(`/trips/reports/details/pi/${value.id}`);
  }

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 items-center">
          <h1 className="text-3xl font-bold text-white">Trip Report for Plant: {plantName}</h1>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6 flex">
            <h3 className="text-lg font-semibold text-white my-2">
              Completed: &nbsp; HUD {hudCount} &nbsp; &nbsp; MOD {modCount} &nbsp; &nbsp; Plant{" "}
              {plantCount} &nbsp; &nbsp; Total {totalCount}
            </h3>
            <GreenButton className="ml-auto" onClick={() => setShowFilterModal(true)}>
              Filter
            </GreenButton>
          </div>
          <Table columns={tripColumns} items={report} onRowClick={handleRowClick} />
        </div>
      </main>
      <Modal modalShowing={showFilterModal} closeModal={() => setShowFilterModal(false)}>
        <FilterReportForm onSubmit={onFilterChange} initialValues={{ before, after }} />
      </Modal>
    </div>
  );
}

function FilterReportForm({ onSubmit, initialValues }) {
  return (
    <div>
      <Formik className="p-4" initialValues={initialValues} onSubmit={onSubmit}>
        <Form>
          <FormHeader>Filter Report</FormHeader>
          <FormikFieldWithLabelAndError type="date" label="Completed After" name="after" />
          <FormikFieldWithLabelAndError type="date" label="Completed Before" name="before" />
          <div className="mt-4">
            <SubmitButton />
          </div>
        </Form>
      </Formik>
    </div>
  );
}

export function PublicTripReportByPlant({ match, location }) {
  const plantId = match.params.plantId;
  const [plantName, setPlantName] = useState("");
  const [report, setReport] = useState([]);
  const history = useHistory();

  const query = new URLSearchParams(location?.search);
  const after = query.get("after") || "";
  const before = query.get("before") || "";

  useEffect(() => {
    getReportByPlantId(plantId, { before, after, isReviewed: true }).then(report => {
      setPlantName(report.name);
      setReport(report.fieldVerifications);
    });
  }, [plantId, after, before]);

  const tripColumns = [
    {
      caption: "Address",
      cellDisplay: value => formatAddressText(value),
    },
    {
      caption: "Completed Date",
      cellDisplay: value => formatDisplayDate(value.completedDate),
    },
    {
      caption: "Reviewed",
      cellDisplay: value => {
        console.log("value", value);
        return value.isReviewed ? (
          <CheckIcon
            className="h-6 p-1 m-2 rounded-full text-green-900"
            data-testid="icon-completed"
          />
        ) : (
          ""
        );
      },
    },

  ];

  function handleRowClick(value) {
    value.type
      ? history.push(`/trips/reports/details/${value.id}`)
      : history.push(`/trips/reports/details/pi/${value.id}`);
  }

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 items-center">
          <h1 className="text-3xl font-bold text-white">Trip Report for Plant: {plantName}</h1>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6 flex">
            <h3 className="text-lg font-semibold text-white my-2">
              Completed count: {report?.length}
            </h3>
          </div>
          <Table columns={tripColumns} items={report} onRowClick={handleRowClick} />
        </div>
      </main>
    </div>
  );
}
