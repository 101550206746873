import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import SubmittalService from "../../services/submittal";
import { selectAllCustomerNamesAndIds } from "./customerSlice";
import { flashSuccessNotification } from "./notificationSlice";
import { selectAllPlantNamesAndIds } from "./plantSlice";
import HomeService from "../../services/home";

// Selectors

export const useSubmittalTableItems = () => {
  // we use this somewhat roundabout method of getting plant and customer name
  // because we cannot use hooks inside other hooks
  const plants = useSelector(selectAllPlantNamesAndIds);
  const getPlantNameById = id => plants.find(p => p.id === id)?.name;
  const customers = useSelector(selectAllCustomerNamesAndIds);
  const getCustomerNameById = id => customers.find(p => p.id === id)?.name;

  return useSelector(state =>
    state.submittal.submittals.map(s => {
      return {
        id: s.id,
        energyStar: s.energyStar,
        name: s.name,
        notes: s.notes,
        customerName: getCustomerNameById(s.CustomerId),
        plantName: getPlantNameById(s.PlantId),
        // TODO: move these 2 id fields to a different selector
        // they are passed down to the submittal form with the whole object
        // we should rather pass down only submittal id and use a selector in the form itself
        CustomerId: s.CustomerId,
        PlantId: s.PlantId,
        received: s.received,
        completed: 0,
        total: s.Homes?.length || 0,
      };
    })
  );
};

export const useSubmittalHEUGTableItems = () => {
  return useSelector(state =>
    state.submittal.submittals.map(s => {
      return {
        id: s.id,
        name: s.name,
        received: s.received,
        total: s.HEUGHomes?.length || 0,
      };
    })
  );
};

// Actions

export const readAllSubmittalsAsync = createAsyncThunk("submittal/readAll", async () => {
  try {
    const submittals = await SubmittalService.getAll();
    return { submittals };
  } catch (error) {
    console.log(error);
  }
});

export const upsertSubmittalAsync = createAsyncThunk(
  "submittal/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      // submittal here is in fact [submittal, isNew]
      const submittal = await SubmittalService.update(payload);
      dispatch(readAllSubmittalsAsync());
      return { submittal }; // {submittal: [{newData}, boolean]}
    } catch (error) {
      // TODO: cleanup this unused error logic
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteSubmittalAsync = createAsyncThunk("/submittal/delete", async payload => {
  await SubmittalService.delete(payload.id);
  return { id: payload.id };
});

export const deleteHomeAsync = createAsyncThunk(
  "/submittal/deleteHome",
  async (payload, { dispatch }) => {
    // TODO: add window.confirm to signal irreversible operation
    await HomeService.delete(payload);
    await dispatch(readAllSubmittalsAsync());
    dispatch(flashSuccessNotification("Home deleted successfully."));
  }
);

export const importSubmittalAsync = createAsyncThunk(
  "submittal/import",
  async (payload, { dispatch }) => {
    try {
      const { homes, submittal } = payload;
      const [newSubmittal] = await SubmittalService.update(submittal);
      homes.forEach(home => {
        home.SubmittalId = newSubmittal.id;
      });
      await HomeService.bulkUpsert(homes);
      await dispatch(readAllSubmittalsAsync());
      return newSubmittal;
    } catch (error) {
      console.error("Error creating submittal import", error);
    }
  }
);

export const getSubmittalsCSVAsync = createAsyncThunk("submittal/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/api/submittal/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "submittals.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

// Slice

export const submittalSlice = createSlice({
  name: "submittal",
  initialState: {
    loading: false,
    error: null,
    generalError: null,
    submittals: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllSubmittalsAsync.pending]: state => {
      state.loading = true;
    },
    [readAllSubmittalsAsync.fulfilled]: (state, action) => {
      state.loading = false;

      state.submittals = action.payload?.submittals.map(s => {
        s.CustomerId = s.Plant?.CustomerId;
        delete s.Plant;
        // TODO: remove Homes array in this slice and replace it with homeIds
        // then create a selector to get those homes from a submittal ID
        // this will cleanup (normalize) and provide better control over state
        return s;
      });
    },
    [readAllSubmittalsAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [upsertSubmittalAsync.pending]: state => {
      state.loading = true;
    },
    [upsertSubmittalAsync.fulfilled]: (state, action) => {
      state.loading = false;
      const [submittal, isNewSubmittal] = action.payload.submittal;

      if (isNewSubmittal) {
        // insert
        if (submittal) state.submittals.unshift(submittal);
      } else {
        // update
        state.submittals = (state.submittals || []).map(s =>
          s.id === submittal.id ? submittal : s
        );
      }
    },
    [upsertSubmittalAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [deleteSubmittalAsync.pending]: state => {
      state.loading = true;
    },
    [deleteSubmittalAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.submittals = state.submittals.filter(submittal => submittal.id !== action.payload.id);
    },
    [deleteSubmittalAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [importSubmittalAsync.pending]: state => {
      state.loading = true;
    },
    [importSubmittalAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [importSubmittalAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clearError, clearErrors } = submittalSlice.actions;
export default submittalSlice.reducer;
