import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteParkAsync } from "../../state/slices/parkSlice";
import { openModal } from "../../state/slices/frontend";
import Table from "../common/Table";
import { GreenButton as Button } from "../common/Buttons";
import TableButton from "../common/TableButton";

export default function Parks() {
  const { parks } = useSelector(state => state.park);
  const dispatch = useDispatch();

  const [state, setState] = useState({ pageSize: 25 });

  const onChange = e => {
    const { value, name } = e.target;
    return setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditPark = park => {
    dispatch(openModal({ modal: "park", target: park }));
  };
  const handleAddPark = () => {
    dispatch(openModal({ modal: "park" }));
  };

  const handleDelete = value => {
    const confirm = window.confirm("Deleting a park is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deleteParkAsync(value));
    }
  };

  const userColumns = [
    {
      dataField: "name",
      class: "text-left",
    },
    {
      dataField: "address",
      caption: "Address",
      class: "text-left",
    },
    {
      caption: "City",
      dataField: "city",
      class: "text-left",
    },
    {
      caption: "State",
      dataField: "state",
      class: "text-left",
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              onClick={() => handleEditPark(value)}
              testId={`park-edit-${value.id}`}
              icon={PencilIcon}
              className="text-indigo-600 mr-2"
            />
            <TableButton
              onClick={() => handleDelete(value)}
              icon={TrashIcon}
              testId={`park-delete-${value.id}`}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Parks</h1>
          <div className="flex">
            <Button onClick={handleAddPark}>Add</Button>

            {/* <button
              onClick={() => history.push("/search")}
              type="button"
              className="bg-green-600 items-center focus:opacity-50 mx-2 flex text-white font-bold py-3 px-5 rounded-md"
            >
              <OutlineIcons.Search className="mx-2 h-4 text-white" />
              Search
            </button> */}
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6">
            <select
              value={state.pageSize}
              onChange={onChange}
              id="pageSize"
              name="pageSize"
              className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={5}>5</option>
              <option value={25}>25</option>
            </select>
          </div>
          <Table columns={userColumns} className="" items={parks || []} pageSize={state.pageSize} />
        </div>
      </main>
    </div>
  );
}
