import axios from "axios";
import { isHEUGSite } from "../utils/isHEUGSite";

class HomeService {
  async getAll() {
    const route = isHEUGSite ? "/api/heug/homes" : "/api/45L/homes";
    const { data } = await axios.get(route);
    return data;
  }
  async get(id) {
    const route = isHEUGSite ? `/api/heug/home/${id}` : `/api/45L/home/${id}`;
    const { data } = await axios.get(route);
    return data;
  }
  async upsert(home) {
    const route = isHEUGSite ? "/api/heug/home" : "/api/45L/home";
    const { data } = await axios.post(route, home);
    return data;
  }
  async bulkUpsert(homes) {
    const responses = homes.map(home => {
      return this.upsert(home);
    });
    return Promise.all(responses);
  }
  async delete(id) {
    const route = isHEUGSite ? `/api/heug/home/${id}` : `/api/45L/home/${id}`;
    const { data } = await axios.delete(route);
    return data;
  }
}

export async function validateSerialNumber(serialNumber) {
  const route = isHEUGSite ? "/api/heug/home/validate" : "/api/45L/home/validate";
  const response = await axios.get(route, { params: { serialNumber } });
  return response.data;
}

export default new HomeService();
