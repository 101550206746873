import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "lodash";
import { useCustomerIdFromPlantId } from "../../../../../state/slices/customerSlice";
import { selectPlantById } from "../../../../../state/slices/plantSlice";
import {
  deletePlantInspectionAsync,
  reviewPlantInspectionAsync,
} from "../../../../../state/slices/tripSlice";
import { selectUsers } from "../../../../../state/slices/userSlice";
import formatAddressText from "../../../../../utils/formatAddress";
import { SmallGreenButton, SmallRedButton } from "../../../../common/Buttons";
import { DataField } from "../../../../common/DataField";
import HeadingWithTabsAndActions from "../../../../common/HeadingWithTabsAndActions";
import GeneralTab from "./GeneralTab";
import PlantInspectionTab from "./PlantInspectionTab";

export default function PlantInspectionMainSection({ piId, trip, refreshTrip }) {
  const tabs = ["General", "Plant Inspection"];
  const [tab, setTab] = useState(tabs[0]);
  const dispatch = useDispatch();
  const originalPI = trip?.items.find(pi => pi.id === piId && pi.isPlantInspection);
  const pi = { ...originalPI };
  const users = useSelector(selectUsers);
  const getUserName = userId => users.find(u => u.id === userId)?.name;
  const plant = useSelector(selectPlantById(pi?.PlantId));

  // not sure yet what next 2 lines does to this component.
  const CustomerId = useCustomerIdFromPlantId(pi?.PlantId);
  if (pi) pi.CustomerId = CustomerId;
  const isPiCompleted = pi?.status === "completed";
  const shouldShowReviewButton = isPiCompleted && !pi?.isReviewed;

  function deleteFv() {
    const confirm = window.confirm("Deleting a Plant Inspection is irreversible.\nAre you sure?");
    if (confirm) {
      dispatch(deletePlantInspectionAsync(pi)).then(refreshTrip);
    }
  }

  function reviewFv() {
    dispatch(reviewPlantInspectionAsync(pi.id)).then(refreshTrip);
  }

  function renderActions() {
    return (
      <div className="space-x-2">
        {shouldShowReviewButton && (
          <SmallGreenButton onClick={reviewFv}>Mark Reviewed</SmallGreenButton>
        )}
        <SmallRedButton onClick={deleteFv}>Delete</SmallRedButton>
      </div>
    );
  }

  return (
    <>
      <DataField label="plant" data={plant?.name} />
      <DataField label="Address" data={formatAddressText(pi)} />
      <DataField label="Status" data={capitalize(pi.status)} />
      {pi.status === "cancelled" && (
        <DataField label="Cancellation Notes" data={pi.cancellationNotes} />
      )}
      <DataField label="Scheduler" data={getUserName(pi.SchedulerId)} />
      <HeadingWithTabsAndActions
        tabs={tabs}
        currentTab={tab}
        onChange={setTab}
        renderActions={renderActions}
      />
      {tab === "General" && <GeneralTab pi={pi} refreshTrip={refreshTrip} />}
      {tab === "Plant Inspection" && <PlantInspectionTab pi={pi} refreshTrip={refreshTrip} />}
    </>
  );
}
