import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { flashErrorNotification, flashSuccessNotification } from "./notificationSlice";

// Selectors

export const selectTripById = id => state => {
  // Should convert id to number because it may come in as a string from router params
  return state.trip.trips.find(t => t.id === Number(id));
};

// selectOptions should usually follow a pattern like selectPlantOptions in plantSlice
// right now GET trips is not paginated so it's ok to break the pattern
// we will refactor/change this later because we will change the flow of trip scheduling
// right now this selector is only used to pick a trip to re-assign a cancelled FV to
export const selectTripOptions = () => state => {
  const { trips } = state.trip;
  return trips.map(t => ({ label: t.name, value: t.id }));
};

// Actions

export const readAllTripsAsync = createAsyncThunk("trips/readAll", async () => {
  try {
    const response = await axios.get("/api/trips/all");
    return { trips: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const readCurrentTripsAsync = createAsyncThunk("trips/readCurrent", async () => {
  try {
    const response = await axios.get("/api/trips");
    return { trips: response.data };
  } catch (error) {
    console.log(error);
  }
});

export const createTripAsync = createAsyncThunk(
  "trip/create",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/trip`, payload);
      dispatch(readAllTripsAsync());
      return data;
    } catch (error) {
      const errorPayload = error.response.data?.errors ?? error.response.data;
      return rejectWithValue(errorPayload);
    }
  }
);

export const updateTripAsync = createAsyncThunk("trip/update", async (payload, { dispatch }) => {
  try {
    const { data } = await axios.put(`/api/trip`, payload);
    dispatch(readAllTripsAsync());
    return data;
  } catch (error) {
    console.error(error);
  }
});

export const createFieldVerificationAsync = createAsyncThunk(
  "trip/createFieldVerificationAsync",
  async (payload, { dispatch }) => {
    try {
      const { data } = await axios.post("/api/fieldVerification", payload);
      dispatch(flashSuccessNotification("Field verification created."));
      return data;
    } catch (error) {
      const { data } = error.response;
      if (data.name === "SequelizeUniqueConstraintError") {
        dispatch(flashErrorNotification("Serial number must be unique."));
      } else {
        console.error("Error in create home action", { ...error });
      }
    }
  }
);

export const updateFieldVerificationAsync = createAsyncThunk(
  "trip/updateFieldVerification",
  async (payload, { dispatch }) => {
    await axios.put("/api/fieldVerification", payload);
    dispatch(flashSuccessNotification("Updated field verification."));
  }
);

export const deleteFieldVerificationPhotoAsync = createAsyncThunk(
  "trip/deleteFieldVerificationPhoto",
  async (payload, { dispatch }) => {
    // this will both trigger S3 to delete the file and update the field verification in the DB
    await axios.delete("/api/fieldVerification/photo/" + payload.id + "/" + payload.fieldName);
    dispatch(flashSuccessNotification("Deleted field verification photo."));
  }
);

export const reScheduleFieldVerificationAsync = createAsyncThunk(
  "trip/reScheduleFieldVerification",
  async (payload, { dispatch }) => {
    await axios.put(`/api/fieldVerification/${payload.id}/reschedule`, payload);
    dispatch(flashSuccessNotification("Re-scheduled field verification."));
  }
);

export const reviewFieldVerificationAsync = createAsyncThunk(
  "trip/reviewFieldVerification",
  async (payload, { dispatch }) => {
    const id = payload;
    await axios.put("/api/fieldVerification/review/" + id);
    dispatch(flashSuccessNotification("Reviewed field verification."));
  }
);

export const deleteFieldVerificationAsync = createAsyncThunk(
  "trip/deleteFieldVerificationAsync",
  async (payload, { dispatch }) => {
    await axios.delete("/api/fieldVerification/" + payload.id);
    dispatch(flashSuccessNotification("Deleted field verification."));
  }
);

export const reorderFieldVerificationsAsync = createAsyncThunk(
  "trip/reorderFieldVerification",
  async (payload, { dispatch }) => {
    const { tripId, items } = payload;
    try {
      const formattedBody = items.map(({ id, isPlantInspection, isFieldVerification }) => ({
        id,
        isPlantInspection,
        isFieldVerification,
      }));
      await axios.put(`/api/trip/${tripId}/reorder`, formattedBody);
      dispatch(flashSuccessNotification("The order was updated"));
    } catch (error) {
      console.error("Failed to reorder trip items", error);
    }
  }
);

export const deleteTripAsync = createAsyncThunk("/trip/delete", async (payload, { dispatch }) => {
  await axios.delete(`/api/trip/${payload.id}`);
  dispatch(flashSuccessNotification("Trip deleted"));
  return { id: payload.id };
});

export const createPlantInspectionAsync = createAsyncThunk(
  "trip/createPlantInspectionAsync",
  async (payload, { dispatch }) => {
    try {
      const { data } = await axios.post("/api/plantInspection", payload);
      dispatch(flashSuccessNotification("Plant inspection created."));
      return data;
    } catch (error) {
      const { data } = error.response;
      if (data.name === "SequelizeUniqueConstraintError") {
        dispatch(flashErrorNotification("Serial number must be unique."));
      } else {
        console.error("Error in create home action", { ...error });
      }
    }
  }
);

export const updatePlantInspectionAsync = createAsyncThunk(
  "trip/updatePlantInspection",
  async (payload, { dispatch }) => {
    await axios.put("/api/plantInspection", payload);
    dispatch(flashSuccessNotification("Updated plant inspection."));
  }
);

export const deletePlantInspectionPhotoAsync = createAsyncThunk(
  "trip/deletePlantInspectionPhoto",
  async (payload, { dispatch }) => {
    // this will both trigger S3 to delete the file and update the plant inspection in the DB
    await axios.delete("/api/plantInspection/photo/" + payload.id + "/" + payload.fieldName);
    dispatch(flashSuccessNotification("Deleted plant Inspection photo."));
  }
);

export const reviewPlantInspectionAsync = createAsyncThunk(
  "trip/reviewPlantInspection",
  async (payload, { dispatch }) => {
    const id = payload;
    await axios.put("/api/plantInspection/review/" + id);
    dispatch(flashSuccessNotification("Reviewed plant inspection."));
  }
);

export const deletePlantInspectionAsync = createAsyncThunk(
  "trip/deletePlantInspectionAsync",
  async (payload, { dispatch }) => {
    await axios.delete("/api/plantInspection/" + payload.id);
    dispatch(flashSuccessNotification("Deleted plant inspection."));
  }
);

export const getUsersCSVAsync = createAsyncThunk("trip/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/trip/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "trips.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const tripSlice = createSlice({
  name: "trip",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    trips: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllTripsAsync.pending]: state => {
      state.loading = true;
    },
    [readAllTripsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.trips = action.payload.trips;
    },
    [readAllTripsAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [readCurrentTripsAsync.pending]: state => {
      state.loading = true;
    },
    [readCurrentTripsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.trips = action.payload.trips;
    },
    [readCurrentTripsAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [createTripAsync.pending]: state => {
      state.loading = true;
    },
    [createTripAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [createTripAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [updateTripAsync.pending]: state => {
      state.loading = true;
    },
    [updateTripAsync.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateTripAsync.rejected]: state => {
      state.loading = false;
    },
    [deleteTripAsync.pending]: state => {
      state.loading = true;
    },
    [deleteTripAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.trips = state.trips.filter(trip => trip.id !== action.payload.id);
    },
    [deleteTripAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = tripSlice.actions;
export default tripSlice.reducer;
