import React, { useEffect } from "react";
import { Transition } from "@tailwindui/react";

const Modal = ({ modalShowing, closeModal, children, className, padded = true, large }) => {
  //   const { platformData } = useSelector(state => state.frontend);
  useEffect(() => {
    if (modalShowing) {
      document.body.style["overflowY"] = "hidden";
    } else {
      document.body.style["overflowY"] = "initial";
    }
  }, [modalShowing]);

  // TODO: add an event listener for the escape key, better UX
  // useEffect(() => {
  //   const listenerId = window.addEventListener("keypress", () => {})
  //   return () => window.removeEventListener("keypress", listenerId)
  // })

  const modalBodyStyle = `inline-block overflow-scroll bg-white rounded-lg max-h-full ${
    large ? "lg:max-w-6xl" : "lg:max-w-3xl"
  } sm:max-w-lg sm:w-full text-left shadow-xl transform transition-all ${padded ? "p-6" : ""}`;

  return (
    <Transition show={modalShowing}>
      <div className={`fixed z-40 inset-0 h-screen text-center py-12 ${className}`}>
        <div className="block h-full">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            as="div"
            className="fixed inset-0 transition-opacity"
          >
            <div
              className="absolute inset-0 bg-gray-500 opacity-75"
              onClick={() => {
                closeModal();
              }}
              data-testid="modal-close"
            ></div>
          </Transition.Child>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            as="div"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
            className={modalBodyStyle}
          >
            {children}
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
};

export default Modal;
