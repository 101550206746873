import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import * as Plant from "../../services/plant";

// selectors

export const selectPlantOptions = state =>
  state.plant.plantOptions.map(p => ({
    value: p.id,
    label: p.name,
    CustomerId: p.CustomerId,
    city: p.city,
    address: p.address,
    state: p.state,
    county: p.county,
    zipCode: p.zipCode,
  }));

export const selectAllPlantNamesAndIds = state =>
  state.plant.plants.map(p => ({ id: p.id, name: p.name }));

export const selectPlantById = id => state => state.plant.plants.find(p => p.id === id);

// actions

export const readAllPlantsAsync = createAsyncThunk("plants/readAll", async (_, { dispatch }) => {
  try {
    const plants = await Plant.getAll();
    await dispatch(readAllPlantOptions());
    return { plants: plants.rows }; // .rows because it's a paginated request
  } catch (error) {
    console.log(error);
  }
});

const readAllPlantOptions = createAsyncThunk("plants/fetchOptions", async () => {
  try {
    const plantOptions = await Plant.getAllOptions();
    return plantOptions;
  } catch (error) {
    console.error("Failed to fetch plant options", error);
  }
});

export const insertPlantAsync = createAsyncThunk(
  "plant/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let response;
      response = await axios.post(`/api/plant`, {
        ...payload,
      });
      dispatch(readAllPlantOptions());
      return { plant: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);
export const updatePlantAsync = createAsyncThunk(
  "plant/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let response;
      response = await axios.put(`/api/plant`, payload);
      dispatch(readAllPlantOptions());
      return { plant: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deletePlantAsync = createAsyncThunk("/plant/delete", async payload => {
  await axios.delete(`/api/plant/${payload.id}`);
  return { id: payload.id };
});

export const getUsersCSVAsync = createAsyncThunk("plant/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/user/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "plants.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const plantSlice = createSlice({
  name: "plant",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    // plants is the paginated detailed data we
    // use for display and editing
    plants: [],
    // plantOptions is used to populate dropdowns
    // contains all plants but with fewer details
    // contains { id, certificateName, CustomerId }
    // use a selector that maps onto { label, values, CustomerId }
    plantOptions: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllPlantsAsync.pending]: state => {
      state.loading = true;
    },
    [readAllPlantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.plants = action.payload.plants;
    },
    [readAllPlantsAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [readAllPlantOptions.fulfilled]: (state, action) => {
      state.plantOptions = action.payload;
    },
    [insertPlantAsync.pending]: state => {
      state.loading = true;
    },
    [insertPlantAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.plants = (state.plants || []).map(plant =>
        plant.id === action.payload.plant.id ? action.payload.plant : plant
      );
    },
    [insertPlantAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [updatePlantAsync.pending]: state => {
      state.loading = true;
    },
    [updatePlantAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.plant) {
        state.plants.unshift(action.payload.plant);
      }
    },
    [updatePlantAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [deletePlantAsync.pending]: state => {
      state.loading = true;
    },
    [deletePlantAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.plants = state.plants.filter(plant => plant.id !== action.payload.id);
    },
    [deletePlantAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = plantSlice.actions;
export default plantSlice.reducer;
