import React from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { HomeValidator } from "../../../../utils/validators/HomeValidator";
import FormikFieldWithLabel from "../../../common/FormikFieldWithLabel";
import { upsertHomeAsync } from "../../../../state/slices/homeSlice";
import { FormButtons } from "./SubmittalPhase";
import { selectParkOptions } from "../../../../state/slices/parkSlice";
import { selectPlantOptions } from "../../../../state/slices/plantSlice";
import { selectCustomerOptions } from "../../../../state/slices/customerSlice";
import FilteredPlantOptions from "../../FilteredPlantOptions";
import { deleteBugCausingFields } from "./util";

// TODO: remove setParentState after refactoring submittal data
// normalization and home selector in sub details page
export default function BillingPhase({ home, handleDelete, refreshSubmittal, setParentState }) {
  const { submittalId } = useParams();
  const dispatch = useDispatch();
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const parkOptions = useSelector(selectParkOptions);

  const initialValues = {
    // core info
    CustomerId: home.CustomerId || "",
    PlantId: home.PlantId || "",
    ParkId: home.ParkId || "",
    serialNumber: home.serialNumber || "",
    modelNumber: home.modelNumber || "",
    energyStar: home.energyStar || "",
    floorPlan: home.floorPlan || "",
    certType: home.certType || "",
    retailer: home.retailer || "",
    certDate: home.certDate || "",
  };

  const handleSubmit = async values => {
    deleteBugCausingFields(values);

    const { payload } = await dispatch(
      upsertHomeAsync({
        ...values,
        id: home.id,
        SubmittalId: submittalId,
      })
    );
    refreshSubmittal();
    const [updatedHome, isNew] = payload;
    if (!isNew) {
      updatedHome.CustomerId = home.CustomerId;
      setParentState(updatedHome);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={HomeValidator(initialValues.serialNumber)}
      validateOnMount={true}
      validateOnChange={true}
      // initialTouched={true}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
        <Form>
          <FormButtons showDelete={!!home.id} handleDelete={handleDelete} />
          <div className="p-3 bg-white rounded-lg m-2 lg:w-1/2">
            <div className="flex justify-between mb-4">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Core Information</h3>
            </div>
            <FormikFieldWithLabel
              as="select"
              name="CustomerId"
              label="Customer"
              error={touched.CustomerId && errors.CustomerId}
              disabled={true}
            >
              <option value="">---</option>
              {customerOptions.map(customer => (
                <option value={customer.value} key={customer.value}>
                  {customer.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="PlantId"
              label="Plant/HBF"
              error={touched.PlantId && errors.PlantId}
              disabled={true}
            >
              <option value="">---</option>
              <FilteredPlantOptions plantOptions={plantOptions} customerId={values.CustomerId} />
            </FormikFieldWithLabel>
            <FormikFieldWithLabel as="select" type="select" label="Park" name="ParkId">
              <option defaultValue value="">
                ---
              </option>
              {parkOptions.map(park => (
                <option value={park.value} key={park.value}>
                  {park.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              type="text"
              name="serialNumber"
              id="serialNumber"
              label="Serial Number"
              error={touched.serialNumber && errors.serialNumber}
            />
            <FormikFieldWithLabel
              type="text"
              name="modelNumber"
              id="ModelNo"
              label="Model Number"
              className="xl:col-span-1"
              error={touched.modelNumber && errors.modelNumber}
            />
            {/* <div className="col-span-3 grid grid-cols-3"> */}
            <FormikFieldWithLabel
              as="select"
              label="Energy Star"
              type="select"
              name="energyStar"
              error={touched.energyStar && errors.energyStar}
              disabled={true}
            >
              <option defaultValue value="">
                ---
              </option>
              <option value="es">ES</option>
              <option value="nes">NES</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel as="select" name="floorPlan" label="Floor Plan">
              <option defaultValue value="">
                ---
              </option>
              <option value="base floor">Base Floor</option>
              <option value="custom">Custom</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              label="Cert Type"
              as="select"
              name="certType"
              error={touched.certType && errors.certType}
            >
              <option defaultValue value="">
                ---
              </option>
              <option defaultValue value="30">
                30%
              </option>
              <option value="50">50%</option>
              <option defaultValue value="mod-50">
                Mod50%
              </option>
              <option defaultValue value="no">
                No
              </option>
              <option value="50">50%</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              className="col-span-2"
              name="certDate"
              label="Cert Date"
              type="date"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
