import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  createFieldVerificationAsync,
  updateFieldVerificationAsync,
} from "../../../../../state/slices/tripSlice";
import stripUnfilledValues from "../../../../../utils/forms/stripUnfilledValues";
import FormikFieldWithLabel from "../../../../common/FormikFieldWithLabel";
import FormHeader from "../../../../common/forms/FormHeader";
import { WithSpacesBetween } from "../../../../common/list-wrappers";
import { SubmitButton } from "../../../../common/Buttons";
import { validateSerialNumber } from "../../../../../services/fieldVerification";
import { selectCustomerOptions } from "../../../../../state/slices/customerSlice";
import { selectPlantOptions } from "../../../../../state/slices/plantSlice";
import { selectParkById, selectParkOptions } from "../../../../../state/slices/parkSlice";
import {
  selectRetailerById,
  selectRetailerOptions,
} from "../../../../../state/slices/retailerSlice";
import FilteredPlantOptions from "../../../../forms/FilteredPlantOptions";
import { selectMyUserId } from "../../../../../state/slices/authSlice";

// This form is filled in  and edited by the scheduler with
// the basic home data for a field verification

export default function StatefulFieldVerificationFormForScheduler({
  tripId,
  fv = {},
  refreshTrip,
}) {
  const dispatch = useDispatch();
  const myUserId = useSelector(selectMyUserId);
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);
  const parkOptions = useSelector(selectParkOptions);
  const retailerOptions = useSelector(selectRetailerOptions);

  const initialValues = {
    CustomerId: `${fv?.CustomerId || ""}`,
    PlantId: `${fv?.PlantId || ""}`,
    ParkId: `${fv.ParkId || ""}`,
    RetailerId: `${fv.RetailerId || ""}`,
    schedulerNotes: fv.schedulerNotes || "",
    serialNumber: fv.serialNumber || "",
    modelNumber: fv.modelNumber || "",
    type: fv.type || "",
    streetAddress: fv.streetAddress || "",
    lotNumber: fv.lotNumber || "",
    city: fv.city || "",
    state: fv.state || "",
    zipCode: fv.zipCode || "",
    county: fv.county || "",
    id: fv.id || "",
  };

  const handleSubmit = async (fv, { resetForm }) => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(fv);
    if (fv.id) {
      fv.SchedulerId = myUserId;
      await dispatch(updateFieldVerificationAsync(fv));
      refreshTrip();
    } else {
      fv.TripId = tripId;
      await dispatch(createFieldVerificationAsync(fv));
      resetForm({
        CustomerId: "",
        PlantId: "",
        serialNumber: "",
        modelNumber: "",
        type: "",
        ParkId: "",
        streetAddress: "",
        lotNumber: "",
        city: "",
        state: "",
        zipCode: "",
        county: "",
      });
      refreshTrip();
    }
  };

  return (
    <FieldVerificationFormForScheduler
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      customerOptions={customerOptions}
      plantOptions={plantOptions}
      parkOptions={parkOptions}
      retailerOptions={retailerOptions}
    />
  );
}

function ParkInfo({ id }) {
  const park = useSelector(selectParkById(Number(id)));
  return id ? (
    <div className="col-span-2">
      <p>Park Address: {[park.address, park.city, park.state].join(", ")}</p>
      <p>Park Contact: {[park.contactName, park.contactPhone].join(", ")}</p>
    </div>
  ) : null;
}

function RetailerInfo({ id }) {
  const retailer = useSelector(selectRetailerById(Number(id)));
  return id ? (
    <div className="col-span-2">
      <p>Retailer Address: {[retailer.address, retailer.city, retailer.state].join(", ")}</p>
      <p>Retailer Contact: {[retailer.contactName, retailer.contactPhone].join(", ")}</p>
    </div>
  ) : null;
}

export function FieldVerificationFormForScheduler({
  handleSubmit,
  customerOptions,
  plantOptions,
  parkOptions,
  retailerOptions,
  initialValues,
}) {
  // Validation rules
  const HomeSchema = Yup.object().shape({
    CustomerId: Yup.string().required("Required"),
    PlantId: Yup.string().required("Required"),
    ParkId: Yup.string(),
    RetailerId: Yup.string(),
    schedulerNotes: Yup.string(),
    serialNumber: Yup.string()
      .required("Required")
      .test("checkDuplicateSerialNumber", "This serial number already exists", async value => {
        if (initialValues.serialNumber === value) return true;
        else {
          return validateSerialNumber(value);
        }
      }),
    modelNumber: Yup.string(),
    type: Yup.string().required("Required"),
    streetAddress: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    lotNumber: Yup.string(),
    zipCode: Yup.string(),
    county: Yup.string(),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={HomeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values }) => (
        <>
          {initialValues.id ? (
            <FormHeader className="mt-4">Edit Field Verification</FormHeader>
          ) : (
            <div className="flex gap-4"></div>
          )}
          <Form>
            <WithSpacesBetween>
              <div className="grid grid-cols-2 gap-2">
                <FormikFieldWithLabel
                  as="select"
                  name="CustomerId"
                  label="Customer"
                  error={touched.CustomerId && errors.CustomerId}
                >
                  <option value="">---</option>
                  {customerOptions.map(customer => (
                    <option value={customer.value} key={customer.value}>
                      {customer.label}
                    </option>
                  ))}
                </FormikFieldWithLabel>
                <FormikFieldWithLabel
                  as="select"
                  name="PlantId"
                  label="Plant/HBF"
                  error={touched.PlantId && errors.PlantId}
                >
                  <option value="">---</option>
                  <FilteredPlantOptions
                    plantOptions={plantOptions}
                    customerId={values.CustomerId}
                  />
                </FormikFieldWithLabel>
                <FormikFieldWithLabel
                  as="select"
                  name="ParkId"
                  label="Park"
                  className="col-span-2"
                  error={touched.ParkId && errors.ParkId}
                >
                  <option value="">---</option>
                  {parkOptions.map(park => (
                    <option value={park.value} key={park.value}>
                      {park.label}
                    </option>
                  ))}
                </FormikFieldWithLabel>
                <ParkInfo id={values.ParkId} />
                <FormikFieldWithLabel
                  as="select"
                  name="RetailerId"
                  label="Retailer"
                  className="col-span-2"
                  error={touched.ParkId && errors.ParkId}
                >
                  <option value="">---</option>
                  {retailerOptions.map(retailer => (
                    <option value={retailer.value} key={retailer.value}>
                      {retailer.label}
                    </option>
                  ))}
                </FormikFieldWithLabel>
                <RetailerInfo id={values.RetailerId} />
                <FormikFieldWithLabel
                  className="col-span-2"
                  label="Scheduler notes"
                  name="schedulerNotes"
                  type="text"
                  as="textarea"
                  fieldClass="h-24 resize-y"
                />
                <FormikFieldWithLabel
                  name="serialNumber"
                  label="Serial Number"
                  type="text"
                  className="col-span-1"
                  error={touched.serialNumber && errors.serialNumber}
                />
                <FormikFieldWithLabel
                  name="modelNumber"
                  label="Model Number"
                  type="text"
                  className="col-span-1"
                  error={touched.modelNumber && errors.modelNumber}
                />
                <FormikFieldWithLabel
                  as="select"
                  name="type"
                  label="Type"
                  error={touched.type && errors.type}
                >
                  <option value="">---</option>
                  <option value="HUD">HUD</option>
                  <option value="MOD">MOD</option>
                </FormikFieldWithLabel>
                <FormikFieldWithLabel
                  name="streetAddress"
                  label="Street Address"
                  type="text"
                  className="col-span-1"
                  error={touched.streetAddress && errors.streetAddress}
                />
                <FormikFieldWithLabel
                  name="lotNumber"
                  label="Lot #"
                  type="text"
                  className="col-span-1"
                  error={touched.lotNumber && errors.lotNumber}
                />
                <FormikFieldWithLabel
                  name="city"
                  label="City"
                  type="text"
                  className="col-span-1"
                  error={touched.city && errors.city}
                />
                <FormikFieldWithLabel
                  name="state"
                  label="State"
                  type="text"
                  className="col-span-1"
                  error={touched.state && errors.state}
                />
                <FormikFieldWithLabel
                  name="zipCode"
                  label="Zip Code"
                  type="text"
                  className="col-span-1"
                  error={touched.state && errors.state}
                />
                <FormikFieldWithLabel
                  name="county"
                  label="County"
                  type="text"
                  className="col-span-1"
                  error={touched.state && errors.state}
                />
              </div>
            </WithSpacesBetween>

            <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
              <SubmitButton />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
}
