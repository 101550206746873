import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, loginUser, loginUserTo45L } from "../../services/auth";
import { ReactSession } from "react-client-session";
ReactSession.setStoreType("cookie");

// selectors

export const selectMyUserId = state => state.auth.currentUser?.id;
export const selectMyRetailerId = state => state.auth.currentUser?.RetailerId;

// actions

// TODO: simplify session persistence
// suggested implementation: on login success save token in localStorage
// if a token is found in localStorage, call verifyAuth
// both login and verifyAuth should return a "Set-Cookie"

// login used for HEUG site
export const loginAsync = createAsyncThunk("auth/signin", async payload => {
  const user = await loginUser(payload);
  ReactSession.set("currentUser", { ...user, isAuthenticated: true });
  return user;
});

// 45L signin is different from heug signin in that retailer accounts are not allowed on 45L site
export const login45LAsync = createAsyncThunk("auth/signin-45L", async payload => {
  const user = await loginUserTo45L(payload);
  ReactSession.set("currentUser", { ...user, isAuthenticated: true });
  return user;
});

export const logoutAsync = createAsyncThunk("auth/signout", async payload => {
  const response = await axios.post(`/api/auth/signout`, payload);
  ReactSession.set("currentUser", { isAuthenticated: false });
  return response.data;
});

export const checkAuthStatusAsync = createAsyncThunk("auth/status", async () => {
  const user = await checkStatus();
  ReactSession.set("currentUser", user);
  return user;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: true,
    error: null,
    isAuthenticated: false,
    currentUser: null,
    loginModalShowing: false,
    activeAuthForm: "signIn", // or "resetPassword", "signUp"
    redirectOnCloseTo: null,
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setLoginModalState: (state, action) => {
      if (undefined !== action.payload.loginModalShowing) {
        state.loginModalShowing = action.payload.loginModalShowing;
      }
      if (action.payload.activeAuthForm) {
        state.activeAuthForm = action.payload.activeAuthForm;
      }
      if (action.payload.redirectOnCloseTo) {
        state.redirectOnCloseTo = action.payload.redirectOnCloseTo;
      } else {
        state.redirectOnCloseTo = null;
      }
    },
  },
  extraReducers: {
    [loginAsync.pending]: state => {
      state.loading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (!action.payload.id) {
        state.error = "Wrong username or password";
      } else {
        state.error = null;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
      }
    },
    [loginAsync.rejected]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = "Invalid credentials";
    },
    [login45LAsync.pending]: state => {
      state.loading = true;
    },
    [login45LAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (!action.payload.id) {
        state.error = "Wrong username or password";
      } else {
        state.error = null;
        state.currentUser = action.payload;
        state.isAuthenticated = true;
      }
    },
    [login45LAsync.rejected]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.error = "Invalid credentials";
    },
    [logoutAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.currentUser = null;
    },
    [checkAuthStatusAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.currentUser = action.payload.currentUser;
    },
  },
});
export const { clearErrors, setLoginModalState } = authSlice.actions;
export default authSlice.reducer;
