import axios from "axios";
import React, { useEffect, useState } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { capitalize, upperCase } from "lodash";

function Header({ children }) {
  return (
    <th
      className="w-1/4 pb-4 pl-3 pr-10 text-lg leading-6 font-medium text-gray-900 text-left"
      scope="col"
    >
      {children}
    </th>
  );
}

function DataRow({ rowData }) {
  const [title, fvData, submittalData] = rowData;
  const bothRowsEmpty = !submittalData && !fvData;
  return (
    <tr className="border-b border-gray-300">
      <th className="py-3 pl-3 pr-10 font-normal text-gray-800 text-left" scope="row">
        {title}
      </th>
      <td className="py-2 pl-3 pr-10">{fvData}</td>
      <td className="py-2 pl-3 pr-10">{submittalData}</td>
      <td className="py-2 pl-3 pr-10">
        {bothRowsEmpty ? (
          ""
        ) : submittalData === fvData ? (
          <CheckIcon className="h-6 w-6 text-green-700" />
        ) : (
          <XIcon className="h-6 w-6 text-red-700" />
        )}
      </td>
    </tr>
  );
}

export default function CompareHomeAndFV({ match }) {
  const fvId = match.params.fvId;
  // eslint-disable-next-line no-unused-vars
  const [home, setReport] = useState({});
  const [fv, setFv] = useState({});

  useEffect(() => {
    axios.get("/api/45L/home/compare/" + fvId).then(res => {
      setReport(res.data);
      setFv(res.data.FieldVerification || {});
    });
  }, [fvId]);

  return (
    <div className="h-screen w-full bg-gray-200">
      <header className="bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-6 px-8 items-center">
          <h1 className="text-3xl font-bold text-white">Field Verification Comparison</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto p-8">
          <table className="w-full h-px table-fixed">
            <thead>
              <tr className="border-b border-gray-300">
                <Header>Data field</Header>
                <Header>Field Verification</Header>
                <Header>Submittal</Header>
                <Header>Match</Header>
              </tr>
            </thead>
            <tbody>
              <DataRow
                rowData={[
                  "HVAC Distribution / Duct Location",
                  capitalize(fv?.HVACDistribution),
                  capitalize(home.ductLocation),
                ]}
              />
              <DataRow
                rowData={["Furnace Type", formatGasValue(fv?.gas), capitalize(home.furnace)]}
              />
              <DataRow
                rowData={[
                  "Energy Star Label",
                  fv?.energyStarLabel ? "ES" : "NES",
                  upperCase(home.energyStar),
                ]}
              />
              <DataRow rowData={["Serial Number", fv?.serialNumber, home.serialNumber]} />
              <DataRow
                rowData={[
                  "Homeowner Address Street # and Street",
                  fv?.streetAddress,
                  home.streetAddress,
                ]}
              />
              <DataRow rowData={["Homeowner Address Lot #", fv?.lotNumber, home.lotNumber]} />
              <DataRow rowData={["Homeowner Address City", fv?.city, home.city]} />
              <DataRow rowData={["Homeowner Address State", fv?.state, home.state]} />
              <DataRow rowData={["Homeowner Address Zip Code", fv?.zipCode, home.zipCode]} />
              <DataRow rowData={["Model Number", fv?.modelNumber, home.modelNumber]} />
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
}

function formatGasValue(val) {
  if (val === null) return "";
  if (val === true) return "Gas";
  if (val === false) return "Electric";
}
