import { camelCase } from "lodash";
import Papa from "papaparse";
import { combineLatest, from, Observable } from "rxjs";
import { last, map, scan, skip, switchMap, take } from "rxjs/operators";
import { validateSerialNumber } from "../services/home";

export let parser$ = file => {
  const config = {
    delimeter: ",",
    headers: true,
  };
  return new Observable(observer => {
    let unsubscribe;
    function onNext({ data }, parser) {
      observer.next(data);
      if (parser) {
        unsubscribe = parser.abort;
      }
    }
    function onError(error) {
      observer.error(error);
    }
    function onComplete(results) {
      observer.complete(results);
    }

    // config.currentStep = onNext
    config.step = (data, parser) => {
      onNext(data, parser);
    };
    config.error = onError;
    config.complete = onComplete;
    Papa.parse(file, config);
    return () => unsubscribe && unsubscribe();
  });
};

export function readHomesFile(file) {
  const parseFile = parser$(file);
  return combineLatest([
    parseFile.pipe(
      take(1),
      switchMap(data => {
        return from(data);
      }),
      map(header => {
        let newKey = camelCase(
          header
            .replace(/\s/gi, "")
            .replace(
              /NoDuplicates|NotRequired|NoPOBoxesorTBD|HomeownerAddress|OfficeUse|##|%/gi,
              ""
            )
        );
        if (/UValue|NoOutsideModel|NoOutsideBrand/gi.test(newKey)) {
          newKey = newKey.replace(/UValue|NoOutsideModel|NoOutsideBrand/gi, "");
        }
        newKey = newKey.replace(/shgc/gi, "SHGC");
        switch (newKey) {
          case "lot":
            newKey = "lotNumber";
            break;
          case "furnace":
            newKey = newKey.toLowerCase();
            break;
          case "homeownerName":
            newKey = "homeOwnerName";
            break;
          case "modelNumber":
          case "serialNumber":
          case "retailer":
          case "state":
          case "city":
          case "county":
            break;
          case "retailerPhoneNumber":
            newKey = "retailerPhone";
            break;
          case "streetAndStreet":
            newKey = "streetAddress";
            break;
          case "esNes":
            newKey = "energyStar";
            break;
          case "modelNo":
            newKey = "modelNumber";
            break;
          case "drawingNo":
            newKey = "drawingNumber";
            break;
          case "hpModel":
            newKey = "hpModelNumber";
            break;
          case "hpCoil":
            newKey = "hpCoilNumber";
            break;
          case "sgd":
            newKey = "slidingGlassDoor";
            break;
          case "sgdSHGC":
            newKey = "slidingGlassDoorSHGC";
            break;
          default:
            break;
        }

        return [header, newKey];
      }),
      scan((acc, curr) => {
        acc.push(curr);
        return acc;
      }, []),
      last()
    ),
    parseFile.pipe(
      skip(1),
      scan((acc, curr) => {
        acc.push(curr);
        return acc;
      }, []),
      last()
    ),
  ]);
}

// sometimes the csv contains an invisible last row
// strip this row to avoid the issues it causes
export function stripInvisibleRow(rows) {
  // rows is like [[...valid values], [...valid values], [""]]
  // invisible row, if present, is like [""]
  return rows.filter(item => {
    const isRowInvisible = item.length === 1 && item[0] === "";
    return !isRowInvisible;
  });
}

export function checkUnmappedColumns(cols) {
  // expects cols: { original col: mapped value }
  const unmapped = [];
  for (let key in cols) {
    if (!homeKeys.includes(cols[key])) {
      // csv column extraction tends to add newline characters. clean them here
      key = key.replace(/\n/, " ");
      unmapped.push(key);
    }
  }
  return unmapped;
}

export function parseHomeData(data, headers) {
  return data.reduce((acc, currentItem) => {
    const home = {};
    const keys = Object.keys(headers);
    for (const idx in keys) {
      let value = currentItem[idx];
      const key = headers[keys[idx]];
      home[key] = value;
      if (headers[keys[idx]] === "energyStar" && home[key]) {
        home[key] = home[key]?.toLowerCase()?.trim();
      }
    }

    return [...acc, home];
  }, []);
}

export function changeDateEmptyStringsToNull(obj) {
  // the back-end returns "Incorrect datetime value: 'Invalid date'" error
  // if a date field is sent as empty string
  if (obj.dateAdded === "") obj.dateAdded = null;
  if (obj.soldDate === "") obj.soldDate = null;
}

export function cleanHomeData(home) {
  const allBooleanFields = ["radiantBarrier", "occupied", "set", "acOnSite"];

  allBooleanFields.forEach(key => {
    if (home[key]) {
      if (/yes|y/i.test(home[key])) {
        home[key] = true;
      }
      if (/no|n/i.test(home[key])) {
        home[key] = false;
      }
    }
  });
  changeDateEmptyStringsToNull(home);
  return home;
}

// DEPRECATED: no need to validate the type, ES/NES is no longer supplied in import
// function getValidCertTypes(isEnergyStar, isHUD) {
//   let validCerts = [];
//   if (isEnergyStar) {
//     // ES and any type
//     validCerts = ["NO", "50%"];
//   } else if (isHUD) {
//     // if HUD and NES
//     validCerts = ["NO", "30%", "50%"];
//   } else {
//     // if MOD and NES
//     validCerts = ["NO", "MOD 50%"];
//   }
//   return validCerts;
// }

export async function validateHomes(homes, submittal) {
  // Check each home for duplicate serial number on back-end
  homes = await Promise.all(
    homes.map(async home => {
      if (home.serialNumber) {
        home.isDupe = !(await validateSerialNumber(home.serialNumber));
      }
      return home;
    })
  );

  const payload = homes.reduce(
    (acc, home, row) => {
      // For error formatting
      function prependColumnNumberToErrorMessage(message, columnName) {
        const columns = Object.keys(home);
        const colNum = columns.indexOf(columnName) + 1;
        return `Col ${colNum}: ${message}`;
      }
      // DEPRECATED: we no longer do submittal-level validation (matching plant, ES/NES)
      // function composeUnexpectedValueErrorMessage(expected, actual) {
      //   return `Import should be ${expected}, but user marked as ${actual}.`;
      // }

      row = row + 1;
      const { serialNumber } = home;

      const invalidObj = {
        serialNumber,
        row,
        errors: [],
      };

      if (home.isDupe) {
        invalidObj.errors.push("Duplicate serial number found on another submittal");
      }
      if (acc.serialNumbers.includes(serialNumber)) {
        invalidObj.errors.push("Duplicate serial number in this import.");
      }
      acc.serialNumbers.push(serialNumber);

      // const isEnergyStar = /^es$/i.test(home.energyStar);
      // const isHUD = /hud/i.test(home.type);

      // const validCertTypes = getValidCertTypes(isEnergyStar, isHUD);
      // const validTypes = isEnergyStar ? ["HUD"] : ["HUD", "MOD"];
      // const isHomeTypeValid = validTypes.includes(home.type?.toUpperCase());

      if (!serialNumber) {
        invalidObj.errors.push(
          prependColumnNumberToErrorMessage(
            "Serial Number is required, but not provided.",
            "serialNumber"
          )
        );
      }
      if (!home.streetAddress) {
        invalidObj.errors.push(
          prependColumnNumberToErrorMessage(
            "Street address is required, but not provided.",
            "streetAddress"
          )
        );
      }
      if (!home.modelNumber) {
        invalidObj.errors.push(
          prependColumnNumberToErrorMessage(
            "Model Number is required, but not provided.",
            "modelNumber"
          )
        );
      }
      if (!home.retailer) {
        invalidObj.errors.push(
          prependColumnNumberToErrorMessage(
            // home.retailer ? `No retailer found with name "${home.retailer}". Please check your spelling or create this retailer and try again.` :
            "Retailer is required, but not provided.",
            "retailer"
          )
        );
      }
      if (!home.PlantId) {
        invalidObj.errors.push(
          prependColumnNumberToErrorMessage(
            home.hbfPlantName
              ? `No plant found with name "${home.hbfPlantName}". Please check your spelling or create this plant and try again.`
              : "Plant is required, but not provided.",
            "hbfPlantName"
          )
        );
      }
      if (home.type) {
        // DEPRECATED: type validation relies on ES/NES, which is no longer supplied in the imports
        // if (!isHomeTypeValid) {
        //   const rawMessage = composeUnexpectedValueErrorMessage(
        //     validTypes.join(", or "),
        //     home.type
        //   );
        //   const formattedMessage = prependColumnNumberToErrorMessage(rawMessage, "type");
        //   invalidObj.errors.push(formattedMessage);
        // }
      } else {
        const formattedMessage = prependColumnNumberToErrorMessage(
          "Type is required, but not provided.",
          "type"
        );
        invalidObj.errors.push(formattedMessage);
      }

      // DEPRECATED: imports no longer include certificate type
      // Validate that the home's certType is included in the validTypes.
      // if (home.certType && !validCertTypes.includes(home.certType.toUpperCase())) {
      //   const rawMessage = composeUnexpectedValueErrorMessage(
      //     validCertTypes.join(", or "),
      //     home.certType
      //   );
      //   const formattedMessage = prependColumnNumberToErrorMessage(rawMessage, "certType");
      //   invalidObj.errors.push(formattedMessage);
      // }

      // DEPRECATED: imports no longer contain ES/NES
      // Check that the home's energyStar value is equal to the submittal's
      // if (home.energyStar !== submittal.energyStar) {
      //   const rawMessage = composeUnexpectedValueErrorMessage(
      //     submittal.energyStar,
      //     home.energyStar
      //   );
      //   const formattedMessage = prependColumnNumberToErrorMessage(rawMessage, "energyStar");
      //   invalidObj.errors.push(formattedMessage);
      // }

      // DEPRECATED: we no longer require the plant to match the submittal, now an import can contain multiple plants
      // if (home.hbfPlantName && home.hbfPlantName !== submittal.plantName) {
      //   const rawMessage = composeUnexpectedValueErrorMessage(
      //     submittal.plantName,
      //     home.hbfPlantName
      //   );
      //   const formattedMessage = prependColumnNumberToErrorMessage(rawMessage, "hbfPlantName");
      //   invalidObj.errors.push(formattedMessage);
      // }

      if (invalidObj.errors.length) {
        acc.invalid.push(invalidObj);
      } else {
        acc.valid.push({ serialNumber, row });
      }
      return acc;
    },
    { valid: [], invalid: [], serialNumbers: [] }
  );
  return payload;
}

// DEPRECATED: we save retailer as a string, we don't look it up
// in case a plant submits data from a retailer that's not yet in our system
export function mapRetailerNamesToIds(homes, retailers = []) {
  return homes.map(h => {
    const matchingRetailer = retailers.find(r => r.name.toLowerCase() === h.retailer.toLowerCase());
    if (matchingRetailer) {
      h.RetailerId = matchingRetailer.id;
      delete h.retailer;
    }
    return h;
  });
}

export function mapPlantNamesToIds(homes, plants = []) {
  return homes.map(h => {
    const matchingPlant = plants.find(p => p.name.toLowerCase() === h.hbfPlantName.toLowerCase());
    if (matchingPlant) {
      h.PlantId = matchingPlant.id;
      delete h.hbfPlantName;
    }
    return h;
  });
}

export const homeKeys = [
  "homeOwnerName",
  "hbfPlantName",
  "streetAddress",
  "city",
  "state",
  "lotNumber",
  "zipCode",
  "county",
  "energyStar",
  "type",
  "completionDate",
  "certDate",
  "certType",
  "serialNumber",
  "retailer",
  "retailerAddress",
  "retailerContactName",
  "retailerPhone",
  "retailerEmail",
  "notes",
  "climateCode",
  "modelNumber",
  "drawingNumber",
  "floors",
  "radiantBarrier",
  "furnace",
  "insulationSpecFloor",
  "insulationSpecWall",
  "insulationSpecRoof",
  "ductLocation",
  "floorPlan",
  "wallHeightFeet",
  "wallHeightInches",
  "interiorPitch",
  "hpModelNumber",
  "hpCoilNumber",
  "slidingGlassDoor",
  "slidingGlassDoorSHGC",
  "windowSpecs",
  "windowSpecsSHGC",
  "frontDoorSpecs",
  "frontDoorSpecsSHGC",
  "rearDoorSpecs",
  "projectName",
  "frontWallLength",
  "sideWallsLength",
  "floor",
  "walls",
  "attic",
  "windowsFront",
  "windowsRight",
  "windowsBack",
  "windowsLeft",
  "atriumFront",
  "atriumRight",
  "atriumLeft",
  "atriumBack",
  "porchFront",
  "porchRight",
  "porchBack",
  "porchLeft",
  "parkName",
  "wallsFront",
  "wallsSide",
  "conditionedSqFt",
  "shippedDate",
  "soldDate",
  "occupied",
  "set",
  "acOnSite",
  "dateAdded",
  "phase",
  "createdAt",
  "updatedAt",
  "fieldVerifier",
  "qaVerifier",
  "hersVerifier",
];
