import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// selectors

export const selectParkOptions = state => {
  const parkOptions = state.park.parks.map(p => ({ value: p.id, label: p.name }));
  // sort alphabetically by park name
  const sortedParkOptions = parkOptions.sort((a, b) => {
    var aLabel = a.label.toUpperCase(); // ensure case insensitive sort
    var bLabel = b.label.toUpperCase();
    return aLabel < bLabel ? -1 : aLabel > bLabel ? 1 : 0;
  });
  return sortedParkOptions;
};

export const selectParkById = id => state => state.park.parks.find(p => p.id === id) || {};

// actions

export const readAllParksAsync = createAsyncThunk("parks/readAll", async (_, { dispatch }) => {
  try {
    const response = await axios.get(`/api/parks`);
    return { parks: response.data.rows };
  } catch (error) {
    console.log(error);
  }
});

export const upsertParkAsync = createAsyncThunk(
  "park/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let response;
      response = await axios.post(`/api/park`, {
        ...payload,
      });
      dispatch(readAllParksAsync());
      return { park: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteParkAsync = createAsyncThunk("/park/delete", async payload => {
  await axios.delete(`/api/park/${payload.id}`);
  return { id: payload.id };
});

export const getUsersCSVAsync = createAsyncThunk("park/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/park/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "parks.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const parkSlice = createSlice({
  name: "park",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    // parks is the paginated detailed data we
    // use for display and editing
    parks: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllParksAsync.pending]: state => {
      state.loading = true;
    },
    [readAllParksAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.parks = action.payload.parks;
    },
    [readAllParksAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [upsertParkAsync.pending]: state => {
      state.loading = true;
    },
    [upsertParkAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.park[1]) {
        // insert
        if (action.payload.park[0]) state.parks.unshift(action.payload.park[0]);
      } else {
        // update
        state.parks = (state.parks || []).map(park =>
          park.id === action.payload.park[0].id ? action.payload.park[0] : park
        );
      }
    },
    [upsertParkAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [deleteParkAsync.pending]: state => {
      state.loading = true;
    },
    [deleteParkAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.parks = state.parks.filter(park => park.id !== action.payload.id);
    },
    [deleteParkAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = parkSlice.actions;
export default parkSlice.reducer;
