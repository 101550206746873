import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { closeModal } from "../../state/slices/frontend";
import { upsertParkAsync } from "../../state/slices/parkSlice";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import Legend from "../common/Legend";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import Header from "../common/Header";
import { SubmitButton } from "../common/Buttons";

export default function ParkForm() {
  const { modals } = useSelector(state => state.frontend);
  // const {
  //   contact: { contacts }
  // } = useSelector(state => state);

  const dispatch = useDispatch();
  const handleSubmitPark = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    await dispatch(upsertParkAsync(payload));
    dispatch(closeModal({ modal: "park" }));
  };
  const initialValues = modals.park.target || {
    name: "",
    officeHours: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    contactName: "",
    contactPhone: "",
    contactEmail: "",
    onSiteContactName: "",
    onSiteContactPhone: "",
    onSiteContactEmail: "",
    onSiteAlternateContactName: "",
    onSiteAlternateContactPhone: "",
    onSiteAlternateContactEmail: "",
    managementContactName: "",
    managementContactPhone: "",
    managementContactEmail: "",
  };
  return (
    <Formik
      className="p-4"
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitPark}
    >
      <Form>
        <Header>{modals.park.target?.id ? "Edit Park" : "Add Park"}</Header>
        <div className="grid grid-cols-6">
          <FormikFieldWithLabel name="name" className="col-span-3" label="Name" />
          <FormikFieldWithLabel name="officeHours" label="Office Hours" className="col-span-3" />
          <FormikFieldWithLabel
            name="address"
            label="Street Address"
            type="text"
            className="col-span-6"
          />
          <FormikFieldWithLabel name="city" label="City" type="text" className="col-span-2" />
          <FormikFieldWithLabel name="state" label="State" type="text" className="col-span-2" />
          <FormikFieldWithLabel
            name="zipCode"
            label="Zip Code"
            type="text"
            className="col-span-2"
          />
          <hr className="my-4 col-span-6" />
          <Legend className="col-span-6">Default Contact</Legend>
          <FormikFieldWithLabel name="contactName" label="Name" className="col-span-2" />
          <FormikFieldWithLabel name="contactEmail" label="Email" className="col-span-2" />
          <FormikFieldWithLabel name="contactPhone" label="Phone" className="col-span-2" />

          <Legend className="col-span-6">On-Site Contact</Legend>
          <FormikFieldWithLabel name="onSiteContactName" label="Name" className="col-span-2" />
          <FormikFieldWithLabel name="onSiteContactEmail" label="Email" className="col-span-2" />
          <FormikFieldWithLabel name="onSiteContactPhone" label="Phone" className="col-span-2" />

          <Legend className="col-span-6">On-Site Alternate Contact</Legend>
          <FormikFieldWithLabel
            name="onSiteAlternateContactName"
            label="Name"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="onSiteAlternateContactEmail"
            label="Email"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="onSiteAlternateContactPhone"
            label="Phone"
            className="col-span-2"
          />

          <Legend className="col-span-6">Management Contact</Legend>
          <FormikFieldWithLabel name="managementContactName" label="Name" className="col-span-2" />
          <FormikFieldWithLabel
            name="managementContactEmail"
            label="Email"
            className="col-span-2"
          />
          <FormikFieldWithLabel
            name="managementContactPhone"
            label="Phone"
            className="col-span-2"
          />
        </div>
        <div className="flex justify-end m-4 mt-6">
          <SubmitButton />
        </div>
      </Form>
    </Formik>
  );
}
