import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import { closeModal } from "../../state/slices/frontend";
import { selectPlantOptions } from "../../state/slices/plantSlice";
import { upsertRetailerAsync } from "../../state/slices/retailerSlice";
import FormikFieldWithLabel from "../common/FormikFieldWithLabel";
import Legend from "../common/Legend";
import Header from "../common/Header";
import { SubmitButton } from "../common/Buttons";

export default function RetailerForm() {
  const { modals } = useSelector(state => state.frontend);
  const plantOptions = useSelector(selectPlantOptions);

  const dispatch = useDispatch();

  const handleSubmitRetailer = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    await dispatch(upsertRetailerAsync(payload));
    dispatch(closeModal({ modal: "retailer" }));
  };
  const RetailerSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    plantId: Yup.string(),
    address: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zipCode: Yup.string().required("Required"),
    contactName: Yup.string(),
    contactEmail: Yup.string(),
    contactPhone: Yup.string(),
  });
  const initialValues = {
    id: modals.retailer.target?.id || "",
    name: modals.retailer.target?.name || "",
    plantId: modals.retailer.target?.plantId || "",
    address: modals.retailer.target?.address || "",
    city: modals.retailer.target?.city || "",
    state: modals.retailer.target?.state || "",
    zipCode: modals.retailer.target?.zipCode || "",
    contactName: modals.retailer.target?.contactName || "",
    contactEmail: modals.retailer.target?.contactEmail || "",
    contactPhone: modals.retailer.target?.contactPhone || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={RetailerSchema}
      className="p-4 "
      onSubmit={handleSubmitRetailer}
    >
      {({ errors, touched }) => (
        <Form>
          <Header>{modals.retailer.target?.id ? "Edit Retailer" : "Add Retailer"}</Header>
          <div className="grid grid-cols-6">
            <FormikFieldWithLabel
              autoFocus
              name="name"
              className="col-span-6"
              label="Name"
              error={touched.name && errors.name}
            />
            {/* This field is broken right now because we have yet to
            implement many-to-many plant-retailer relationships end-to-end */}
            <FormikFieldWithLabel
              as="select"
              label="Plant"
              className="col-span-6"
              name="plantId"
              error={touched.plantId && errors.plantId}
            >
              <option value="">---</option>
              {plantOptions.map(plant => (
                <option value={plant.value} key={plant.value}>
                  {plant.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              name="address"
              label="Street Address"
              type="text"
              className="col-span-6"
              error={touched.address && errors.address}
            />
            <FormikFieldWithLabel
              name="city"
              label="City"
              type="text"
              className="col-span-2"
              error={touched.city && errors.city}
            />
            <FormikFieldWithLabel
              name="state"
              label="State"
              type="text"
              className="col-span-2"
              error={touched.state && errors.state}
            />
            <FormikFieldWithLabel
              name="zipCode"
              label="Zip Code"
              type="text"
              className="col-span-2"
              error={touched.zipCode && errors.zipCode}
            />
            <hr className="my-4 col-span-6" />
            <Legend className="col-span-6">Contact</Legend>
            <FormikFieldWithLabel name="contactName" label="Name" className="col-span-2" />
            <FormikFieldWithLabel name="contactEmail" label="Email" className="col-span-2" />
            <FormikFieldWithLabel name="contactPhone" label="Phone" className="col-span-2" />
          </div>
          <div className="flex justify-end m-4 mt-6">
            <SubmitButton />
          </div>
        </Form>
      )}
    </Formik>
  );
}
