import { Formik, Form } from "formik";
import FormikFieldWithLabel from "../../../../common/FormikFieldWithLabel";
import { formatDisplayDate, formatInputDate } from "../../../../../utils/formatDate";
import { DataField } from "../../../../common/DataField";
import FormHeader from "../../../../common/forms/FormHeader";
import EditableImageList from "../EditableImageList";
import { useUserOptions } from "../../../../../state/slices/userSlice";
import { SubmitButton } from "../../../../common/Buttons";
import { useDispatch } from "react-redux";
import { updatePlantInspectionAsync } from "../../../../../state/slices/tripSlice";

export default function CompletedPlantInspectionView({ pi, refreshTrip }) {
  const dispatch = useDispatch();
  const userOptions = useUserOptions();

  // photos in list
  // does not include cancellation photo because it's in a separate section
  const piPhotoFields = [
    { title: "Plant", source: pi.plantPhoto, name: "plantPhoto" },
    { title: "Insulation #1", source: pi.insulationPhoto1, name: "insulationPhoto1" },
    { title: "Insulation #2", source: pi.insulationPhoto2, name: "insulationPhoto2" },
    { title: "Insulation #3", source: pi.insulationPhoto3, name: "insulationPhoto3" },
    { title: "Air Sealing #1", source: pi.airSealingPhoto1, name: "airSealingPhoto1" },
    { title: "Air Sealing #2", source: pi.airSealingPhoto2, name: "airSealingPhoto2" },
    { title: "Air Sealing #3", source: pi.airSealingPhoto3, name: "airSealingPhoto3" },
    { title: "Window Label", source: pi.windowLabelPhoto, name: "windowLabelPhoto" },
    { title: "Duct Work", source: pi.ductWorkPhoto, name: "ductWorkPhoto" },
    { title: "Misc.", source: pi.miscPhoto1, name: "miscPhoto1" },
    { title: "Misc.", source: pi.miscPhoto2, name: "miscPhoto2" },
    { title: "Misc.", source: pi.miscPhoto3, name: "miscPhoto3" },
    { title: "Misc.", source: pi.miscPhoto4, name: "miscPhoto4" },
  ];

  function initializeBooleanValue(val) {
    return val === null ? "" : val;
  }

  const initialValues = {
    inspectionNotes: pi.inspectionNotes || "",
    isAirPermeableInsulation: initializeBooleanValue(pi.isAirPermeableInsulation),
    isInsulationAlignedWithAirBarrier: initializeBooleanValue(pi.isInsulationAlignedWithAirBarrier),
    isExteriorThermalEnveloppeInsulationConform: initializeBooleanValue(
      pi.isExteriorThermalEnveloppeInsulationConform
    ),
    isFloorFramingCavityInsulationConform: initializeBooleanValue(
      pi.isFloorFramingCavityInsulationConform
    ),
    isBattsInNarrowCavitiesConform: initializeBooleanValue(pi.isBattsInNarrowCavitiesConform),
    isRecessedLightFixturesConform: initializeBooleanValue(pi.isRecessedLightFixturesConform),
    isBattInsulationConform: initializeBooleanValue(pi.isBattInsulationConform),
    isExteriorWallsInsulated: initializeBooleanValue(pi.isExteriorWallsInsulated),
    isHVACRegisterBootsSealed: initializeBooleanValue(pi.isHVACRegisterBootsSealed),
    isContinuousAirBarrier: initializeBooleanValue(pi.isContinuousAirBarrier),
    isAirBarrierAligned: initializeBooleanValue(pi.isAirBarrierAligned),
    isSpaceWindowDoorSealed: initializeBooleanValue(pi.isSpaceWindowDoorSealed),
    isDuctSealed: initializeBooleanValue(pi.isDuctSealed),
    isRecessedLightFixturesCSealed: initializeBooleanValue(pi.isRecessedLightFixturesCSealed),
    isAirBarrierConform: initializeBooleanValue(pi.isAirBarrierConform),

    status: pi.status || "",
    UserId: pi.UserId || "",
    completedDate: formatInputDate(pi.completedDate) || "",
    id: pi.id || "",
  };

  // allows "unsetting" yes-no fields
  function convertEmptyStringToNull(obj) {
    for (const prop in obj) {
      if (obj[prop] === "") {
        obj[prop] = null;
      }
    }
  }

  const handleSubmit = async pi => {
    convertEmptyStringToNull(pi);
    await dispatch(updatePlantInspectionAsync(pi));
    refreshTrip();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values }) => (
        <Form>
          <FormHeader className="mt-4">Summary</FormHeader>
          <div className="grid grid-cols-3 gap-2 p-8 mb-4 mt-4 bg-gray-300 rounded-md">
            <FormikFieldWithLabel
              as="select"
              name="status"
              label="Status"
              error={touched.status && errors.status}
            >
              <option value="scheduled">Scheduled</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="UserId"
              label="Verified By"
              error={touched.UserId && errors.UserId}
            >
              <option defaultValue value="">
                ---
              </option>
              {userOptions.map(user => (
                <option key={user.value} value={user.value}>
                  {user.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              name="completedDate"
              label="Verification Date"
              className="col-span-1"
              type="date"
              error={touched.completedDate && errors.completedDate}
            />
          </div>
          <div className="grid grid-cols-2 gap-2 mb-6">
            {pi.User?.name && (
              <DataField
                label="Verified By"
                data={`${pi.User?.name} (${pi.User?.RTIN || "RTIN missing"})`}
              />
            )}
            {pi.completedDate && (
              <DataField label="Verification Date" data={formatDisplayDate(pi.completedDate)} />
            )}
            {pi.Reviewer?.name && (
              <DataField
                label="Reviewed by"
                data={`${pi.Reviewer?.name} (${pi.Reviewer?.RTIN || "RTIN missing"})`}
              />
            )}
            {pi.reviewDate && (
              <DataField label="Review Date" data={formatDisplayDate(pi.reviewDate)} />
            )}
            <DataField label="Created date" data={formatDisplayDate(pi.createdAt)} />
          </div>
          <div className="grid grid-cols-1 mt-6 mb-4">
            <h3 className="font-medium text-xl">Insulation Installation</h3>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <FormikFieldWithLabel
              as="select"
              name="isAirPermeableInsulation"
              label="Air Permeable Insulation"
              tooltip="Air-permeable insulation shall not be used as a sealing material."
              error={touched.isAirPermeableInsulation && errors.isAirPermeableInsulation}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isInsulationAlignedWithAirBarrier"
              label="Insulation Aligned With Air Barrier"
              tooltip="The insulation in any dropped ceiling/soffit shall be aligned with the air barrier."
              error={
                touched.isInsulationAlignedWithAirBarrier &&
                errors.isInsulationAlignedWithAirBarrier
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isExteriorThermalEnveloppeInsulationConform"
              label="Exterior Thermal Envelope Insulation"
              tooltip="Exterior thermal envelope insulation for framed walls shall be installed in substantial contact and continuous alignment with the air barrier."
              error={
                touched.isExteriorThermalEnveloppeInsulationConform &&
                errors.isExteriorThermalEnveloppeInsulationConform
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isFloorFramingCavityInsulationConform"
              label="Floor Framing Cavity Insulation"
              tooltip="Floor framing cavity insulation shall be installed to maintain permanent contact with the underside of the subfloor decking, or floor framing cavity insulation shall be permitted to be in contact with the top side of sheathing, or continuous insulation installed on the underside of floor framing and extends from the bottom to the top of all perimeter floor framing members."
              error={
                touched.isFloorFramingCavityInsulationConform &&
                errors.isFloorFramingCavityInsulationConform
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isBattsInNarrowCavitiesConform"
              label="Batts In Narrow Cavities"
              tooltip="Batts in narrow cavities shall be cut to fit, or narrow cavities shall be filled by insulation that on installation readily conforms to the available cavity space."
              error={
                touched.isBattsInNarrowCavitiesConform && errors.isBattsInNarrowCavitiesConform
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isRecessedLightFixturesConform"
              label="Recessed Light Fixtures"
              tooltip="Recessed light fixtures installed in the building thermal envelope shall be air tight and IC rated."
              error={
                touched.isRecessedLightFixturesConform && errors.isRecessedLightFixturesConform
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isBattInsulationConform"
              label="Batt Insulation"
              tooltip="Batt insulation shall be cut neatly to fit around wiring and plumbing in exterior walls, or insulation that on installation readily conforms to available space shall extend behind piping and wiring."
              error={touched.isBattInsulationConform && errors.isBattInsulationConform}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isExteriorWallsInsulated"
              label="Exterior Walls Insulated"
              tooltip="Exterior walls adjacent to showers and tubs shall be insulated."
              error={touched.isExteriorWallsInsulated && errors.isExteriorWallsInsulated}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
          </div>
          <div className="grid grid-cols-1 mt-6 mb-4">
            <h3 className="font-medium text-xl">Air Sealing</h3>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <FormikFieldWithLabel
              as="select"
              name="isHVACRegisterBootsSealed"
              label="HVAC Register Boots Sealed"
              tooltip="HVAC register boots that penetrate the building thermal envelope shall be sealed to the subfloor or drywall"
              error={touched.isHVACRegisterBootsSealed && errors.isHVACRegisterBootsSealed}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isContinuousAirBarrier"
              label="Continuous Air Barrier"
              tooltip="A continuous air barrier shall be installed in the building envelope. Exterior thermal envelope contains a continuous air barrier. Breaks or joints in the air barrier shall be sealed."
              error={touched.isContinuousAirBarrier && errors.isContinuousAirBarrier}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isAirBarrierAligned"
              label="Air Barrier Aligned"
              tooltip="The air barrier in any dropped ceiling/soffit shall be aligned with the insulation and any gaps in the air barrier shall be sealed. Access openings, drop down stair or knee wall doors to unconditioned attic spaces shall be sealed."
              error={touched.isAirBarrierAligned && errors.isAirBarrierAligned}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isSpaceWindowDoorSealed"
              label="Space Between Window/Door"
              tooltip="The space between window/door jambs and framing and skylights and framing shall be sealed."
              error={touched.isSpaceWindowDoorSealed && errors.isSpaceWindowDoorSealed}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isDuctSealed"
              label="Duct Shafts"
              tooltip="Duct shafts, utility penetrations, and flue shafts opening to exterior or unconditioned space shall be sealed."
              error={touched.isDuctSealed && errors.isDuctSealed}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isRecessedLightFixturesCSealed"
              label="Recessed Light Fixtures"
              tooltip="Recessed light fixtures installed in the building thermal envelope shall be sealed to the drywall."
              error={
                touched.isRecessedLightFixturesCSealed && errors.isRecessedLightFixturesCSealed
              }
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="isAirBarrierConform"
              label="Air Barrier"
              tooltip="The air barrier shall be installed behind electrical or communication boxes or air sealed boxes shall be installed."
              error={touched.isAirBarrierConform && errors.isAirBarrierConform}
            >
              <option value="">---</option>
              <option value={true}>Yes</option>
              <option value={false}>N/A</option>
            </FormikFieldWithLabel>
          </div>
          <div className="grid grid-cols-3 gap-2 mb-4">
            <FormikFieldWithLabel
              name="inspectionNotes"
              label="Plant inspection notes"
              type="text"
              className="col-span-3"
              error={touched.inspectionNotes && errors.inspectionNotes}
              as="textarea"
              fieldClass="h-24 resize-y"
            />
          </div>

          <SubmitButton />
          <EditableImageList images={piPhotoFields} piId={pi.id} refreshTrip={refreshTrip} />
        </Form>
      )}
    </Formik>
  );
}
