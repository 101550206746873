import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PencilIcon } from "@heroicons/react/outline";
import Table from "../common/Table";
import { readAllUsersAsync } from "../../state/slices/userSlice";
import { openModal } from "../../state/slices/frontend";
import TableButton from "../common/TableButton";

export default function Users() {
  const { users } = useSelector(state => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readAllUsersAsync());
  }, [dispatch]);

  const [state, setState] = useState({ pageSize: 25 });

  const onChange = e => {
    const { value, name } = e.target;
    return setState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEditUser = user => {
    dispatch(openModal({ modal: "user", target: user }));
  };

  const userColumns = [
    {
      dataField: "name",
      class: "text-left",
    },
    {
      dataField: "email",
      class: "text-left",
    },
    {
      caption: "RTIN",
      class: "text-left",
      cellDisplay(value) {
        return <div className="text-left">{value.RTIN}</div>;
      },
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`user-edit-${value.id}`}
              onClick={() => handleEditUser(value)}
              className="text-indigo-600"
              icon={PencilIcon}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between">
          <h1 className="text-3xl font-bold text-white">Users</h1>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6">
            <select
              value={state.pageSize}
              onChange={onChange}
              id="pageSize"
              name="pageSize"
              className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value={5}>5</option>
              <option value={25}>25</option>
            </select>
          </div>
          <Table columns={userColumns} className="" items={users || []} pageSize={state.pageSize} />
        </div>
      </main>
    </div>
  );
}
