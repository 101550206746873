import React from "react";

export default function FilteredPlantOptions({ plantOptions = [], customerId }) {
  return (
    customerId &&
    plantOptions.map(
      plant =>
        // convert to number because dom changes values to string automatically
        Number(customerId) === plant.CustomerId && (
          <option value={plant.value} key={plant.value}>
            {plant.label}
          </option>
        )
    )
  );
}
