import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// DEPRECATED and unused
// be careful if you use this because /api/homes is no longer supported
// use /api/45L/homes or /api/heug/homes instead, see HomeService
export const getHomesAsync = createAsyncThunk("api/homes", async payload => {
  const response = await axios.post(`/api/homes`, payload);
  return response.data;
});

export const frontend = createSlice({
  name: "frontend",
  initialState: {
    loading: true,
    modals: {
      submittal: {
        isOpen: false,
        target: null,
      },
      customer: {
        isOpen: false,
        target: null,
      },
      user: {
        isOpen: false,
        target: null,
      },
      contact: {
        isOpen: false,
        target: null,
      },
      trip: {
        isOpen: false,
        target: null,
      },
      plant: {
        isOpen: false,
        target: null,
      },
      park: {
        isOpen: false,
        target: null,
      },
      retailer: {
        isOpen: false,
        target: null,
      },
      registration: {
        isOpen: false,
        target: null,
      },
    },
    // this piece of state can be useful if you want to open, edit, and
    // submit a sub-modal, and come back to your past modal with your
    // inputs still intact. this is in state because only one modal can render at a time
    savedModal: {
      state: {},
      target: null,
      modal: "",
    },
  },
  reducers: {
    clearErrors: state => {
      state.error = null;
    },
    setHomes: (state, action) => {
      if (action.payload) {
        state.homes = action.payload;
      }
    },
    saveModal: (state, action) => {
      state.savedModal = {
        state: action.payload.state,
        target: action.payload.target,
        modal: action.payload.modal,
      };
    },
    /**
     *
     * @param {*} state
     * @param {Object} action
     * @param {String} action.modal //Target modal in string.
     * @param {Number} action.target // Target id so the modal can keep track of an edit.
     */
    openModal(state, action) {
      if (action.payload) {
        const modals = { ...state.modals };
        for (const modal in modals) {
          if (action.payload.modal === modal) {
            modals[modal].isOpen = true;
            modals[modal].target = action.payload.target;
          } else {
            modals[modal].isOpen = false;
            modals[modal].target = null;
          }
        }
        state.modals = modals;
      }
    },
    closeModal: (state, action) => {
      const modalToClose = action.payload.modal;
      state.modals[modalToClose].isOpen = false;
      state.modals[modalToClose].target = null;
    },
  },
  extraReducers: {
    [getHomesAsync.pending]: state => {
      state.loading = true;
    },
    [getHomesAsync.fulfilled]: (state, action) => {
      state.loading = false;

      if (!action.payload.id) {
        state.error = "Wrong username or password";
      } else {
        state.homes = action.payload;
      }
    },
    [getHomesAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { clearErrors, setHomes, saveModal, openModal, closeModal } = frontend.actions;

export default frontend.reducer;
