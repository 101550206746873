import React from "react";

export default function Select({ label, name, options, value, className, onChange = () => {} }) {
  return (
    <div className={className}>
      <label htmlFor={name} className="text-gray-900 mb-1">
        {label}
      </label>
      <select
        id={name}
        value={value}
        name={name}
        onChange={e => onChange(e.target.value)}
        className="bg-white block w-full px-2 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="">---</option>
        {options.map(u => (
          <option value={u.value} key={u.value}>
            {u.label}
          </option>
        ))}
      </select>
    </div>
  );
}
