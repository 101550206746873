import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFieldVerificationById } from "../../../../services/fieldVerification";
import formatAddressText from "../../../../utils/formatAddress";
import { GreenButton } from "../../../common/Buttons";
import { DataField } from "../../../common/DataField";
import CompletedFieldVerificationView from "./CompletedFieldVerificationView";

export default function FieldVerificationReport({ match, isAuthenticated }) {
  const fvId = match.params.fvId;
  const [report, setReport] = useState([]);

  useEffect(() => {
    getFieldVerificationById(fvId).then(report => {
      setReport(report);
    });
  }, [fvId]);

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow">
        <div className="max-w-7xl mx-auto py-6 px-8 items-center">
          <h1 className="text-3xl font-bold text-white">Field Verification Report</h1>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto p-8">
          {isAuthenticated && (
            <Link to={`/trip/details/${report.TripId}?fvId=${report.id}`}>
              <GreenButton className="mb-6">Edit Field Verification</GreenButton>
            </Link>
          )}
          <div className="grid grid-cols-2 gap-2 mb-6">
            <DataField label="Plant" data={report.Plant?.name} />
            <DataField label="Address" data={formatAddressText(report)} />
          </div>
          <CompletedFieldVerificationView fv={report} />
        </div>
      </main>
    </div>
  );
}

export function AuthedFieldVerificationReport({ match }) {
  return <FieldVerificationReport match={match} isAuthenticated={true} />;
}
