import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { format, parseISO, isValid } from "date-fns";
import axios from "axios";
import { HomeIcon, CalendarIcon } from "@heroicons/react/outline";
import { HomeIcon as FilledHomeIcon } from "@heroicons/react/solid";
import { SubmittalSearch } from "../forms/SearchForms";
import Modal from "../Modal";
import HomePhasesNavBar from "../nav/HomePhasesNavBar";
import SubmittalPhase from "../forms/HomeForms/Phases/SubmittalPhase";
import CertificationPhase from "../forms/HomeForms/Phases/CertificationPhase";
import InspectionPhase from "../forms/HomeForms/Phases/InspectionPhase";
import PlansPhase from "../forms/HomeForms/Phases/PlansPhase";
import BillingPhase from "../forms/HomeForms/Phases/BillingPhase";
import { MainSection, PageWithSidebar, Sidebar } from "./PageWithSidebar";
import Header from "../common/Header";
import useQuery from "../../utils/hooks/useQuery";
import { deleteHomeAsync } from "../../state/slices/submittalSlice";

const initialDefaultHome = {
  customerName: "",
  serialNumber: "",
  energyStar: "",
  type: "",
  floorPlan: "",
  certType: "",
  certDate: "",
  notes: "",
  streetAddress: "",
  city: "",
  state: "",
  zipCode: "",
};

// homes in a submittal should always have the same es, customer, and plant as the submittal
function appendSubmittalDataToHome(submittal, home) {
  return {
    ...home,
    energyStar: submittal?.energyStar,
    PlantId: submittal?.PlantId,
    CustomerId: submittal?.Plant?.CustomerId,
  };
}

export default function SubmittalDetailsHEUG({ match }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submittal, setSubmittal] = useState({});
  const [defaultHome, setDefaultHome] = useState(initialDefaultHome);
  const [homeState, setHomeState] = useState(defaultHome);
  const [searchHomes, setSearchHomes] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [phase, setPhase] = useState("submittal");
  const queryParams = useQuery();
  const homeId = queryParams.get("homeId");
  const { submittalId } = match.params;

  const { fetchSubmittal, cancelFunc } = useMemo(() => {
    // cancels the request if the component is unmounted before the request completes
    const source = axios.CancelToken.source();
    const fetchSubmittal = () =>
      axios
        .get(`/api/heug/submittal/${submittalId}`, { cancelToken: source.token })
        .then(({ data }) => {
          setSubmittal(data);
          const home = data.Homes?.find(home => home.id === Number(homeId)) || defaultHome;
          const populatedHome = appendSubmittalDataToHome(data, home);
          const populatedDefaultHome = appendSubmittalDataToHome(data, defaultHome);
          setDefaultHome(populatedDefaultHome);
          if (!homeId) setHomeState(populatedDefaultHome);
          else setHomeState(populatedHome);
        })
        .catch(() => {});
    return { cancelFunc: source.cancel, fetchSubmittal };
  }, [homeId, submittalId, defaultHome]);

  // get submittal by id
  useEffect(() => {
    fetchSubmittal();
    return cancelFunc;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const homesInSidebar = searching ? searchHomes : submittal?.Homes ?? [];

  const displayDate = date => {
    date = parseISO(date);
    if (isValid(date)) {
      return format(date, "yyyy-MM-dd");
    }
    return "";
  };

  function renderAddress(home) {
    return (
      home.streetAddress && (
        <span className="text-sm text-gray-800 pointer-events-auto">
          {home.streetAddress}, {home.city}, {home.state}, {home.zipCode}
          {home.lotNumber && `, Lot #${home.lotNumber}`}
        </span>
      )
    );
  }

  const searchHandler = modalSearchObj => {
    const searchHomes = submittal.Homes.filter(home => {
      const searchOptions = Object.keys(modalSearchObj).filter(e => modalSearchObj[e]);
      setSearching(Boolean(searchOptions.length));
      const matches = [];
      for (const prop of searchOptions) {
        if (modalSearchObj[prop] && home[prop]) {
          const query = modalSearchObj[prop];
          const homeVal = home[prop];
          if (homeVal.toLowerCase().includes(query.toLowerCase())) {
            matches.push(prop);
          }
        }
      }
      return matches.length === searchOptions.length;
    });
    setTimeout(() => {
      if (searchHomes.length) {
        setHomeState(searchHomes[0]);
      }
    }, 0);
    setSearchHomes(searchHomes);
  };

  const handleAddNewHome = () => {
    setPhase("submittal");
    history.push({ search: "" });
    setHomeState(appendSubmittalDataToHome(submittal, defaultHome));
  };

  const handleSetHomeState = home => {
    // Fixes error: TypeError: Cannot add property CustomerId, object is not extensible
    const h = { ...home };
    if (home.id) {
      // TODO: use home CustomerId after refactoring selector
      h.CustomerId = submittal?.Plant?.CustomerId;
      history.push({ search: `homeId=${home.id}` });
      setHomeState(h);
      // if (h.phase) setPhase(h.phase || "submittal");
    }
  };

  const handleDeleteHome = async () => {
    const confirm = window.confirm("Deleting a home is irreversible.\nAre you sure?");
    if (confirm) {
      await dispatch(deleteHomeAsync(homeState.id));
      setHomeState(defaultHome);
      setPhase("submittal");
      fetchSubmittal();
    }
  };

  const modalOpenHandler = () => {
    setModalOpen(!modalOpen);
  };

  const HomeHeader = () => (
    <>
      <h3 className="text-xl leading-6 mt-2 text-gray-900">
        {homeState.serialNumber && (
          <span className="">Serial number: {homeState.serialNumber}</span>
        )}
      </h3>
      <div className="flex lg:flex-row flex-col mb-4 lg:items-end items-start">
        {homeState.id ? (
          <>
            <p className="inline-flex mr-4 mt-4 items-end font-medium  text-gray-600">
              <CalendarIcon className="h-6 mr-1" />
              <span className="">Added: {displayDate(homeState.dateAdded)}</span>
            </p>
            {homeState.certDate && (
              <p className="mt-2 inline-flex md:mx-4 items-end  font-medium text-gray-600">
                <CalendarIcon className="h-6 mr-1" />
                <span className="">Certified: {displayDate(homeState.certDate)}</span>
              </p>
            )}
            {homeState.completionDate && (
              <p className="mt-2 inline-flex md:mx-4 items-end font-medium  text-gray-600">
                <CalendarIcon className="h-6 mr-1" />
                <span className="">Completed: {displayDate(homeState.completionDate)}</span>
              </p>
            )}
          </>
        ) : (
          <Header>Add Home</Header>
        )}
      </div>
    </>
  );

  const MainSectionContents = () => (
    <>
      <h1 className="text-3xl font-bold leading-7 text-gray-900 mb-4">
        {`Submittal Details: ${submittal?.name}`}
      </h1>
      <HomeHeader />
      <HomePhasesNavBar setPhase={setPhase} currentPhase={phase} isNewHome={!homeState.id} />
      {phase === "submittal" && (
        <SubmittalPhase
          setParentState={setHomeState}
          refreshSubmittal={fetchSubmittal}
          home={homeState}
          handleDelete={handleDeleteHome}
        />
      )}
      {phase === "certification" && (
        <CertificationPhase
          home={homeState}
          refreshSubmittal={fetchSubmittal}
          setParentState={setHomeState}
          handleDelete={handleDeleteHome}
        />
      )}
      {phase === "inspection" && (
        <InspectionPhase
          refreshSubmittal={fetchSubmittal}
          home={homeState}
          setParentState={setHomeState}
          handleDelete={handleDeleteHome}
        />
      )}
      {phase === "plans" && (
        <PlansPhase
          refreshSubmittal={fetchSubmittal}
          home={homeState}
          setParentState={setHomeState}
          handleDelete={handleDeleteHome}
        />
      )}
      {phase === "billing" && (
        <BillingPhase
          refreshSubmittal={fetchSubmittal}
          home={homeState}
          setParentState={setHomeState}
          handleDelete={handleDeleteHome}
        />
      )}
    </>
  );

  const SidebarContents = () => (
    <>
      <div className="justify-between items-center flex space-x-1.5 p-2">
        <h4 className="text-md">Homes ({submittal?.Homes?.length ?? 0})</h4>
        <div className="min-w-max flex justify-end flex-1 space-x-1.5">
          <button
            onClick={handleAddNewHome}
            className="bg-green-600 text-white font-bold py-2 px-3 mr-1 rounded-md"
          >
            Add
          </button>
          <button
            onClick={() => {
              if (searching) {
                setSearchHomes([]);
                return setSearching(false);
              }
              modalOpenHandler(true);
            }}
            className="bg-green-600 text-white font-bold py-2 px-3 rounded-md"
          >
            {searching ? "Clear" : "Search"}
          </button>
        </div>
      </div>
      <div className="w-full bg-white divide-y divide-gray-200">
        {/* empty div serves to make a bar based on parent divide-y */}
        <div></div>
        {homesInSidebar.map((home, i) => (
          <div
            key={i}
            role="button"
            onClick={() => {
              handleSetHomeState(home);
            }}
            className={`flex items-center cursor-pointer ${
              home.id === homeState.id ? "bg-blue-100 duration-75" : "hover:bg-gray-100"
            }`}
          >
            {home.FieldVerification ? (
              <FilledHomeIcon className="h-5 m-2 rounded-full pointer-events-auto" />
            ) : (
              <HomeIcon className="h-5 m-2 rounded-full pointer-events-auto" />
            )}
            <div className="pointer-events-auto flex-1 py-2">
              {home.serialNumber && (
                <div className="text-sm text-gray-800 truncate pointer-events-auto">
                  {home.serialNumber}
                </div>
              )}
              {renderAddress(home)}
            </div>
          </div>
        ))}
        {/* empty div serves to make a bar based on parent divide-y */}
        <div></div>
      </div>
    </>
    // </table>
  );

  return (
    <>
      <PageWithSidebar>
        <Sidebar>
          <SidebarContents />
        </Sidebar>
        <MainSection>
          <MainSectionContents />
        </MainSection>
      </PageWithSidebar>
      <Modal modalShowing={modalOpen} closeModal={() => modalOpenHandler(false)}>
        <SubmittalSearch modalToggleHandler={modalOpenHandler} searchHandler={searchHandler} />
      </Modal>
    </>
  );
}
