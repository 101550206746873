import axios from "axios";

export const getAll = async () => {
  const { data } = await axios.get("/api/customers");
  return data;
};

export const getAllOptions = async () => {
  const { data } = await axios.get("/api/customer/options");
  return data;
};
