import React from "react";

export function DataField({ label, data }) {
  return (
    <div className="my-2">
      <label className="text-sm font-medium text-gray-500" htmlFor={label}>
        {label}
      </label>
      <dd className="text-gray-900" aria-labelledby={label}>
        {data}
      </dd>
    </div>
  );
}
