import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  createPlantInspectionAsync,
  updatePlantInspectionAsync,
} from "../../../../../state/slices/tripSlice";
import { WithSpacesBetween } from "../../../../common/list-wrappers/index";
import { SubmitButton } from "../../../../common/Buttons";
import FormikFieldWithLabel from "../../../../common/FormikFieldWithLabel";
import { useSelector } from "react-redux";
import { selectCustomerOptions } from "../../../../../state/slices/customerSlice";
import FilteredPlantOptions from "../../../../forms/FilteredPlantOptions";
import { selectPlantOptions } from "../../../../../state/slices/plantSlice";
import stripUnfilledValues from "../../../../../utils/forms/stripUnfilledValues";
import { useDispatch } from "react-redux";
import { selectMyUserId } from "../../../../../state/slices/authSlice";
import { formatInputDate } from "../../../../../utils/formatDate";
import { useEffect } from "react";

export default function StatefulPlantInspectionFormForScheduler({ tripId, pi = {}, refreshTrip }) {
  const dispatch = useDispatch();
  const myUserId = useSelector(selectMyUserId);
  const customerOptions = useSelector(selectCustomerOptions);
  const plantOptions = useSelector(selectPlantOptions);

  const handleSubmit = async (pi, { resetForm }) => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(pi);
    if (pi.id) {
      pi.SchedulerId = myUserId;
      await dispatch(updatePlantInspectionAsync(pi));
      refreshTrip();
    } else {
      pi.TripId = tripId;
      await dispatch(createPlantInspectionAsync(pi));
      resetForm({
        CustomerId: "",
        PlantId: "",
        streetAddress: "",
        lotNumber: "",
        city: "",
        state: "",
        zipCode: "",
        county: "",
      });
      refreshTrip();
    }
  };

  const initialValues = {
    CustomerId: `${pi?.CustomerId || ""}`,
    PlantId: `${pi?.PlantId || ""}`,
    schedulerNotes: pi.schedulerNotes || "",
    streetAddress: pi.streetAddress || "",
    lotNumber: pi.lotNumber || "",
    city: `${pi?.city || ""}`,
    state: pi.state || "",
    zipCode: `${pi.zipCode || ""}`,
    county: `${pi.county || ""}`,
    id: pi.id || "",
    scheduledDate: formatInputDate(pi.scheduledDate) || "",
    appointmentTime: pi.appointmentTime || "",
    contactName: pi.contactName || "",
    contactPhone: pi.contactPhone || "",
  };

  return (
    <PlantInspectionForm
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      customerOptions={customerOptions}
      plantOptions={plantOptions}
    />
  );
}

export function PlantInspectionForm({
  handleSubmit,
  customerOptions,
  plantOptions,
  initialValues,
}) {
  // Validation rules
  const HomeSchema = Yup.object().shape({
    CustomerId: Yup.string().required("Required"),
    PlantId: Yup.string().required("Required"),
    schedulerNotes: Yup.string(),
    streetAddress: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    lotNumber: Yup.string(),
    zipCode: Yup.string(),
    county: Yup.string(),
    scheduledDate: Yup.string().required("Required"),
    appointmentTime: Yup.string().required("Required"),
    contactName: Yup.string().required("Required"),
    contactPhone: Yup.string().required("Required"),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={HomeSchema}
      enableReinitialize
      className="sm:rounded-md transition-height duration-500"
    >
      {({ errors, touched, values }) => {
        return <PrepopulateFieldsForm touched={touched} errors={errors} values={values} />;
      }}
    </Formik>
  );

  function PrepopulateFieldsForm({ touched, errors, values }) {
    let plantCity;
    let plantAddress;
    let plantState;
    let plantCounty;
    let plantZipCode;
    plantOptions.map(plant => {
      if (plant.value === Number(values.PlantId)) {
        plantCity = plant.city;
        plantAddress = plant.address;
        plantState = plant.state;
        plantCounty = plant.county;
        plantZipCode = plant.zipCode;
      }
    });
    useEffect(() => {
      if (values.PlantId) {
        values.city = plantCity || "";
        values.streetAddress = plantAddress || "";
        values.state = plantState || "";
        values.county = plantCounty || "";
        values.zipCode = plantZipCode || "";
      }
    }, [values.PlantId]);

    return (
      <Form>
        <WithSpacesBetween>
          <div className="grid grid-cols-2 gap-2">
            <FormikFieldWithLabel
              as="select"
              name="CustomerId"
              label="Customer"
              error={touched.CustomerId && errors.CustomerId}
            >
              <option value="">---</option>
              {customerOptions.map(customer => (
                <option value={customer.value} key={customer.value}>
                  {customer.label}
                </option>
              ))}
            </FormikFieldWithLabel>
            <FormikFieldWithLabel
              as="select"
              name="PlantId"
              label="Plant/HBF"
              error={touched.PlantId && errors.PlantId}
            >
              <option value="">---</option>
              <FilteredPlantOptions plantOptions={plantOptions} customerId={values.CustomerId} />
            </FormikFieldWithLabel>

            <FormikFieldWithLabel
              className="col-span-1"
              label="Scheduled date"
              name="scheduledDate"
              type="date"
              error={touched.scheduledDate && errors.scheduledDate}
            />
            <FormikFieldWithLabel
              className="col-span-1"
              label="Appointment Time"
              name="appointmentTime"
              type="text"
              error={touched.appointmentTime && errors.appointmentTime}
            />
            <FormikFieldWithLabel
              className="col-span-1"
              label="Contact Name"
              name="contactName"
              type="text"
              error={touched.contactName && errors.contactName}
            />
            <FormikFieldWithLabel
              className="col-span-1"
              label="Contact Phone"
              name="contactPhone"
              type="text"
              error={touched.contactPhone && errors.contactPhone}
            />

            <FormikFieldWithLabel
              className="col-span-2"
              label="Scheduler notes"
              name="schedulerNotes"
              type="text"
              as="textarea"
              fieldClass="h-24 resize-y"
            />
            <FormikFieldWithLabel
              name="streetAddress"
              label="Street Address"
              type="text"
              className="col-span-1"
              error={touched.streetAddress && errors.streetAddress}
            />
            <FormikFieldWithLabel
              name="lotNumber"
              label="Lot #"
              type="text"
              className="col-span-1"
              error={touched.lotNumber && errors.lotNumber}
            />
            <FormikFieldWithLabel
              name="city"
              label="City"
              type="text"
              className="col-span-1"
              error={touched.city && errors.city}
            />
            <FormikFieldWithLabel
              name="state"
              label="State"
              type="text"
              className="col-span-1"
              error={touched.state && errors.state}
            />
            <FormikFieldWithLabel
              name="zipCode"
              label="Zip Code"
              type="text"
              className="col-span-1"
              error={touched.state && errors.state}
            />
            <FormikFieldWithLabel
              name="county"
              label="County"
              type="text"
              className="col-span-1"
              error={touched.state && errors.state}
            />
          </div>
        </WithSpacesBetween>
        <div className="px-4 py-3 mt-auto text-right sm:px-6 flex justify-end">
          <SubmitButton />
        </div>
      </Form>
    );
  }
}
