import React, { useEffect, useRef, useState } from "react";
import { Field, useFormikContext, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { LABEL_FONT_SIZE } from "../../utils/constants";
import { formatInputDate } from "../../utils/formatDate";

export function FieldWithError({
  name,
  label,
  handleChange,
  value,
  type = "text",
  error,
  className,
  disabled,
  placeholder,
}) {
  return (
    <div className={`inline-block my-3 pr-4 ${className ?? ""}`}>
      <label htmlFor={name} className="block text-sm text-gray-900 mb-2">
        {label}
      </label>
      <input
        id={name}
        name={name}
        type={type}
        onChange={handleChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        className={`resize-none bg-white block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      {error ? <ErrorLabel name={name} error={error} /> : null}
    </div>
  );
}

export function SelectFieldWithError({
  name,
  label,
  handleChange,
  value,
  error,
  className,
  children,
}) {
  return (
    <div className={`inline-block my-3 pr-4 ${className ?? ""}`}>
      <label htmlFor={name} className="block text-sm text-gray-900 mb-2">
        {label}
      </label>
      <select
        id={name}
        name={name}
        onChange={handleChange}
        value={value}
        className={`resize-none bg-white block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      >
        {children}
      </select>
      {error ? <ErrorLabel name={name} error={error} /> : null}
    </div>
  );
}

export function DateFieldWithError({ name, label, handleChange, value, error, className }) {
  const dateChangeHandler = date => {
    const formattedDate = formatInputDate(date);
    // event is required to have similar api to other fields and to be compatible with formik
    const event = { target: { value: formattedDate, name } };
    handleChange(event);
  };
  return (
    <div className={"flex flex-col p-1 " + className}>
      <label htmlFor={name} className="block text-sm text-gray-900 mb-2">
        {label}
      </label>
      <DatePicker
        id={name}
        name={name}
        value={value}
        onChange={dateChangeHandler}
        className={`resize-none bg-white block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
      />
      <p className="text-xs text-red-600 px-2">{error}</p>
    </div>
  );
}

export default function InputFieldWithAutomaticLabelResize({
  className,
  label,
  children,
  ...props
}) {
  const inputSize = useRef();
  const labelSize = useRef();
  const [fontSize, setFontSize] = useState(LABEL_FONT_SIZE);

  // calculates the width of the input and resizes the label if it's smaller
  useEffect(() => {
    if (inputSize.current) {
      const { current: inputEl } = inputSize;
      const { current: labelEl } = labelSize;
      const inputElement = inputEl.querySelector(`input[name="${props.name}"]`);

      if (inputElement) {
        const inputWidth = inputElement.offsetWidth;
        if (labelEl.offsetWidth > inputWidth) {
          setFontSize(fontSize => fontSize - 0.5);
        }
      }
    }
  }, [fontSize, props.name]);

  const { values, errors } = useFormikContext();
  const meta = { errors: errors[props.name] };

  return (
    <div className={"flex flex-col " + className}>
      <div ref={inputSize} className="inline-block p-1">
        <label
          htmlFor={props.name}
          style={{ fontSize }}
          className="block text-xs  font-medium text-gray-700"
        >
          <span style={{ fontSize }} ref={labelSize} className="whitespace-nowrap">
            {label}
          </span>
        </label>
        <Field
          {...props}
          id={props.name}
          value={values[props.name] || ""}
          className={`resize-none bg-white block w-full px-3 py-2 border ${
            meta.errors ? "border-red-600" : "border-gray-300"
          } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        >
          {props.as === "select" ? (
            <>
              <option value={""}>---</option>
              {children}
            </>
          ) : (
            children
          )}
        </Field>
      </div>
      <ErrorMessage
        name={props.name}
        render={error => <span className="text-xs text-red-600 px-2">{error}</span>}
      />
    </div>
  );
}

export function ErrorLabel(props) {
  return (
    <p name={props.name} className="text-xs text-red-500 mt-1" data-testid={"error-" + props.name}>
      {props.error}
    </p>
  );
}
