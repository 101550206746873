import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { closeModal } from "../../state/slices/frontend";
import { upsertCustomerAsync } from "../../state/slices/customerSlice";
import Input from "../common/FormikFieldWithLabel";
import Legend from "../common/Legend";
import stripUnfilledValues from "../../utils/forms/stripUnfilledValues";
import Header from "../common/Header";
import { SubmitButton } from "../common/Buttons";

export default function CustomerForm() {
  const { modals } = useSelector(state => state.frontend);
  const dispatch = useDispatch();

  const handleSubmitCustomer = async payload => {
    //Removes unfilled inputs, "" or null
    stripUnfilledValues(payload);
    await dispatch(upsertCustomerAsync(payload));
    dispatch(closeModal({ modal: "customer" }));
  };

  const initialValues = modals.customer.target || {
    name: "",
    contactName: "",
    contactTitle: "",
    contactEmail: "",
    contactPhone: "",
  };
  return (
    <Formik
      className="p-4 "
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitCustomer}
    >
      <Form>
        <Header>{initialValues?.id ? "Edit Customer" : "Add Customer"}</Header>
        <div className="grid grid-cols-2">
          <Input autoFocus name="name" className="col-span-2" label="Name" />
          <Legend className="col-span-2">Contact</Legend>
          <Input name="contactName" label="Contact Name" className="col-span-1" />
          <Input name="contactTitle" label="Contact Title" className="col-span-1" />
          <Input name="contactEmail" label="Email Address" className="col-span-1" />
          <Input
            name="contactPhone"
            label="Phone Number"
            type="tel"
            className="col-span-1"
            pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
          />
        </div>
        <div className="flex justify-end m-4 mt-6">
          <SubmitButton />
        </div>
      </Form>
    </Formik>
  );
}
