import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Selectors

export const selectRetailerOptions = state =>
  state.retailer.retailers.map(r => ({ label: r.name, value: r.id }));

export const selectRetailerById = id => state => {
  const foundRetailer = state.retailer.retailers.find(r => r.id === id);
  // return empty object to prevent crash if an item has a retailer id for a retailer that was deleted
  return foundRetailer || {};
};

// Actions

export const readAllRetailersAsync = createAsyncThunk("retailers/readAll", async () => {
  try {
    const response = await axios.get(`/api/retailers`);
    return { retailers: response.data.rows };
  } catch (error) {
    console.log(error);
  }
});

export const upsertRetailerAsync = createAsyncThunk(
  "retailer/upsert",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(`/api/retailer`, {
        ...payload,
      });
      dispatch(readAllRetailersAsync());
      return { retailer: response.data };
    } catch (error) {
      if ("string" === typeof error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.response.data.errors);
      }
    }
  }
);

export const deleteRetailerAsync = createAsyncThunk("/retailer/delete", async payload => {
  await axios.delete(`/api/retailer/${payload.id}`);
  return { id: payload.id };
});

export const getUsersCSVAsync = createAsyncThunk("retailer/csv", async (payload, extra) => {
  //const { filters, sorting } = extra.getState().seller;

  const response = await axios({
    url: "/api/user/export",
    method: "GET",
    responseType: "blob", // important
    //params: { filters, sorting },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "retailers.csv");
  document.body.appendChild(link);
  link.click();

  //return response.data;
});

export const retailerSlice = createSlice({
  name: "retailer",
  initialState: {
    loading: true,
    error: null,
    generalError: null,
    retailers: [],
  },
  reducers: {
    clearError: (state, action) => {
      if (state.error) state.error[action.payload.field] = null;
    },
    clearErrors: state => {
      state.error = null;
      state.generalError = null;
    },
  },
  extraReducers: {
    [readAllRetailersAsync.pending]: state => {
      state.loading = true;
    },
    [readAllRetailersAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.retailers = action.payload.retailers;
    },
    [readAllRetailersAsync.rejected]: (state, action) => {
      state.loading = false;
      //state.error = action.error;
    },
    [upsertRetailerAsync.pending]: state => {
      state.loading = true;
    },
    [upsertRetailerAsync.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload.retailer[1]) {
        // insert
        if (action.payload.retailer[0]) state.retailers.unshift(action.payload.retailer[0]);
      } else {
        // update
        state.retailers = (state.retailers || []).map(retailer =>
          retailer.id === action.payload.retailer[0].id ? action.payload.retailer[0] : retailer
        );
      }
    },
    [upsertRetailerAsync.rejected]: (state, action) => {
      state.loading = false;
      if ("string" === typeof action.payload) {
        state.generalError = action.payload;
      } else {
        state.error = (action.payload || []).reduce((acc, error) => {
          acc[error.path] = `The ${error.path} field cannot be empty`;
          return acc;
        }, {});
      }
    },
    [deleteRetailerAsync.pending]: state => {
      state.loading = true;
    },
    [deleteRetailerAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.retailers = state.retailers.filter(retailer => retailer.id !== action.payload.id);
    },
    [deleteRetailerAsync.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearError, clearErrors } = retailerSlice.actions;
export default retailerSlice.reducer;
