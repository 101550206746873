import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PencilIcon, TrashIcon, SearchIcon } from "@heroicons/react/outline";
import { deleteTripAsync, readAllTripsAsync } from "../../../state/slices/tripSlice";
import { openModal } from "../../../state/slices/frontend";
import Table from "../../common/Table";
import { GreenButton as Button } from "../../common/Buttons";
import TableButton from "../../common/TableButton";

export function filterCurrentTrips(trips = []) {
  const twoWeeksAgo = new Date() - 14 * 24 * 60 * 60 * 1000;
  return trips.filter(t => new Date(t.endDate) >= twoWeeksAgo);
}

export default function Trips() {
  const dispatch = useDispatch();
  const history = useHistory();
  const trips = useSelector(state => state.trip.trips);
  const [items, setItems] = useState(filterCurrentTrips(trips || []));
  const [tripMode, setTripMode] = useState("current");

  // this is useful if the page was opened here or refreshed
  // without this useEffect, the page render with no trips, sets items state with []
  // and then does not update once trips are loaded
  useEffect(() => {
    // don't remove this conditional, it makes sure not to filter when a trip is updated and mode is "all"
    if (tripMode === "current") setItems(filterCurrentTrips(trips));
  }, [trips, tripMode]);

  useEffect(() => {
    dispatch(readAllTripsAsync());
  }, [dispatch]);

  const onChange = e => {
    const tripReadMode = e.target.value;
    if (tripReadMode === "all") setItems(trips);
    if (tripReadMode === "current") setItems(filterCurrentTrips(trips));
    setTripMode(tripReadMode);
  };

  const handleEditTrip = trip => {
    dispatch(openModal({ modal: "trip", target: trip }));
  };
  const handleAddTrip = () => {
    dispatch(openModal({ modal: "trip" }));
  };

  const handleDelete = trip => {
    const confirm = window.confirm(
      "Deleting a trip is irreversible. All field verifications in this trip will be deleted.\nAre you sure?"
    );
    if (confirm) {
      dispatch(deleteTripAsync(trip));
    }
  };

  const tripColumns = [
    {
      dataField: "name",
    },
    {
      dataField: "total",
      caption: "Total",
      cellDisplay(value) {
        return value.items?.length || 0;
      },
    },
    {
      caption: "Completed",
      dataField: "numberCompleted",
      cellDisplay(value) {
        return (
          value.items?.reduce((acc, fv) => (fv.status === "completed" ? acc + 1 : acc), 0) || 0
        );
      },
    },
    {
      caption: "Verifier",
      cellDisplay(value) {
        return value.User?.name || "";
      },
    },
    {
      caption: "",
      type: "action",
      cellDisplay(value) {
        return (
          <div className="flex items-center justify-end">
            <TableButton
              testId={`trip-edit-${value.id}`}
              icon={PencilIcon}
              onClick={e => {
                handleEditTrip(value);
                e.stopPropagation();
              }}
              className="text-indigo-600"
            />
            <TableButton
              testId={`trip-delete-${value.id}`}
              icon={TrashIcon}
              onClick={e => {
                handleDelete(value);
                e.stopPropagation();
              }}
              className="text-red-700"
            />
          </div>
        );
      },
    },
  ];

  function handleRowClick(value) {
    history.push(`/trip/details/${value.id}`);
  }

  return (
    <div className="w-full bg-gray-200">
      <header className="bg-gray-800 shadow md:h-64">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Trips</h1>
          <div className="flex">
            <Link to="/trips/search">
              <Button>
                <SearchIcon className="mr-2 h-4 text-white inline-block" />
                Search
              </Button>
            </Link>
            <Link className="mx-2" to="/trips/reports">
              <Button>Reports</Button>
            </Link>
            <Button onClick={handleAddTrip}>Add</Button>
          </div>
        </div>
      </header>
      <main className="md:-my-48">
        <div className="max-w-7xl mx-auto py-6">
          <div className="my-1 w-full lg:px-8 sm:px-6">
            <select
              value={tripMode}
              onChange={onChange}
              id="tripReadMode"
              name="tripReadMode"
              className="ml-auto mt-1 block pl-3 pr-2 py-2 text-base bg-white border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="current">Current</option>
              <option value="all">All</option>
            </select>
          </div>
          <Table columns={tripColumns} items={items} onRowClick={handleRowClick} />
        </div>
      </main>
    </div>
  );
}
