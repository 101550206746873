import { DataField } from "../../../../common/DataField";
import FormHeader from "../../../../common/forms/FormHeader";
import ImagePreview from "../../../../common/ImagePreview";

export default function CancelledPlantInspectionView({ pi }) {
  const hasCancellationData = pi.cancellationNotes || pi.cancellationPhoto;
  return (
    <>
      {hasCancellationData && (
        <>
          <FormHeader className="mt-6">Cancellation Details</FormHeader>
          <DataField label="Cancellation notes" data={pi.cancellationNotes || "None"} />
          {pi.cancellationPhoto && (
            <ImagePreview src={pi.cancellationPhoto} caption="Cancellation Photo" />
          )}{" "}
        </>
      )}
    </>
  );
}
