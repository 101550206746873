import React from "react";

export default function TableButton({ icon, testId, onClick, className }) {
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className={`h-8 w-8 hover:bg-gray-200 p-2 rounded-md ${className}`}
    >
      {icon()}
    </button>
  );
}
